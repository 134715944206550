import {
  ControlBoard,
  DeviceType,
  Gateway,
  GenericErrors,
  IdListMapping,
  LoadingById,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  isDefined,
  mapArrayToObject,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions as ControlBoardActions } from '../actions/control_boards';
import { Actions } from '../actions/gateways';
import { Actions as ThermostatActions } from '../actions/thermostats';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import { ControlBoardsFromApiResponse } from './control_boards';
import { spacesFromApiResponse } from './spaces';

import {
  processGatewayApiResponse,
  processGatewayDipSwitch,
  processGatewayHeatingSystem,
} from '@energybox/react-ui-library/dist/utils/gateways';

export interface EditableFields {
  title: string;
  description: string;
  uuid: string;
  spaceId: number;
  networkGroupId: number;
  model: DeviceType;
}

export const editableFields = (gateway: object) =>
  R.pick(
    ['spaceId', 'networkGroupId', 'title', 'description', 'uuid', 'model'],
    gateway
  );

export const excludeFromPatchFields = ['model'];

export type EditGateway = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const newGatewayFields = {
  title: '',
  description: '',
  networkGroupId: -1,
  spaceId: -1,
  siteId: -1,
  uuid: '',
  model: DeviceType.ENERGYBOX_HUB,
};

const newGateway = {
  isLoading: false,
  isChanged: false,
  fields: newGatewayFields,
  formErrors: formValidationErrors('gateway', newGatewayFields),
  formErrorsVisible: false,
  apiError: {},
};

export type GatewaysById = {
  [id: string]: Gateway | ControlBoard;
};

export type GatewaysByNetworkGroupId = {
  [networkGroupId: string]: (Gateway | ControlBoard)[];
};

export type GatewayByParentId = {
  [id: string]: number[];
};
export type GatewayIdFromUuid = {
  [id: string]: number;
};

export type ActionsLoadingStatus = {
  [Actions.GET_GATEWAYS_LOADING]?: boolean;
  [Actions.GET_GATEWAYS_BY_SITE_ID_LOADING]?: LoadingById;
  [Actions.GET_HUB_BY_PAIRED_SENSOR_ID_LOADING]?: LoadingById;
};

export type HubsByPairedSensorId = {
  [pairedSensorId: string]: Gateway;
};

export interface Gateways {
  query: string;
  gatewayIdFromUuid: GatewayIdFromUuid;
  gatewayIdsByParentId: GatewayByParentId;
  gatewayIdsBySiteId: IdListMapping;
  gatewayIdsFilteredBySites: number[];
  gatewaysById: GatewaysById;
  gatewaysByNetworkGroupId: GatewaysByNetworkGroupId;
  gatewaysByUuid: GatewaysById;
  editById: EditById;
  showNewGatewayModal: boolean;
  gatewayIdsByResourceId: GatewayIdsByResourceId;
  loadingStatusByAction: ActionsLoadingStatus;
  hubsByPairedSensorId: HubsByPairedSensorId;
  isNewSuperHub: boolean;
  siteId: string;
}

export type EditById = {
  [id: string]: EditGateway;
};

export type GatewayIdsByResourceId = {
  [id: number]: number[];
};

export const normalizeEbGateway = (data: any) => ({
  id: data.id,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  title: data.title,
  description: data.description || '',
  //Doesn't look like backend ever returns gatewayModelId?
  // gatewayModelId: data.gatewayModelId,
  gatewayModel: data.gatewayModel || undefined,
  modelId: data.modelId || undefined,
  spaceId: data.spaceId,
  pairedSensorIds: data.pairedSensorIds || undefined,
  pairedSensors: data.pairedSensors,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  _entity: data._entity,

  scannedSensorIds: data.scannedSensorIds || undefined,
  scannedSensors: data.scannedSensors,

  sensorsWhitelist: data.sensorsWhitelist || undefined,

  uuid: data.uuid,
  vendor: data.vendor,
  model: data.model,
  networkGroupId: data.networkGroupId,
  productId: data.productId || undefined,
  space: data.space ? spacesFromApiResponse(data.space) : undefined,
  gatewayInfo: data.gatewayInfo || undefined,
  gatewayConfig: data.gatewayConfig || undefined,
  gatewayStatus: data.gatewayStatus || undefined,
  gatewayOnlineStatus: data.gatewayOnlineStatus || undefined,
  // Looks like onlineState is moved inside gatewayOnlineStatus, but not deleting to be safe
  // onlineState: data.onlineState,
  // onlineStateUpdatedAt: data.onlineStateUpdatedAt,

  venstarThermostatModel: data.venstarThermostatModel
    ? `Venstar ${data.venstarThermostatModel}`
    : undefined,
  temperatureSensorSource: data.temperatureSensorSource || undefined,
  compressorProtectionTime: data.compressorProtectionTime || undefined,
  temperatureSensorFallbackTime:
    data.temperatureSensorFallbackTime || undefined,
  deadbandStage1: data.deadbandStage1 || undefined,
  deadbandStage2: data.deadbandStage2 || undefined,
  warnings: data.warnings || undefined,
});

const GatewaysFromApiResponse = (data: any) => {
  const entity = data._entity;
  if (entity === 'ControlBoard') {
    return ControlBoardsFromApiResponse(data);
  } else {
    return normalizeEbGateway(data);
  }
};

export const initialState = {
  query: '',
  gatewaysById: {},
  gatewayIdFromUuid: {},
  gatewaysByUuid: {},
  gatewayIdsByParentId: {},
  gatewayIdsBySiteId: {},
  gatewayIdsFilteredBySites: [],
  gatewaysByNetworkGroupId: {},
  showNewGatewayModal: false,
  isNewSuperHub: false,
  siteId: '',
  editById: {},
  loadingStatusByAction: {},
  // This is recursive
  gatewayIdsByResourceId: {},
  hubsByPairedSensorId: {},
};

export const gatewayList = (state: Gateways, parentId: number) => {
  const { gatewaysById, query, gatewayIdsByParentId } = state;
  let gateways = values(gatewaysById);

  if (parentId && parentId >= 0) {
    gateways = gateways.filter(
      gateway => (gatewayIdsByParentId[parentId] || []).indexOf(gateway.id) >= 0
    );
  }

  if (query && query.length >= 3) {
    gateways = gateways.filter(gateway => hasSubstr(gateway.title, query));
  }

  return gateways;
};

export const sortedGateways = ({ gatewaysById }: Gateways) =>
  values(gatewaysById).sort((a, b) => a.title.localeCompare(b.title));

const mapPairedHubsBySensorId = rawData => {
  const processedHubsBySensorId = {};
  if (!isDefined(rawData)) return processedHubsBySensorId;

  const filteredHubsList = rawData.filter(rawGateway => {
    return rawGateway._entity === 'Hub';
  });

  filteredHubsList.forEach(rawHub => {
    const normalizedHub: Gateway = normalizeEbGateway(rawHub);

    normalizedHub.pairedSensorIds?.forEach(pairedSensorId => {
      processedHubsBySensorId[pairedSensorId] = normalizedHub;
    });
  });

  return processedHubsBySensorId;
};

const gateways = (state: Gateways = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_GATEWAYS_SUCCESS:
      return R.pipe(
        R.assoc(
          'gatewayIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('gatewayIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ spaceId }) => spaceId,
              action.data
            )
          )
        ),
        R.assoc(
          'gatewaysById',
          R.mergeRight(
            R.view(R.lensProp('gatewaysById'), state),
            mapArrayToObject(mapValues(action.data, GatewaysFromApiResponse))
          )
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_GATEWAYS_LOADING],
          false
        )
      )(state);

    case Actions.GET_GATEWAYS_BY_SITE_ID_SUCCESS:
      const apiRes = action.data;
      const gatewayIds = apiRes.map(({ id }) => id);
      return R.pipe(
        action.siteId
          ? R.assocPath(['gatewayIdsBySiteId', [action.siteId]], gatewayIds)
          : R.assocPath(['gatewayIdsFilteredBySites'], gatewayIds),
        R.assoc(
          'gatewayIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('gatewayIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ spaceId }) => spaceId,
              action.data
            )
          )
        ),
        R.assoc(
          'gatewaysById',
          R.mergeRight(
            R.view(R.lensProp('gatewaysById'), state),
            mapArrayToObject(mapValues(action.data, GatewaysFromApiResponse))
          )
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_GATEWAYS_BY_SITE_ID_LOADING,
            [action.siteId],
          ],
          false
        )
      )(state);

    case Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS: {
      let processedData = [...action.data];

      processedData =
        [...action.data].map((item: any) => {
          if (item.gasSystem !== undefined) {
            return {
              ...item,
              hvacType: processGatewayApiResponse(item),
              reversingValve: processGatewayDipSwitch(item),
              heatingSystem: processGatewayHeatingSystem(item),
            };
          }
          if (item.hpSwitch !== undefined) {
            return {
              ...item,
              hvacType: processGatewayApiResponse(item),
              reversingValve: processGatewayDipSwitch(item),
            };
          }
          return item;
        }) || [];

      return R.pipe(
        R.assocPath(
          ['gatewaysByNetworkGroupId', action.networkGroupId],
          processedData
        )
      )(state);
    }

    case Actions.GET_GATEWAYS_BY_SITE_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_GATEWAYS_BY_SITE_ID_LOADING,
          [action.siteId],
        ],
        true,
        state
      );

    case Actions.GET_GATEWAYS_BY_RESOURCE_ID_LOADING:
    case Actions.GET_GATEWAYS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_GATEWAYS_LOADING],
        true,
        state
      );

    case Actions.GET_GATEWAY_SUCCESS:
      let gateway = GatewaysFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['gatewaysById', action.data.id], gateway),
        R.assocPath(['gatewaysByUuid', action.data.uuid], gateway),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          formErrors: {},
          fields: editableFields(gateway),
          apiError: {},
        })
      )(state);

    case Actions.GET_GATEWAY_ID_FROM_UUID_SUCCESS:
      return R.assocPath(['gatewayIdFromUuid', action.id], action.data, state);

    case Actions.UPDATED_QUERY:
      return R.assoc('query', action.query, state);

    case Actions.TOGGLE_NEW_GATEWAY_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newGateway),
        R.assoc('showNewGatewayModal', action.value),
        R.assoc('isNewSuperHub', action.isNewSuperHub),
        R.assoc('siteId', action.siteId)
      )(state);

    case Actions.NEW_GATEWAY:
      return R.pipe(
        R.assocPath(['editById', 'new'], newGateway),
        R.assoc('isNewSuperHub', action.isNewSuperHub),
        R.assoc('siteId', action.siteId)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.CLEAR_FORM_ERRORS:
      return R.pipe(R.assocPath(['editById', 'new', 'apiError'], {}))(state);

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('gateway', updatedField)
        )
      )(state);

    case Actions.CREATE_GATEWAY_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case ControlBoardActions.CREATE_CONTROL_BOARD_SUCCESS:
    case Actions.CREATE_GATEWAY_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['gatewaysById', action.data.id],
          GatewaysFromApiResponse(action.data)
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewGatewayModal', false)
      )(state);

    case Actions.CREATE_GATEWAY_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_GATEWAY_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_GATEWAY_SUCCESS:
      const patchedGateway = GatewaysFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(
          ['gatewaysByNetworkGroupId', action.networkGroupId],
          [patchedGateway]
        ),
        R.assocPath(['gatewaysById', action.id], patchedGateway),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_GATEWAY_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_GATEWAY:
      var fields = editableFields(R.path(['gatewaysById', action.id], state));

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('gateway', fields)
        )
      )(state);

    case Actions.GET_GATEWAYS_BY_RESOURCE_ID_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['gatewayIdsByResourceId', action.id],
          action.data.map(({ id }) => id)
        ),
        R.assoc(
          'gatewaysById',
          R.mergeRight(
            R.view(R.lensProp('gatewaysById'), state),
            mapArrayToObject(mapValues(action.data, GatewaysFromApiResponse))
          )
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_GATEWAYS_LOADING],
          false
        )
      )(state);
    }

    case Actions.GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_SUCCESS: {
      return R.pipe(
        R.assoc(
          'hubsByPairedSensorId',
          R.mergeRight(
            R.view(R.lensProp('hubsByPairedSensorId'), state),
            mapPairedHubsBySensorId(action.data)
          )
        )
      )(state);
    }

    case Actions.GET_HUB_BY_PAIRED_SENSOR_ID_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['hubsByPairedSensorId', action.pairedSensorId],
          action.data
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_HUB_BY_PAIRED_SENSOR_ID_LOADING,
            action.pairedSensorId,
          ],
          false
        )
      )(state);
    }

    case Actions.GET_HUB_BY_PAIRED_SENSOR_ID_LOADING: {
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_HUB_BY_PAIRED_SENSOR_ID_LOADING,
          action.pairedSensorId,
        ],
        true,
        state
      );
    }

    case Actions.GET_HUB_BY_PAIRED_SENSOR_ID_ERROR: {
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_HUB_BY_PAIRED_SENSOR_ID_LOADING,
          action.pairedSensorId,
        ],
        false,
        state
      );
    }

    case Actions.DELETE_GATEWAY_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case ControlBoardActions.DELETE_CONTROL_BOARD_SUCCESS:
    case ThermostatActions.DELETE_THERMOSTAT_DEVICE_SUCCESS:
    case Actions.DELETE_GATEWAY_SUCCESS:
      return R.pipe(
        R.assocPath(
          [
            'gatewaysByNetworkGroupId',
            state.gatewaysById[action.id].networkGroupId,
          ],
          state.gatewaysByNetworkGroupId[
            state.gatewaysById[action.id].networkGroupId
          ]?.filter(gateway => gateway.id !== action.id)
        ),
        R.dissocPath(['gatewaysById', action.id]),
        R.dissocPath(['editById', action.id])
      )(state);

    case Actions.DELETE_GATEWAY_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case ThermostatActions.CREATE_THERMOSTAT_DEVICE_SUCCESS:
      return R.pipe(
        R.assocPath(['gatewaysById', action.data.id], action.data),
        R.assocPath(
          ['gatewayIdsByResourceId', action.siteId],
          R.concat(
            R.view(
              R.lensPath(['gatewayIdsByResourceId', action.siteId]),
              state
            ) || [],
            [action.data.id]
          )
        )
      )(state);

    default:
      return state;
  }
};

export default gateways;
