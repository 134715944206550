import {
  Equipment,
  GenericErrors,
  IdListMapping,
  LoadingById,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/equipment';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import { getMapFromArrayOneToOne } from '@energybox/react-ui-library/dist/utils/util';

export interface EditableFields {
  title: string;
  customerEquipmentId: string;
  description: string;
  groupId: number;
  typeId: number;
  spaceId: number;
  vendor: string;
  model: string;
  serialNumber: string;
  manufactureDate: string;
}

export const editableFields = (equipment: object) =>
  R.pick(
    [
      'title',
      'description',
      'groupId',
      'typeId',
      'spaceId',
      'vendor',
      'model',
      'serialNumber',
      'manufactureDate',
      'customerEquipmentId',
    ],
    equipment
  );

export type EditEquipment = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const newEquipmentFields = {
  title: '',
  description: '',
  groupId: -1,
  typeId: -1,
  vendor: '',
  model: '',
  spaceId: -1,
};

const newEquipment = {
  isLoading: false,
  isChanged: false,
  fields: newEquipmentFields,
  formErrors: formValidationErrors('equipment', newEquipmentFields),
  formErrorsVisible: false,
  apiError: {},
};

export type EquipmentById = {
  [id: string]: Equipment;
};

export type EquipmentIdByParentId = {
  [id: string]: number[];
};

export type EquipmentByEquipmentTypeId = {
  [equipmentTypeId: string]: Equipment[];
};

export type EquipmentByResourceId = {
  [id: string]: Equipment[];
};

export interface Equipments {
  query: string;
  equipmentIdsByParentId: EquipmentIdByParentId;
  equipmentById: EquipmentById;
  equipmentIdsBySiteId: IdListMapping;
  editById: EditById;
  showNewEquipmentModal: boolean;
  equipmentLoading: boolean;
  loadingStatusByAction: ActionsLoadingStatus;
  equipmentByEquipmentTypeId: EquipmentByEquipmentTypeId;
  equipmentPayloadArray: Equipment[];
  errorEmailFields: any;
  isFirstTimeCount: any;
  getUpdatedEquipmentList: any;
  sentinelTargetType: any;
}

export type EditById = {
  [id: string]: EditEquipment;
};

export const equipmentsFromApiResponse = (data: any): Equipment => ({
  id: data.id,
  title: data.title,
  spaceId: data.spaceId || undefined,
  space: data.space,
  description: data.description || '',
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  type: data.type,
  typeId: data.typeId,
  groupId: data.groupId,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  model: data.model || '',
  vendor: data.vendor || '',
  activeControl: data.activeControl,
  manufactureDate: data.manufactureDate || '',
  serialNumber: data.serialNumber || '',
  customerEquipmentId: data.customerEquipmentId || '',
});

export type ActionsLoadingStatus = {
  [Actions.GET_EQUIPMENTS_LOADING]?: boolean;
  [Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING]?: LoadingById;
};

export const initialState = {
  query: '',
  equipmentById: {},
  equipmentIdsByParentId: {},
  showNewEquipmentModal: false,
  editById: {},
  equipmentIdsBySiteId: {},
  equipmentLoading: false,
  loadingStatusByAction: {},
  equipmentByEquipmentTypeId: {},
  equipmentPayloadArray: [],
  errorEmailFields: [],
  isFirstTimeCount: 0,
  getUpdatedEquipmentList: [],
  sentinelTargetType: '',
};

export const equipmentList = (state: Equipments, parentId: number) => {
  const { equipmentById, query, equipmentIdsByParentId } = state;
  let equipments = values(equipmentById);

  if (parentId && parentId >= 0) {
    equipments = equipments.filter(
      equipment =>
        (equipmentIdsByParentId[parentId] || []).indexOf(equipment.id) >= 0
    );
  }

  if (query && query.length >= 3) {
    equipments = equipments.filter(equipment =>
      hasSubstr(equipment.title, query)
    );
  }

  return equipments;
};

export const sortedEquipment = ({ equipmentById }: Equipments) =>
  values(equipmentById).sort((a, b) => a.title.localeCompare(b.title));

const equipments = (state: Equipments = initialState, action: any) => {
  switch (action.type) {
    case Actions.CLEAR_EQUIPMENT:
      return {
        ...initialState,
      };
    case Actions.GET_EQUIPMENT_LOADING:
      return R.assoc('equipmentLoading', true, state);
    case Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING,
          [action.siteId],
        ],
        true,
        state
      );
    case Actions.GET_EQUIPMENTS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_EQUIPMENTS_LOADING],
        true,
        state
      );
    case Actions.GET_EQUIPMENTS_SUCCESS: {
      let newState = R.pipe(
        R.assoc('equipmentPayloadArray', action.data),
        R.assoc(
          'equipmentIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('equipmentIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ spaceId }) => spaceId,
              action.data
            )
          )
        ),
        R.assoc(
          'equipmentById',
          R.mergeRight(
            R.view(R.lensProp('equipmentById'), state),
            getMapFromArrayOneToOne(
              mapValues(action.data, equipmentsFromApiResponse)
            )
          )
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_EQUIPMENTS_LOADING],
          false
        )
      )(state);
      if (action.siteId) {
        newState = R.assocPath(
          ['equipmentIdsBySiteId', [action.siteId]],
          action.data.map(({ id }) => id),
          newState
        );
      }
      return newState;
    }

    case Actions.GET_EQUIPMENT_SUCCESS: {
      let equipment = equipmentsFromApiResponse(action.data);

      return R.pipe(
        R.assoc('equipmentLoading', false),
        R.assocPath(['equipmentById', action.data.id], equipment),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(equipment),
          apiError: {},
        })
      )(state);
    }

    case Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['equipmentIdsBySiteId', [action.siteId]],
          action.data.map(({ id }) => id)
        ),
        R.assoc(
          'equipmentById',
          R.mergeRight(
            R.view(R.lensProp('equipmentById'), state),
            getMapFromArrayOneToOne(
              mapValues(action.data, equipmentsFromApiResponse)
            )
          )
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING,
            [action.siteId],
          ],
          false
        )
      )(state);

    case Actions.UPDATED_QUERY:
      return R.assoc('query', action.query, state);

    case Actions.TOGGLE_NEW_EQUIPMENT_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newEquipment),
        R.assoc('showNewEquipmentModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('equipment', updatedField)
        )
      )(state);

    case Actions.CREATE_EQUIPMENT_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_EQUIPMENT_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['equipmentById', action.data.id],
          equipmentsFromApiResponse(action.data)
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewEquipmentModal', false)
      )(state);

    case Actions.CREATE_EQUIPMENT_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_EQUIPMENT_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_EQUIPMENT_SUCCESS:
      const patchedEquipment = equipmentsFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['equipmentById', action.id], patchedEquipment),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_EQUIPMENT_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_EQUIPMENT: {
      const fields = editableFields(
        R.path(['equipmentById', action.id], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('equipment', fields)
        )
      )(state);
    }

    case Actions.DELETE_EQUIPMENT_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    // TODO Need to remove from by parentId as well
    case Actions.DELETE_EQUIPMENT_SUCCESS: {
      if (action.data) {
        return R.pipe(
          R.dissocPath(['equipmentById', action.id]),
          R.dissocPath(['editById', action.id])
        )(state);
      }
      return state;
    }

    case Actions.DELETE_EQUIPMENT_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['equipmentByEquipmentTypeId', action.equipmentTypeId],
          mapValues(action.data, equipmentsFromApiResponse)
        )
      )(state);
    case Actions.EMAIL_FIELD_ERRORSS:
      return {
        ...state,
        errorEmailFields: [...state.errorEmailFields, action.payload],
      };
    case Actions.IS_FIRST_TIME_COPY:
      return {
        ...state,
        isFirstTimeCount: action.payload,
      };

    case Actions.GET_UPDATED_EQUIPMENT_LIST:
      return {
        ...state,
        getUpdatedEquipmentList: R.uniqBy(i => i.id, [
          ...state.getUpdatedEquipmentList,
          ...action.payload,
        ]),
      };

    case Actions.GET_UPDATED_SENTINEL_ID:
      return {
        ...state,
        sentinelTargetType: action.payload,
      };

    default:
      return state;
  }
};

export default equipments;
