import { ApiError, Site } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import {
  Actions as SiteActions,
  destroy,
  getSite,
  reset,
} from '../../actions/sites';
import DeleteModal from '../../components/Modals/DeleteModal';
import ShowDetailPageHeader from '../../components/ShowDetailPageHeader';
import { ApplicationState } from '../../reducers';
import { EditSite } from '../../reducers/sites';
import { Routes } from '../../routes';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import SiteDetailTabs from '../SiteDetailTabs';
import ShowSitePageDistributionPanels from './ShowSitePageDistributionPanels';
import ShowSitePageEquipment from './ShowSitePageEquipment';
import ShowSitePageGateways from './ShowSitePageGateways';
import ShowSitePageGeneral from './ShowSitePageGeneral';
import ShowSitePageNetworkGroups from './ShowSitePageNetworkGroups';
import ShowSitePageReports from './ShowSitePageReports';
import ShowSitePageSensors from './ShowSitePageSensors';
import ShowSitePageSentinels from './ShowSitePageSentinels';
import ShowSiteInspectionReport from './SiteInspectionReport';
import ShowSitePageSops from './ShowSitePageSops';
import ShowSitePageSpaces from './ShowSitePageSpaces';
import { determineShouldHideFromInstaller } from '../../utils/user';
import ShowSitePageDocuments from './ShowSitePageDocuments';
import { hasDaResults } from '../../util';
import { accessDeniedError } from '../../utils/ApiError/accessDeniedError';
import { pageNotFoundError } from '../../utils/ApiError/pageNotFoundError';

interface OwnProps extends RouteComponentProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  onDelete: () => void;
  site?: Site;
  editSite?: EditSite;
  orgId?: number;
  hideFromInstaller?: boolean;
  siteApiError: ApiError;
}

interface State {
  showDeletePrompt: boolean;
}

class ShowSitePageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showDeletePrompt: false,
    };
  }

  componentDidMount() {
    this.props.load();
    const { site } = this.props;
    document.title = site?.title || '';
  }

  componentDidUpdate() {
    const { site } = this.props;
    document.title = site?.title || '';
  }

  handleOpenDeletePrompt = () => {
    this.setState({ showDeletePrompt: true });
  };

  handleCloseDeletePrompt = () => {
    this.setState({ showDeletePrompt: false });
  };

  headerDescription(): string {
    const { site } = this.props;
    if (!site) return '';
    return site.address;
  }

  render() {
    const {
      site,
      id,
      onDelete,
      editSite,
      orgId,
      hideFromInstaller,
      siteApiError,
    } = this.props;
    const { showDeletePrompt } = this.state;

    const testResults = site?.installerTestResults;
    const isResultsExist: boolean = hasDaResults(testResults);
    return !Object.keys(siteApiError).length ? (
      <>
        {site && (
          <ShowDetailPageHeader
            name={site.title}
            description={this.headerDescription()}
            isUser={true}
            resourceName="Site"
            onDelete={this.handleOpenDeletePrompt.bind(this)}
            hideDelete={hideFromInstaller}
          />
        )}
        <div
          style={
            isResultsExist ? { padding: '0.5rem 3rem' } : { padding: '3rem' }
          }
        >
          {site && <SiteDetailTabs siteId={site.id} />}
          <Switch>
            <Route
              path={`${Routes.SITES}/:id/general`}
              render={({ match }) =>
                match &&
                orgId && (
                  <ShowSitePageGeneral
                    orgId={orgId}
                    {...match.params}
                    viewAsInstaller={hideFromInstaller}
                  />
                )
              }
            />
            <Route
              path={`${Routes.SITES}/:id/installations`}
              render={({ match }) =>
                match &&
                orgId && (
                  <ShowSitePageGeneral
                    orgId={orgId}
                    {...match.params}
                    showReports
                  />
                )
              }
            />
            <Route
              path={`${Routes.SITES}/:id${Routes.DOCUMENTS}`}
              render={({ match }) =>
                match &&
                site && (
                  <ShowSitePageDocuments
                    site={site}
                    viewAsInstaller={hideFromInstaller}
                    {...match.params}
                  />
                )
              }
            />
            {!hideFromInstaller && (
              <Route
                path={`${Routes.SITES}/:id${Routes.SOPS}`}
                render={({ match }) =>
                  match &&
                  site && (
                    <ShowSitePageSops
                      site={site}
                      orgId={orgId}
                      {...match.params}
                    />
                  )
                }
              />
            )}
            <Route
              path={`${Routes.SITES}/:id${Routes.SPACES}`}
              render={({ match }) =>
                match && <ShowSitePageSpaces {...match.params} />
              }
            />
            <Route
              path={`${Routes.SITES}/:id${Routes.NETWORK_GROUPS}`}
              render={({ match }) =>
                match && (
                  <ShowSitePageNetworkGroups {...match.params} orgId={orgId} />
                )
              }
            />
            <Route
              path={`${Routes.SITES}/:id${Routes.SENSORS}`}
              render={({ match }) =>
                match && <ShowSitePageSensors {...match.params} />
              }
            />
            <Route
              path={`${Routes.SITES}/:id${Routes.EQUIPMENT}`}
              render={({ match }) =>
                match && <ShowSitePageEquipment {...match.params} />
              }
            />
            <Route
              path={`${Routes.SITES}/:id${Routes.GATEWAYS}`}
              render={({ match }) =>
                match && <ShowSitePageGateways {...match.params} />
              }
            />
            <Route
              path={`${Routes.SITES}/:id${Routes.DISTRIBUTION_PANELS}`}
              render={({ match }) =>
                match && (
                  <ShowSitePageDistributionPanels
                    siteTitle={site && site.title}
                    {...match.params}
                  />
                )
              }
            />
            {!hideFromInstaller && (
              <Route
                path={`${Routes.SITES}/:id${Routes.SENTINELS}`}
                render={({ match }) =>
                  match && (
                    <ShowSitePageSentinels
                      redirect={`${Routes.SITES}/${id}/`}
                      {...match.params}
                    />
                  )
                }
              />
            )}
            {!hideFromInstaller && (
              <Route
                path={`${Routes.SITES}/:id${Routes.REPORTS}`}
                render={({ match }) =>
                  match && <ShowSitePageReports siteId={id} {...match.params} />
                }
              />
            )}
            <Route
              path={`${Routes.SITES}/:id${Routes.INSPECTION}`}
              render={({ match }) =>
                match && (
                  <ShowSiteInspectionReport
                    siteId={id}
                    siteTitle={site?.title}
                    {...match.params}
                  />
                )
              }
            />
            <Route
              path={`${Routes.SITES}/:id/`}
              render={({ match }) =>
                match && <Redirect to={`${match.url}/general`} />
              }
            />
            <Route component={pageNotFoundError} />
          </Switch>
        </div>

        {showDeletePrompt && (
          <DeleteModal
            onClose={this.handleCloseDeletePrompt}
            onDelete={onDelete}
            apiError={
              editSite &&
              renderAPIerror(editSite.apiError, SiteActions.DELETE_SITE_ERROR)
            }
            title={site ? site.title : 'this site'}
          />
        )}
      </>
    ) : (
      accessDeniedError(siteApiError)
    );
  }
}

const mapStateToProps = (
  { app, sites, organizations }: ApplicationState,
  { id }: OwnProps
) => ({
  orgId: app.currentOrganizationId,
  site: sites.sitesById[parseInt(id)],
  editSite: sites.editById[parseInt(id)],
  hideFromInstaller: determineShouldHideFromInstaller(
    app.currentUser,
    organizations.currentOrganization
  ),
  siteApiError: sites.siteApiError,
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => dispatch(getSite(id)),
  onDelete: () => dispatch(destroy(id)),
  onCancel: () => dispatch(reset(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowSitePageContainer)
);
