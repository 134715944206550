import {
  Button,
  InputField,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
  FormText,
  SingleDatePicker,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as EquipmentActions,
  create,
  displayFormErrors,
  hideNewEquipmentModal,
  updateField,
} from '../../actions/equipment';
import ModalFormContent from '../../components/ModalFormContent';
import { useAppLocale } from '../../hooks/useAppDetails';
import { ApplicationState } from '../../reducers';
import { CreateNewText, PropertyToLabel } from '../../types/global';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import SelectEquipmentGroup from '../Selects/SelectEquipmentGroup';
import SelectEquipmentType from '../Selects/SelectEquipmentType';
import SelectSite from '../Selects/SelectSite';
import SelectSpace from '../Selects/SelectSpace';
import styles from './EquipmentTable.module.css';

import { DateTime } from 'luxon';

interface Props {
  lockSiteId?: number;
  //used in circuitBreakerForm
  breakerIdToUpdate?: number | 'new';
}

const NewEquipmentModal: React.FC<Props> = props => {
  const { lockSiteId, breakerIdToUpdate } = props;
  const locale = useAppLocale();

  const [siteId, setSiteId] = useState(lockSiteId || -1);

  const site = useSelector(({ sites }: ApplicationState) => {
    if (siteId !== -1) return undefined;
    return sites.sitesById[siteId];
  });

  const {
    isVisible,
    // properties of unpacked equipment.editById['new']: EditEquipment
    isLoading,
    formErrors,
    fields,
    apiError,
    formErrorsVisible,
    // end of EditEquipment properties
  } = useSelector(({ equipment }: ApplicationState) => ({
    isVisible: equipment.showNewEquipmentModal,
    ...equipment.editById['new'],
  }));

  const dispatch = useDispatch();
  const onClose = () => dispatch(hideNewEquipmentModal());
  const onChange = (field: string, value: any) =>
    dispatch(updateField('new', field, value));
  const onCreate = ({
    siteId,
    breakerIdToUpdate,
  }: {
    siteId?: number;
    breakerIdToUpdate?: number | 'new';
  }) => dispatch(create({ siteId, breakerIdToUpdate }));
  const onEquipmentCreate = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate({
        siteId: lockSiteId,
        breakerIdToUpdate,
      });
    }
  };

  // Render starts here
  if (!isVisible) return null;

  const {
    title,
    description,
    groupId,
    typeId,
    spaceId,
    model,
    vendor,
    serialNumber,
    manufactureDate,
    customerEquipmentId,
  } = fields;

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button disabled={isLoading} onClick={onEquipmentCreate}>
        Add
      </Button>
    </>
  );

  const manufactureDT = manufactureDate
    ? DateTime.fromISO(manufactureDate)
    : undefined;
  const displayDate = manufactureDT
    ? manufactureDT.toFormat('yyyy-MM-dd')
    : undefined;

  return (
    <Modal onClose={onClose} actions={actions} disableEscapeClose>
      <ModalTitle>{CreateNewText.EQUIPMENT}</ModalTitle>
      <ModalContent>
        <ModalFormContent>
          <div>
            <Label required>{PropertyToLabel.title}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="title"
              autoComplete="title"
              value={title}
              onChange={e => onChange('title', e.currentTarget.value)}
              error={formErrorsVisible && !!formErrors.title}
              customErrorText={formErrors.title && formErrors.title[0]}
            />
          </div>

          <div>
            <Label>{PropertyToLabel.customerEquipmentId}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="customerEquipmentId"
              value={customerEquipmentId}
              onChange={e =>
                onChange('customerEquipmentId', e.currentTarget.value || null)
              }
              error={formErrorsVisible && !!formErrors.customerEquipmentId}
              customErrorText={
                formErrors.customerEquipmentId &&
                formErrors.customerEquipmentId[0]
              }
            />
          </div>

          <div>
            <Label>{PropertyToLabel.description}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="description"
              value={description}
              autoComplete="description"
              onChange={e => onChange('description', e.currentTarget.value)}
            />
          </div>
        </ModalFormContent>
        <ModalFormContent>
          <div>
            <Label required>{PropertyToLabel.groupId}</Label>
          </div>
          <div>
            <SelectEquipmentGroup
              value={groupId}
              onSelect={value => {
                onChange('groupId', value);
                onChange('typeId', -1);
              }}
              error={formErrorsVisible && !!formErrors.groupId}
              customErrorText={formErrors.groupId && formErrors.groupId[0]}
            />
          </div>

          <div>
            <Label required>{PropertyToLabel.typeId}</Label>
          </div>
          <div>
            <SelectEquipmentType
              groupId={groupId}
              value={typeId}
              onSelect={value => onChange('typeId', value)}
              error={formErrorsVisible && !!formErrors.typeId}
              customErrorText={formErrors.typeId && formErrors.typeId[0]}
            />
          </div>

          <div>
            <Label required>{PropertyToLabel.siteId}</Label>
          </div>
          <div>
            <SelectSite
              disabled={!!lockSiteId}
              onSelect={value => {
                setSiteId(value);
                onChange('spaceId', -1);
              }}
              value={siteId}
            />
          </div>

          <div>
            <Label required>{PropertyToLabel.spaceId}</Label>
          </div>
          <div>
            <SelectSpace
              onSelect={value => onChange('spaceId', value)}
              value={spaceId}
              siteId={siteId}
              error={formErrorsVisible && !!formErrors.spaceId}
              customErrorText={formErrors.spaceId && formErrors.spaceId[0]}
            />
          </div>
          <div>
            <Label>{PropertyToLabel.manufacturer}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="vendor"
              autoComplete="vendor"
              value={vendor}
              onChange={({ currentTarget }) =>
                onChange('vendor', currentTarget.value)
              }
            />
          </div>
          <div>
            <Label>{PropertyToLabel.model}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="model"
              autoComplete="model"
              value={model}
              onChange={({ currentTarget }) =>
                onChange('model', currentTarget.value)
              }
            />
          </div>
          <div>
            <Label>{PropertyToLabel.serialNumber}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="serialNumber"
              autoComplete="serial number"
              value={serialNumber}
              onChange={({ currentTarget }) =>
                onChange('serialNumber', currentTarget.value)
              }
            />
          </div>
          <div>
            <Label>{PropertyToLabel.manufactureDate}</Label>
          </div>
          <div>
            <SingleDatePicker
              value={displayDate}
              setDate={(date: string) => {
                onChange(
                  'manufactureDate',
                  DateTime.fromFormat(date, 'yyyy-MM-dd').toISO()
                );
              }}
              locale={locale}
              ianaTimeZoneCode={site?.timeZone}
              className={styles.datePicker}
            />
          </div>
        </ModalFormContent>

        <ModalFormContent>
          <FormText>* Mandatory fields</FormText>
        </ModalFormContent>
        {renderAPIerror(apiError, EquipmentActions.CREATE_EQUIPMENT_ERROR)}
      </ModalContent>
    </Modal>
  );
};

export default NewEquipmentModal;
