import {
  EnergyPro,
  EnergySensor,
} from '@energybox/react-ui-library/dist/types';
import { ApplicationState } from '../../../reducers';
import {
  Card,
  CardContent,
  CardTitle,
} from '@energybox/react-ui-library/dist/components';
import { connect, useDispatch, useSelector } from 'react-redux';
import CircuitBreakerReadings from '../../../components/CircuitBreaker/CircuitBreakerReadings';
import CircuitBreakerPhasorDiagram from '../../../components/CircuitBreaker/CircuitBreakerPhasorDiagram';
import PowerReadings from '../../../components/CircuitBreaker/PowerReadings';
import { getBreakerToEnergySensorsMapping } from '../../../utils/energyPro';
import styles from './EnergyProPhasorModal.module.css';
import { useEffect, useRef, useState } from 'react';
import { hideEnergyProPhasorModal } from '../../../actions/circuit_breakers';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';
import { ProposedReadingByUuid } from '../../../reducers/subscribedEnergyPros';

interface OwnProps {
  equipmentName?: string;
  equipmentId: number;
  breakerIds: number[];
  energyPro: EnergyPro;
  indexStrings: string[];
}

interface Props extends OwnProps {
  isVisible: boolean;
}

const EnergyProPhasorModal: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { equipmentName, breakerIds, energyPro, indexStrings } = props;

  const [selectedSensorIndex, setSelectedSensorIndex] = useState<number | null>(
    null
  );

  const proposedReadingByUuid = useSelector<
    ApplicationState,
    ProposedReadingByUuid
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.proposedReadingByUuid;
  }, equals);

  const proposedReadingByIndex =
    (energyPro && proposedReadingByUuid[energyPro.uuid]) || {};

  const breakerToEnergySensorsMapping = getBreakerToEnergySensorsMapping(
    energyPro
  );

  let allSensors: EnergySensor[] = breakerIds
    .map(id => {
      if (isDefined(id) && breakerToEnergySensorsMapping[id]) {
        return breakerToEnergySensorsMapping[id];
      }
      return [];
    })
    .flat();

  const uniqueSensors = allSensors.filter(
    (value, index, self) => index === self.findIndex(t => t.id === value.id)
  );

  const energySensors = uniqueSensors.sort((a, b) => a.phase - b.phase);

  const useClickOutside = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(hideEnergyProPhasorModal());
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef);

  if (!props.isVisible) return null;
  return (
    <div className={styles.modal} ref={wrapperRef}>
      <div className={styles.arrow} />
      <Card className={styles.card}>
        <CardContent>
          <CardTitle className={styles.title}>{equipmentName || ''}</CardTitle>

          <div className={styles.indexContainer}>
            <div className={styles.indexTitle}>Index</div>
            <div className={styles.indexStrings}>
              {indexStrings?.length > 0
                ? indexStrings.map(s => (
                    <span className={styles.indexString} key={`index${s}`}>
                      {s}
                    </span>
                  ))
                : global.NOT_AVAILABLE}
            </div>
          </div>

          <CircuitBreakerReadings
            proposedReadingByIndex={proposedReadingByIndex}
            selectedSensorIndex={selectedSensorIndex}
            setSelectedSensorIndex={setSelectedSensorIndex}
            energySensors={energySensors}
          />

          <div className={styles.diagramContainer}>
            <CircuitBreakerPhasorDiagram
              proposedReadingByIndex={proposedReadingByIndex}
              energySensors={energySensors}
              selectedSensorIndex={selectedSensorIndex}
            />
            <PowerReadings
              energySensors={
                isDefined(selectedSensorIndex) &&
                energySensors[selectedSensorIndex]
                  ? [energySensors[selectedSensorIndex]]
                  : energySensors
              }
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (
  { circuitBreakers }: ApplicationState,
  { equipmentId }: OwnProps
) => {
  return {
    isVisible:
      circuitBreakers.showEnergyProPhasorModal.value &&
      equipmentId === circuitBreakers.showEnergyProPhasorModal.id,
  };
};

export default connect(mapStateToProps)(EnergyProPhasorModal);
