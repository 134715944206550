import {
  Card,
  CardContent,
  Label,
  RunConfigTestWarning,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import { useSubscribeToThermostat } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import {
  Equipment,
  HvacControl,
  MeasurementSystemToTemperatureLabel,
  Thermostat,
  WorkingMode,
  HvacControlLabel,
  ThermostatWorkingMode,
  ThermostatWorkingModeLabel,
  ControlBoard,
  FanModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { HvacSop } from '@energybox/react-ui-library/dist/types/Sop';
import {
  global,
  isDefined,
  createTemperatureString,
  classNames,
  renderLocalAdjustmentDegree,
  capitalizeFirstLetterOnly,
} from '@energybox/react-ui-library/dist/utils';
import { pluralize } from '@energybox/react-ui-library/dist/utils/util';
import {
  renderOverrideTime,
  showThermostatDisplay,
  getThermostatDisplayLabel,
} from '@energybox/react-ui-library/dist/utils/thermostat';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceReadings,
  unsubscribeFromDeviceReadings,
} from '../../../actions/streamApi';
import { useSelectControlBoardByEquipmentId } from '../../../hooks/useControlBoard';
import { useGetHvacSopByEquipmentId } from '../../../hooks/useHvacSops';
import {
  useCurrentUser,
  useIs12HrTimeFormat,
  useAppLocale,
} from '../../../hooks/useAppDetails';

import ControlCardNotes from '../../../components/ControlCardNotes';
import {
  HvacSopSchedulesTable,
  HvacEdmSchedulesTable,
} from '../../../components/HvacSopSchedulesTable';
import ModalFormContent from '../../../components/ModalFormContent';
import UpdateControlModeOptions from '../../../components/UpdateControlModeOptions';
import LastNetworkGroupConfigUpdate from '../../../components/LastNetworkGroupConfigUpdate';
import { ApplicationState } from '../../../reducers';
import { convertSecToHrsText } from '../../../utils/controls';
import { getHvacSopRoute } from '../../../utils/hvacSop';
import {
  renderLocalOverride,
  renderOnlineStatus,
  determineLiveStatusFieldTextColor,
  getRemoteSensorsCount,
  displayActiveOrIdle,
  determineScreenLockedTextColor,
} from '../HvacControlsContent';
import styles from './HvacSopControlsCards.module.css';
import { useGetNetworkGroupById } from '../../../hooks/useGetNetworkGroup';
import { useEdmSchedules } from '../../../hooks/useEdmConfig';
import EdmOverride from './EdmOverride';
import { useGetSite, useSiteTimezone } from '../../../hooks/useSites';
import FeatureFlag from '../../FeatureFlag';
import { Routes } from '../../../routes';
import { hasDaResults } from '../../../util';
import { DeviceStatusById } from '../../../reducers/deviceStatus';
import RecordInfoTooltip from '../../../components/RecordBubble/RecordInfoTooltip';
import { Vendor } from '@energybox/react-ui-library/dist/types';

type Props = {
  siteId: number;
  equipment: Equipment;
  hvacControl: HvacControl | undefined;
  isNew: boolean;
  controlBoardIdViaActuator: number | undefined;
};

//Hold Live status data for CAM CARD in equipment page
const HvacControlsInfoCard: React.FC<Props> = ({
  siteId,
  equipment,
  hvacControl,
  isNew,
  controlBoardIdViaActuator,
}) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const locale = useAppLocale();
  const { getEdmSchedulesBySiteId } = useEdmSchedules();
  const is12HrFormat = useIs12HrTimeFormat();

  const networkGroupId = hvacControl?.thermostat?.networkGroupId;

  const hvacSop = useGetHvacSopByEquipmentId(equipment);
  const { networkGroup } = useGetNetworkGroupById(networkGroupId);

  const thereIsNoHvacSop = !isDefined(hvacSop);
  const hvacSopComponent = hvacSop?.component as HvacSop | undefined;
  const {
    enableEdm,
    enableLocalAdjustment,
    setPointLimitDelta,
    overrideTimer,
    thermostatDisplayUnits,
  } = hvacSopComponent?.hvacSettings || {};

  const site = useGetSite(siteId);
  const daResultsExist: boolean = hasDaResults(site?.installerTestResults);
  useEffect(() => {
    getEdmSchedulesBySiteId(siteId);
  }, [enableEdm, siteId]);

  //assumes thermostats are fetched in parent component
  const thermostat: Thermostat | undefined = useSelector(
    ({ thermostats }: ApplicationState) => {
      if (!thermostats.thermostatsBySiteId[siteId]) return;
      return thermostats.thermostatsBySiteId[siteId].find(
        thermostat => thermostat.id == hvacControl?.thermostatId
      );
    }
  );
  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);

  const thermostatById = useSelector(
    ({ subscribedThermostats }: ApplicationState) => {
      if (thermostat) {
        if (
          subscribedThermostats.byId[thermostat.id] &&
          isDefined(
            subscribedThermostats.byRFId?.[thermostat.id]
              ?.temperatureSensorStatus
          )
        ) {
          subscribedThermostats.byId[thermostat.id].temperatureSensorStatus =
            subscribedThermostats.byRFId?.[
              thermostat.id
            ]?.temperatureSensorStatus;
        }
        return subscribedThermostats.byId[thermostat.id];
      }
      return undefined;
    }
  );

  const subscribedThermostat = useSubscribeToThermostat(
    thermostat,
    () => thermostatById,
    dispatch,
    subscribeToDeviceReadings,
    unsubscribeFromDeviceReadings
  );

  const isDesiredSettingsValid = isDefined(
    subscribedThermostat?.desiredSettings
  );

  const configRemoteSensorsCount = thermostat?.wirelessTemperatureSensorsCount;
  const liveDetectedSensorsCount = getRemoteSensorsCount(
    subscribedThermostat?.additionalTemperatureReadings
  );
  const isLocalAdjustmentActive = (subscribedThermostat?.overrideTime || 0) > 0;

  const isLocalOverrideActive =
    subscribedThermostat?.localAdjustmentOverrideEnergybox;

  const isControlInCalibrationMode =
    isDefined(hvacControl) &&
    hvacControl.workingMode === WorkingMode.CALIBRATION;

  const isControlInForcedOnMode =
    isDefined(hvacControl) && hvacControl.workingMode === WorkingMode.FORCE_ON;

  const isThermostatMapped = isDefined(hvacControl?.thermostatId);
  const isDeviceOnline =
    thermostat &&
    deviceStatusById &&
    deviceStatusById[thermostat.id]?.onlineState === true;

  const isVenstarThermostat = (): boolean => {
    return subscribedThermostat?.vendor == Vendor.VENSTAR;
  };

  const renderTemperatureBySensorStatus = (temperature, sensorStatus, user) => {
    let isReadingValid = temperature !== 0;

    if (!isReadingValid)
      isReadingValid = ![1, 2, 4].includes(sensorStatus % 32);

    return isReadingValid
      ? createTemperatureString(temperature, user)
      : global.NOT_AVAILABLE;
  };

  if (!isDefined(user)) return null;
  return (
    <>
      <Card>
        <div>
          {daResultsExist && (
            <RunConfigTestWarning
              configLink={`${Routes.RUN_CONFIGURATION_TEST}/${siteId}`}
            />
          )}
        </div>
        <CardContent
          hasExtraPadding={!daResultsExist ? true : false}
          className={daResultsExist ? styles.RunConfigTestWarning : ''}
        >
          <div className={styles.cardHeader}>
            <div className={styles.recordInfoTooltip}>
              <div className={styles.boldText}>HVAC SOP & Controls</div>
              <div>
                <RecordInfoTooltip
                  resourceId={hvacControl?.id!}
                  ianaTimeZoneCode={site?.timeZone}
                />
              </div>
            </div>

            <div className={styles.rightAlign}>
              <UpdateControlModeOptions
                hvacControl={hvacControl}
                equipmentId={equipment.id}
                activeControlWorkingMode={hvacControl?.workingMode}
                networkGroupIdViaActuator={networkGroupId}
                controlBoardIdViaActuator={controlBoardIdViaActuator}
                isLocalOverrideActive={isLocalOverrideActive}
                customDisabled={thereIsNoHvacSop || daResultsExist}
              />

              {networkGroup && (
                <div className={styles.lastConfigUpdateContainer}>
                  <div>Last Config Update: </div>
                  <LastNetworkGroupConfigUpdate
                    className={styles.lastConfigUpdate}
                    networkGroup={networkGroup}
                  />
                </div>
              )}

              <div className={styles.controlCardNotesContainer}>
                <ControlCardNotes
                  siteId={siteId}
                  isControlInCalibrationMode={isControlInCalibrationMode}
                  isThermostatNotMapped={isNew}
                  thereIsNoHvacSop={thereIsNoHvacSop}
                />
              </div>
            </div>
          </div>

          <div className={styles.controlsInfoCardContent}>
            <div
              className={`${styles.cardLeftContent} ${
                thereIsNoHvacSop ? styles.grayText : ''
              }`}
            >
              <ModalFormContent className={styles.modalFormContent}>
                <div>
                  <Label>SOP Title</Label>
                </div>
                <div className={styles.valueContainer}>
                  {isDefined(hvacSop?.title) ? (
                    <Link
                      to={getHvacSopRoute({
                        orgUnitId: siteId,
                        hvacSopId: hvacSop?.sopId,
                        // isOrgLevelTimetable: false,
                      })}
                    >
                      {hvacSop?.title}
                    </Link>
                  ) : (
                    global.NOT_AVAILABLE
                  )}
                </div>

                <div>
                  <Label>Equipment Type</Label>
                </div>
                <div className={styles.valueContainer}>
                  {equipment.type?.title || global.NOT_AVAILABLE}
                </div>

                <div>
                  <Label>Thermostat Display</Label>
                </div>
                <div className={styles.valueContainer}>
                  {thermostatDisplayUnits
                    ? MeasurementSystemToTemperatureLabel[
                        thermostatDisplayUnits
                      ]
                    : global.NOT_AVAILABLE}
                </div>

                <div>
                  <Label>Local Adjustment</Label>
                </div>
                <div className={styles.valueContainer}>
                  {isDefined(enableLocalAdjustment)
                    ? enableLocalAdjustment === true
                      ? 'Enabled'
                      : 'Disabled'
                    : global.NOT_AVAILABLE}
                </div>

                <div>
                  <Label>Setpoint Limit</Label>
                </div>
                <div className={styles.valueContainer}>
                  {isDefined(setPointLimitDelta) &&
                  Number.isSafeInteger(setPointLimitDelta)
                    ? `+/-${setPointLimitDelta} ${pluralize(
                        'degree',
                        setPointLimitDelta
                      )}`
                    : global.NOT_AVAILABLE}
                </div>

                <div>
                  <Label>Revert Adjustment</Label>
                </div>
                <div className={styles.valueContainer}>
                  {isDefined(overrideTimer) &&
                  Number.isSafeInteger(overrideTimer)
                    ? convertSecToHrsText(overrideTimer)
                    : global.NOT_AVAILABLE}
                </div>

                <FeatureFlag>
                  <>
                    <div>
                      <Label>EDM</Label>
                    </div>
                    <div className={styles.valueContainer}>
                      {isDefined(enableEdm)
                        ? enableEdm === true
                          ? 'Enabled'
                          : 'Disabled'
                        : global.NOT_AVAILABLE}
                    </div>
                  </>
                </FeatureFlag>
              </ModalFormContent>

              <HvacSopSchedulesTable
                isControlInForcedOnMode={isControlInForcedOnMode}
                hvacSopComponent={hvacSopComponent}
              />

              {enableEdm && (
                <FeatureFlag>
                  <div className={styles.edmTable}>
                    <HvacEdmSchedulesTable
                      equipmentId={equipment.id}
                      locale={locale}
                      is12HrFormat={is12HrFormat}
                    />
                  </div>
                </FeatureFlag>
              )}
            </div>

            <div className={styles.cardRightContent}>
              <div
                className={`${styles.column} ${
                  !isThermostatMapped ? styles.grayText : ''
                }`}
              >
                <Label>
                  <span className={styles.boldText}>Live Status</span>
                </Label>
                <div />

                <Label>{HvacControlLabel.ONLINE_STATUS}</Label>
                <div className={styles.value}>
                  {renderOnlineStatus(thermostat)}
                </div>

                <div className={styles.dividerLine} />
                <span className={styles.streamTimestampText}>
                  Updated{' '}
                  {<TimeDistance timestamp={subscribedThermostat?.timestamp} />}
                </span>

                <Label>{HvacControlLabel.THERMOSTAT_TEMPERATURE}</Label>
                <div className={styles.value}>
                  {isDefined(subscribedThermostat) &&
                  isDeviceOnline &&
                  isDefined(subscribedThermostat?.temperature) &&
                  (isVenstarThermostat() ||
                    isDefined(subscribedThermostat?.temperatureSensorStatus))
                    ? renderTemperatureBySensorStatus(
                        subscribedThermostat.temperature,
                        subscribedThermostat?.temperatureSensorStatus,
                        user
                      )
                    : global.NOT_AVAILABLE}
                </div>

                {isVenstarThermostat() && (
                  <>
                    <Label>{HvacControlLabel.NUMBER_OF_SENSORS}</Label>
                    <div
                      className={classNames(
                        isThermostatMapped
                          ? determineLiveStatusFieldTextColor(
                              configRemoteSensorsCount,
                              liveDetectedSensorsCount
                            )
                          : '',
                        styles.value
                      )}
                    >
                      {`${
                        isDefined(liveDetectedSensorsCount)
                          ? liveDetectedSensorsCount
                          : global.NOT_AVAILABLE
                      } detected / ${
                        isDefined(configRemoteSensorsCount)
                          ? configRemoteSensorsCount
                          : global.NOT_AVAILABLE
                      } configured`}
                    </div>
                  </>
                )}

                <div className={styles.dividerLine} />
                <div className={styles.dividerLine} />

                {isDefined(subscribedThermostat) &&
                  isDeviceOnline &&
                  (subscribedThermostat?.mode ===
                    ThermostatWorkingMode.HEATING ||
                    subscribedThermostat?.mode ===
                      ThermostatWorkingMode.AUTO) && (
                    <>
                      <Label>{HvacControlLabel.MIN_TEMP}</Label>
                      <div>
                        <div
                          className={classNames(
                            determineLiveStatusFieldTextColor(
                              subscribedThermostat?.desiredSettings
                                ?.heatSetPoint,
                              subscribedThermostat?.heatSetPoint,
                              isDesiredSettingsValid
                            ),
                            styles.value
                          )}
                        >
                          {isDefined(subscribedThermostat.heatSetPoint)
                            ? createTemperatureString(
                                subscribedThermostat.heatSetPoint,
                                user,
                                0
                              )
                            : global.NOT_AVAILABLE}
                        </div>

                        {/* only display local adjustment temp difference
                    when revert timer is active */}
                        {isLocalAdjustmentActive && isDeviceOnline ? (
                          <div className={styles.sopDeviation}>
                            {renderLocalAdjustmentDegree(
                              subscribedThermostat,
                              user
                            )}
                          </div>
                        ) : (
                          global.NOT_AVAILABLE
                        )}
                      </div>
                    </>
                  )}

                {isDefined(subscribedThermostat) &&
                  isDeviceOnline &&
                  (subscribedThermostat?.mode ===
                    ThermostatWorkingMode.COOLING ||
                    subscribedThermostat?.mode ===
                      ThermostatWorkingMode.AUTO) && (
                    <>
                      <Label>{HvacControlLabel.MAX_TEMP}</Label>
                      <div>
                        <div
                          className={classNames(
                            determineLiveStatusFieldTextColor(
                              subscribedThermostat?.desiredSettings
                                ?.coolSetPoint,
                              subscribedThermostat?.coolSetPoint,
                              isDesiredSettingsValid
                            ),
                            styles.value
                          )}
                        >
                          {isDefined(subscribedThermostat.coolSetPoint)
                            ? createTemperatureString(
                                subscribedThermostat.coolSetPoint,
                                user,
                                0
                              )
                            : global.NOT_AVAILABLE}
                        </div>

                        {/* only display local adjustment temp difference
                    when revert timer is active */}
                        {isLocalAdjustmentActive && isDeviceOnline ? (
                          <div className={styles.sopDeviation}>
                            {renderLocalAdjustmentDegree(
                              subscribedThermostat,
                              user
                            )}
                          </div>
                        ) : (
                          global.NOT_AVAILABLE
                        )}
                      </div>
                    </>
                  )}

                <Label>{HvacControlLabel.REVERT_ADJUSTMENT} in</Label>
                <div className={styles.value}>
                  {isDefined(subscribedThermostat) &&
                  isDeviceOnline &&
                  isDefined(subscribedThermostat.overrideTime)
                    ? renderOverrideTime(
                        subscribedThermostat.overrideTime,
                        overrideTimer || 0,
                        subscribedThermostat?.vendor !== 'energybox'
                      )
                    : global.NOT_AVAILABLE}
                </div>

                <Label>{HvacControlLabel.THERMOSTAT_MODE}</Label>
                <div
                  className={classNames(
                    determineLiveStatusFieldTextColor(
                      subscribedThermostat?.desiredSettings?.mode,
                      subscribedThermostat?.mode,
                      isDesiredSettingsValid
                    ),
                    styles.value
                  )}
                >
                  {isDefined(subscribedThermostat) &&
                  isDeviceOnline &&
                  isDefined(subscribedThermostat.mode)
                    ? ThermostatWorkingModeLabel[subscribedThermostat.mode]
                    : global.NOT_AVAILABLE}
                </div>

                <Label>{HvacControlLabel.FAN_MODE}</Label>
                <div
                  className={classNames(
                    determineLiveStatusFieldTextColor(
                      subscribedThermostat?.desiredSettings?.fanMode,
                      subscribedThermostat?.fanMode,
                      isDesiredSettingsValid
                    ),
                    styles.value
                  )}
                >
                  {isDefined(subscribedThermostat) &&
                  isDeviceOnline &&
                  isDefined(subscribedThermostat.fanMode) &&
                  subscribedThermostat.mode !== 'OFF'
                    ? FanModeLabel[subscribedThermostat.fanMode]
                    : global.NOT_AVAILABLE}
                </div>

                <Label>{HvacControlLabel.THERMOSTAT_UNIT}</Label>
                <div
                  className={classNames(
                    determineLiveStatusFieldTextColor(
                      subscribedThermostat?.desiredSettings?.tempUnits,
                      subscribedThermostat?.tempUnits,
                      isDesiredSettingsValid
                    ),
                    styles.value
                  )}
                >
                  {isDefined(subscribedThermostat) && isDeviceOnline
                    ? capitalizeFirstLetterOnly(
                        subscribedThermostat.tempUnits || ''
                      )
                    : global.NOT_AVAILABLE}
                </div>

                {isVenstarThermostat() && (
                  <>
                    <Label>{HvacControlLabel.THERMOSTAT_DISPLAY}</Label>
                    <div
                      className={classNames(
                        determineScreenLockedTextColor(
                          enableLocalAdjustment,
                          isDefined(subscribedThermostat)
                            ? showThermostatDisplay(subscribedThermostat)
                            : false
                        ),
                        styles.value
                      )}
                    >
                      {isDefined(subscribedThermostat) &&
                        isDeviceOnline &&
                        getThermostatDisplayLabel(subscribedThermostat)}
                    </div>
                  </>
                )}

                <div className={styles.dividerLine} />
                <div className={styles.dividerLine} />

                {enableEdm && (
                  <FeatureFlag>
                    <EdmOverride
                      subscribedThermostat={subscribedThermostat}
                      is12HrFormat={is12HrFormat}
                    />
                  </FeatureFlag>
                )}

                <Label>Heating Stage 1</Label>
                <div className={styles.value}>
                  {isDefined(subscribedThermostat) && isDeviceOnline
                    ? displayActiveOrIdle(
                        subscribedThermostat.heatingStage1Running
                      )
                    : global.NOT_AVAILABLE}
                </div>

                <Label>Heating Stage 2</Label>
                <div className={styles.value}>
                  {isDefined(subscribedThermostat) && isDeviceOnline
                    ? displayActiveOrIdle(
                        subscribedThermostat.heatingStage2Running
                      )
                    : global.NOT_AVAILABLE}
                </div>

                <Label>Cooling Stage 1</Label>
                <div className={styles.value}>
                  {isDefined(subscribedThermostat) && isDeviceOnline
                    ? displayActiveOrIdle(
                        subscribedThermostat.coolingStage1Running
                      )
                    : global.NOT_AVAILABLE}
                </div>

                <Label>Cooling Stage 2</Label>
                <div className={styles.value}>
                  {isDefined(subscribedThermostat) && isDeviceOnline
                    ? displayActiveOrIdle(
                        subscribedThermostat.coolingStage2Running
                      )
                    : global.NOT_AVAILABLE}
                </div>

                <Label>Fan Output</Label>
                <div className={styles.value}>
                  {isDefined(subscribedThermostat) && isDeviceOnline
                    ? displayActiveOrIdle(subscribedThermostat.fanRunning)
                    : global.NOT_AVAILABLE}
                </div>

                <div className={styles.dividerLine} />
                <div className={styles.dividerLine} />

                <Label>Local Override</Label>
                <div className={styles.value}>
                  {isDeviceOnline
                    ? renderLocalOverride(isLocalOverrideActive)
                    : global.NOT_AVAILABLE}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default HvacControlsInfoCard;
