import {
  FormText,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import { usePrevious } from '@energybox/react-ui-library/dist/hooks';
import {
  GenericErrors,
  TimeTableRowStore,
  TimeTableSpecialStore,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import ToggleItem from '../ToggleItem';
import AddRowButton from './AddRowButton';
import styles from './EditTimeTableForm.module.css';
import TimeTableRow from './TimeTableRow';
import TimeTableSpecialRow from './TimeTableSpecialRow';

interface Props {
  title: string;
  rows: TimeTableRowStore[];
  specials: TimeTableSpecialStore[];
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  addTimeTableRow: (specialRowIndex?: number | boolean) => void;
  addTimeTableSpecialRow: () => void;
  updateField: (
    path: any[],
    field: string | number,
    value: string | TimeTableRowStore | TimeTableSpecialStore,
    validationType: 'timeTable' | 'timeTableRow' | 'specialTimeTableRow'
  ) => void;
  removeTimeTableRow: (
    rowIndex: number,
    specialRowIndex?: number | boolean
  ) => void;
  removeSpecialRow: (rowIndex: number) => void;
  shouldAutoCollapse?: boolean;
  isInDropdown?: boolean;
}

const EditTimeTableForm = ({
  title,
  rows,
  specials,
  addTimeTableRow,
  addTimeTableSpecialRow,
  updateField,
  removeTimeTableRow,
  removeSpecialRow,
  formErrors,
  formErrorsVisible,
  shouldAutoCollapse = false,
  isInDropdown = false,
}: Props) => {
  const prev = usePrevious({ rows, specials }) || { rows: [], specials: [] };
  const isAddingNormalRow = prev.rows.length < rows.length;
  const isAddingSpecialRow = prev.specials.length < specials.length;
  const isAddingRow = isAddingNormalRow || isAddingSpecialRow;

  return (
    <form className={styles.form} onSubmit={e => e.preventDefault()}>
      <div className={styles.container}>
        <Cell width="2">
          <Label htmlFor="timeTableName" required>
            Name
          </Label>
        </Cell>
        <Cell width="6">
          <InputField
            id="timeTableName"
            type="text"
            name="title"
            value={title}
            placeholder="Timetable Name"
            onChange={e =>
              updateField([], 'title', e.currentTarget.value, 'timeTable')
            }
            error={formErrorsVisible && !!formErrors.title}
            customErrorText={!!formErrors.title && formErrors.title[0]}
          />
        </Cell>
        <Cell width="10" />
        <Cell width="12">
          <div className={styles.rowsContainer}>
            <ToggleItem
              startOpen
              isFirstItem
              shouldCollapse={
                shouldAutoCollapse && isAddingSpecialRow && specials.length > 0
              }
              header={<Cell width="12">Default</Cell>}
              alwaysShowHeader
            >
              <Cell width="12">
                {rows.map((row, index) => (
                  <TimeTableRow
                    formErrorsVisible={formErrorsVisible}
                    key={index}
                    row={row}
                    id={index}
                    weekdayPickerId={index}
                    isFirstItem={index === 0}
                    removable={rows.length > 1}
                    shouldCollapse={
                      shouldAutoCollapse &&
                      isAddingRow &&
                      index < rows.length - 1
                    }
                    onChange={(rowIndex, rowValue) =>
                      updateField(['rows'], rowIndex, rowValue, 'timeTableRow')
                    }
                    onRemove={removeTimeTableRow}
                  />
                ))}
              </Cell>
              <Cell width="12">
                <AddRowButton onClick={() => addTimeTableRow(false)}>
                  Add row
                </AddRowButton>
              </Cell>
            </ToggleItem>
            <div>
              {specials.map((special, index) => (
                <TimeTableSpecialRow
                  id={index}
                  addTimeTableRow={() => addTimeTableRow(index)}
                  removeTimeTableRow={rowIndex =>
                    removeTimeTableRow(rowIndex, index)
                  }
                  key={index}
                  special={special}
                  isInDropdown={isInDropdown}
                  shouldCollapse={
                    shouldAutoCollapse &&
                    isAddingRow &&
                    index < specials.length - 1
                  }
                  shouldAutoCollapse={shouldAutoCollapse}
                  onRemove={() => removeSpecialRow(index)}
                  formErrorsVisible={formErrorsVisible}
                  onChange={(path, field, value, validationType) =>
                    updateField(
                      ['specials', ...path],
                      field,
                      value,
                      validationType
                    )
                  }
                />
              ))}
              <AddRowButton onClick={addTimeTableSpecialRow}>
                Add Holidays / Special Events
              </AddRowButton>
            </div>
            <FormText>* Mandatory fields</FormText>
          </div>
        </Cell>
      </div>
    </form>
  );
};

export default EditTimeTableForm;
