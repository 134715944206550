import {
  Button,
  InputField,
  Label,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  ErrorIcon,
  WarningIcon,
  SuccessArtwork,
} from '@energybox/react-ui-library/dist/icons';
import { InspectionDataFieldsByKey } from '@energybox/react-ui-library/dist/types';
import { classNames, isEmail } from '@energybox/react-ui-library/dist/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitSiteInspectionReport } from '../../../../actions/reportworker';
import { useCurrentUser } from '../../../../hooks/useAppDetails';
import { ApplicationState } from '../../../../reducers';
import { InspectionReportLoaded } from '../../../../reducers/inspection';
import { InspectionSubmission } from '../../../../reducers/reportworker';
import styles from './SubmitFormModal.module.css';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../../../../mixpanelEvents';

type ModalProps = {
  data: InspectionReportLoaded;
  isVisible: boolean;
  onClose: (reset?: 'reset') => void;
  siteTitle?: string;
};

enum Steps {
  showConfirmations = 'showConfirmations',
  fillForm = 'fillForm',
  submitted = 'submitted',
  errorSubmitting = 'errorSubmitting',
}

const inspectionDataFieldAsNum = (
  fields: InspectionDataFieldsByKey,
  key: string
) => (fields[key] as number) || 0;

type IssueCounts = {
  errorCount: number;
  warningCount: number;
};

const SubmitFormModal: React.FC<ModalProps> = ({
  data,
  isVisible,
  onClose,
  siteTitle,
}) => {
  const dispatch = useDispatch();

  const { errorCount, warningCount } = useMemo<IssueCounts>(() => {
    const { data: fields } = data;
    const allData: InspectionDataFieldsByKey[] = [
      ...(fields.edge_controllers || []),
      ...(fields.super_edge_controllers || []),
      ...(fields.superhub_sensors || []),
      ...(fields.super_hubs || []),
      ...(fields.site_controllers || []),
      ...(fields.energy_pros || []),
      ...(fields.energy_pro2s || []),
      ...(fields.thermostats || []),
      ...(fields.eb_thermostats || []),
      ...(fields.hubs || []),
      fields.unpaired_sensors || {},
    ];
    return allData.reduce<IssueCounts>(
      (prev, value) => ({
        errorCount:
          prev.errorCount +
          inspectionDataFieldAsNum(value, 'errors') +
          inspectionDataFieldAsNum(value, 'errors_sensors'),
        warningCount:
          prev.warningCount +
          inspectionDataFieldAsNum(value, 'warnings') +
          inspectionDataFieldAsNum(value, 'warnings_sensors'),
      }),
      {
        errorCount: 0,
        warningCount: 0,
      }
    );
  }, [data]);

  const { showConfirmations, fillForm, submitted, errorSubmitting } = Steps;

  const initialStep = useMemo<Steps>(
    () => (errorCount + warningCount ? showConfirmations : fillForm),
    [errorCount, warningCount]
  );

  const [step, setStep] = useState<Steps>(initialStep);
  const [installer, setInstaller] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [comment, setComment] = useState('');
  const isReady = installer && email && isEmail(email);

  const user = useCurrentUser();

  const submitStatus = useSelector<
    ApplicationState,
    InspectionSubmission | undefined
  >(({ reportworker }) => reportworker.sirSubmissions[data.key]);

  const accessToken = useSelector<ApplicationState, string>(
    ({ app }) => app.accessToken || ''
  );

  useEffect(() => {
    if (submitStatus === InspectionSubmission.submitted) {
      setStep(submitted);
      const timeout = setTimeout(onClose, 5000);
      return () => clearTimeout(timeout);
    }
    if (submitStatus === InspectionSubmission.error) {
      setStep(errorSubmitting);
    }
    return () => {
      if (submitStatus === InspectionSubmission.error) {
        setStep(initialStep); // reset the local step state when error is dismissed
      }
    };
  }, [submitStatus]);

  const submit = useCallback(() => {
    if (isReady) {
      dispatch(
        submitSiteInspectionReport({
          accessToken,
          siteId: data.key,
          reportTimestamp: data.report_date,
          email,
          name: installer,
          company,
          comment,
        })
      );

      // Mixpanel tracking
      mixpanel.track(mixpanelEvents.SIR_SUBMITTED, {
        installer,
        siteId: data.key,
        siteTitle: siteTitle,
      });
    }
  }, [
    accessToken,
    data.key,
    data.report_date,
    email,
    installer,
    company,
    comment,
  ]);

  if (!isVisible) return null;

  const cancelButton = (
    <Button
      variant="text"
      onClick={onClose}
      disabled={submitStatus === InspectionSubmission.submitting}
    >
      Cancel
    </Button>
  );

  const actions: {
    [step in Steps]: React.ReactNode;
  } = {
    showConfirmations: (
      <>
        {cancelButton}
        <Button onClick={() => setStep(fillForm)}>Continue</Button>
      </>
    ),
    fillForm: (
      <>
        {cancelButton}
        <Button
          disabled={
            !isReady || submitStatus === InspectionSubmission.submitting
          }
          onClick={submit}
        >
          {submitStatus === InspectionSubmission.submitting ? (
            <Loader size={16} variant="secondary" />
          ) : (
            'Submit'
          )}
        </Button>
      </>
    ),
    submitted: null,
    errorSubmitting: <Button onClick={() => onClose('reset')}>OK</Button>,
  };

  const { error, warning, count } = styles;

  return (
    <Modal
      onClose={onClose}
      actions={actions[step]}
      className={styles.root}
      disableEscapeClose={
        submitStatus && submitStatus !== InspectionSubmission.submitted
      }
    >
      {step === showConfirmations && (
        <>
          <ModalTitle className={styles.modalTitle}>
            <WarningIcon
              width="30"
              height="30"
              className={styles.modalTitleIcon}
            />
            Are you sure you want Submit for Review?
          </ModalTitle>
          <ModalContent className={styles.modalContent}>
            You have the following open issue
            {errorCount + warningCount > 1 && 's'}:
            {errorCount ? (
              <div className={classNames(error, count)}>
                <ErrorIcon width="16" height="16" /> Error ({errorCount})
              </div>
            ) : null}
            {warningCount ? (
              <div className={classNames(warning, count)}>
                <WarningIcon width="16" height="16" /> Warning ({warningCount})
              </div>
            ) : null}
          </ModalContent>
        </>
      )}
      {step === fillForm && (
        <>
          <ModalTitle className={styles.modalTitle}>
            Installer details for closeout
          </ModalTitle>
          <ModalContent className={styles.modalContent}>
            <div className={styles.instruction}>
              <Label>Please add your name and email address</Label>
            </div>
            <Label>
              <b>Logged User</b>
            </Label>
            <div className={styles.predefined}>
              <Label>
                {user?.firstName} {user?.lastName}
              </Label>
            </div>
            <Label required>
              <b>Installer Name</b>
            </Label>
            <div className={styles.inputField}>
              <InputField
                required
                type="text"
                value={installer}
                placeholder="Add name here"
                onChange={e => setInstaller(e.currentTarget.value)}
              />
            </div>
            <Label required>
              <b>Email Address</b>
            </Label>
            <div className={styles.inputField}>
              <InputField
                required
                type="email"
                name="email"
                value={email}
                placeholder="Add email address to receive a confirmation"
                onChange={({ currentTarget }) => setEmail(currentTarget.value)}
                error={!!email && !isEmail(email)}
              />
            </div>
            <Label>
              <b>Company</b>
            </Label>
            <div className={styles.inputField}>
              <InputField
                type="text"
                value={company}
                placeholder="Company Name"
                onChange={e => setCompany(e.currentTarget.value)}
              />
            </div>
            <textarea
              value={comment}
              onChange={({ currentTarget }) => {
                setComment(currentTarget.value);
              }}
              placeholder="Additional Comment to Energybox Team"
            />
          </ModalContent>
        </>
      )}
      {step === submitted && (
        <div className={styles.successMessage}>
          <SuccessArtwork />
          <ModalTitle className={styles.successTitle}>
            Thank You for completing this installation
          </ModalTitle>
          <ModalContent className={styles.modalContent}>
            <div className={styles.successContent}>
              You will receive an confirmation email once the installation
              report is successfully submitted.
            </div>
          </ModalContent>
        </div>
      )}
      {step === errorSubmitting && (
        <>
          <ModalTitle className={styles.modalTitle}>
            <WarningIcon
              width="30"
              height="30"
              className={styles.modalTitleIcon}
            />
            Error Message
          </ModalTitle>
          <ModalContent className={styles.modalContent}>
            <div>
              Sorry! Something went wrong. Please try again after some time.
            </div>
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

export default SubmitFormModal;
