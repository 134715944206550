import {
  DeviceType,
  FirmwareGatewayModel,
  GatewayConfig,
  GatewayInfo,
  GenericErrors,
  NetworkGroup,
  Vendor,
} from '@energybox/react-ui-library/dist/types';
import {
  FormText,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect } from 'react';
import SelectHubModel from '../../containers/Selects/SelectHubModel';
import SelectNetworkGroup from '../../containers/Selects/SelectNetworkGroup';
import SelectSite from '../../containers/Selects/SelectSite';
import SelectSpace from '../../containers/Selects/SelectSpace';
import { PropertyToLabel } from '../../types/global';
import ModalFormContent from '../ModalFormContent';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import { classNames, global } from '@energybox/react-ui-library/dist/utils';
import { getFirmwares } from '../../actions/app';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import styles from './EditHubForm.module.css';
import DeviceOnlineState, {
  DisplayType,
} from '../../containers/DeviceStatus/DeviceOnlineState';
import { useGetSite } from '../../hooks/useSites';

const SuperHubRegion = {
  0: 'US',
  1: 'EU',
  2: 'HK',
  3: 'SINGAPORE',
  4: 'THAILAND',
  5: 'INDONESIA',
  6: 'PHILIPPINES',
  7: 'MALAYSIA',
  8: 'VIETNAM',
  9: 'CHINA',
};

interface Props {
  id?: number;
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  title: string;
  description: string;
  uuid: string;
  spaceId: number;
  networkGroupId: number;
  model: DeviceType;
  siteId?: number;
  isChanged?: boolean;
  formErrorsVisible: boolean;
  lockSiteId?: number;
  firmwareVersion?: string;
  isNew?: 'superHub' | boolean;
  readOnly?: boolean;
  setIsLatestFirmware?: (isLatestFirmware: boolean) => void;
  isNetworkGroupPage?: boolean;
  vendor?: Vendor;
  gatewayInfo?: GatewayInfo;
  gatewayConfig?: GatewayConfig;
}

const EditHubForm = ({
  id,
  onChange,
  title,
  description,
  uuid,
  networkGroupId,
  lockSiteId,
  siteId = -1,
  spaceId,
  model,
  isNew,
  isChanged,
  firmwareVersion,
  formErrors,
  formErrorsVisible,
  readOnly = false,
  setIsLatestFirmware,
  isNetworkGroupPage,
  vendor,
  gatewayConfig,
  gatewayInfo,
}: Props) => {
  const [siteIdInState, setSiteId] = React.useState(lockSiteId || siteId);
  const [formattedUuid, setFormattedUuid] = React.useState<string>(uuid);

  const dispatch = useDispatch();
  const firmwares = useSelector(({ app }: ApplicationState) => app.firmwares);
  const site = useGetSite(siteIdInState);
  const rfPanId = gatewayConfig?.rfPanId;
  const rfPhyId = gatewayConfig?.rfPhyId;
  const hubFirmwareVersion = gatewayInfo?.firmwareVersion;

  const isLatestFirmware = React.useMemo(() => {
    if (!firmwareVersion || !firmwares || model === DeviceType.EB_SUPER_HUB) {
      return true;
    }

    const releaseFirmwares = firmwares.filter(
      firmware => firmware.type === 'RELEASE'
    );

    const latestFirmware = releaseFirmwares.reduce(
      (prev, current) => (prev.version > current.version ? prev : current),
      { version: '' }
    );

    return latestFirmware.version === firmwareVersion;
  }, [firmwareVersion, firmwares]);

  useEffect(() => {
    dispatch(
      getFirmwares({ gatewayModels: [FirmwareGatewayModel.ENERGYBOX_HUB] })
    );
  }, []);

  useEffect(() => {
    if (setIsLatestFirmware) {
      setIsLatestFirmware(isLatestFirmware);
    }
  }, [isLatestFirmware]);

  useEffect(() => {
    // Case where form is rest to initial state
    if (isChanged !== undefined && !isChanged && siteId) {
      setSiteId(siteId);
    }
  }, [siteId, isChanged]);

  const onChangeSite = value => {
    setSiteId(value);
    onChange('spaceId', -1);
    onChange('networkGroupId', -1);
  };

  const onChangeUuid = value => {
    const formattedUuid =
      value
        .replace(/[^0-9A-Fa-f]/g, '')
        ?.slice(0, 12)
        ?.toUpperCase()
        ?.match(/.{1,2}/g)
        ?.join(':') || '';
    setFormattedUuid(formattedUuid);
    onChange('uuid', formattedUuid);
  };

  const isNewSuperHub = isNew === 'superHub';

  const networkGroup = useSelector<ApplicationState, NetworkGroup | undefined>(
    ({ networkGroups }) => {
      const { lastCreatedId, networkGroupsById } = networkGroups;
      if (isNewSuperHub && lastCreatedId) {
        return networkGroupsById[lastCreatedId];
      }
      return undefined;
    }
  );
  useEffect(() => {
    if (networkGroup) {
      if (isNewSuperHub) {
        onChange('title', networkGroup.title.concat(' Hub'));
      } else {
        onChange('title', networkGroup.title);
      }
      onChange('model', DeviceType.EB_SUPER_HUB);
      onChange('networkGroupId', networkGroup.id);
    }
  }, [networkGroup]);

  useEffect(() => {
    setFormattedUuid(uuid);
  }, [uuid]);

  return isNetworkGroupPage ? (
    <div className={styles.cardContent}>
      <div className={styles.columnData}>
        <div className={styles.labelText}>
          <Label>Status</Label>
        </div>
        <div>
          <div className={styles.deviceStatus}>
            <DeviceOnlineState
              displayType={DisplayType.STATUS_WITH_TEXT_AND_TIME}
              devices={[
                {
                  id: id!,
                  uuid: uuid,
                  vendor: vendor!,
                },
              ]}
            />
          </div>
        </div>

        <div className={styles.labelText}>
          <Label>RF Pan ID</Label>
        </div>
        <div className={styles.versionData}>
          {rfPanId !== null ? rfPanId : global.NOT_AVAILABLE}
        </div>
      </div>

      <div className={classNames(styles.columnData, styles.modelContent)}>
        <div className={styles.labelText}>
          <Label>Version</Label>
        </div>
        <div className={styles.versionData}>
          {hubFirmwareVersion ? hubFirmwareVersion : global.NOT_AVAILABLE}
          {!isLatestFirmware && (
            <div className={styles.firmwareStatus}>
              <WarningIcon size={16} />
              <span>
                <b>Update Firmware</b> Not the latest release version
              </span>
            </div>
          )}
        </div>

        <div className={styles.labelText}>
          <Label>Region</Label>
        </div>
        <div className={styles.versionData}>
          {rfPhyId !== undefined
            ? SuperHubRegion[rfPhyId]
            : global.NOT_AVAILABLE}
        </div>
      </div>
    </div>
  ) : (
    <>
      <ModalFormContent>
        <div>
          <Label required={!readOnly}>Device Name</Label>
        </div>
        <div>
          {readOnly && id ? (
            <div
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
            >
              <Link
                to={`${Routes.DEVICES}${Routes.GATEWAYS}/${id}`}
                style={{ fontSize: '0.8rem' }}
              >
                {title}
              </Link>
            </div>
          ) : (
            <InputField
              type="text"
              name="title"
              autoComplete="title"
              value={title}
              onChange={e => onChange('title', e.currentTarget.value)}
              error={formErrorsVisible && !!formErrors.title}
              customErrorText={formErrors.title && formErrors.title[0]}
            />
          )}
        </div>

        <div>
          <Label>Description</Label>
        </div>
        <div>
          <InputField
            type="text"
            name="description"
            value={description}
            autoComplete="description"
            onChange={e => onChange('description', e.currentTarget.value)}
            disabled={readOnly}
          />
        </div>

        <div>
          <Label required={!readOnly}>
            {isNewSuperHub && 'MAC SOUTH '}UUID
          </Label>
        </div>
        <div>
          <InputField
            type="text"
            name="uuid"
            value={formattedUuid}
            autoComplete="uuid"
            onChange={e => onChangeUuid(e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.uuid}
            customErrorText={
              formErrors && formErrors.uuid && formErrors.uuid[0]
            }
            disabled={readOnly || model === DeviceType.EB_SUPER_HUB}
          />
        </div>

        {!isNew && (
          <>
            <div>
              <Label htmlFor="type">Firmware</Label>
            </div>
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                fontSize: '0.75rem',
                justifyContent: 'space-between',
                gap: '0.2rem',
              }}
            >
              <InputField
                type="text"
                name="firmwareVersion"
                value={firmwareVersion || global.NOT_AVAILABLE}
                disabled
              />
              {!isLatestFirmware && (
                <div
                  style={{
                    backgroundColor: '#ef740f25',
                    padding: '0.66rem 0.2rem',
                    width: '100%',
                    fontSize: '0.60rem',
                    gap: '0.2rem',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: '#ef740f75',
                  }}
                >
                  <WarningIcon size={16} />
                  <span>
                    <b>Update Firmware</b> Not the latest release version
                  </span>
                </div>
              )}
            </div>
          </>
        )}

        <div>
          <Label required={!readOnly}>Model</Label>
        </div>
        <div>
          <SelectHubModel
            isNew={
              isNewSuperHub ? 'byNetworkGroup' : isNew ? 'manual' : undefined
            }
            onSelect={value => onChange('model', value)}
            value={model}
            disabled={readOnly}
          />
        </div>

        <div>
          <Label required={!readOnly}>Site</Label>
        </div>
        <div>
          <SelectSite
            disabled={!!lockSiteId}
            onSelect={onChangeSite}
            value={siteIdInState}
          />
        </div>

        <div>
          <Label required={!readOnly}>{PropertyToLabel.spaceId}</Label>
        </div>
        <div>
          <SelectSpace
            onSelect={value => onChange('spaceId', value)}
            value={spaceId}
            siteId={siteIdInState}
            error={formErrorsVisible && !!formErrors.spaceId}
            customErrorText={formErrors.spaceId && formErrors.spaceId[0]}
            disabled={readOnly}
          />
        </div>

        <div>
          <Label required={!readOnly}>Network Group</Label>
        </div>
        <div>
          <SelectNetworkGroup
            siteId={siteIdInState}
            onSelect={value => {
              onChange('networkGroupId', value);
            }}
            value={networkGroupId}
            error={formErrorsVisible && !!formErrors.networkGroupId}
            customErrorText={
              formErrors.networkGroupId && formErrors.networkGroupId[0]
            }
            disabled={
              readOnly || (model === DeviceType.EB_SUPER_HUB && isNew !== true)
            }
          />
        </div>
      </ModalFormContent>
      {!readOnly && (
        <ModalFormContent>
          <FormText>* Mandatory fields</FormText>
        </ModalFormContent>
      )}
    </>
  );
};

export default EditHubForm;
