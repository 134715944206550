import { DateTime } from 'luxon';
import styles from './HvacSopTimetableTooltipContent.module.css';

type props = {
  timetableTitle: string;
  timeRange: string;
  overflowTimestamp: DateTime | null;
  schedulerThreshold: string;
};

const SchedulerTimetableTooltipContent: React.FC<props> = ({
  timetableTitle,
  timeRange,
  overflowTimestamp,
  schedulerThreshold,
}) => {
  return (
    <div>
      <div className={styles.tooltipTitle}>{timetableTitle}</div>
      <div
        className={
          schedulerThreshold
            ? `${styles.tooltipTime}`
            : `${styles.tooltipTime} ${styles.noBorder}`
        }
      >
        {timeRange}
        <span className={styles.overflowTag}>{overflowTimestamp && ' +1'}</span>
      </div>
      {schedulerThreshold ? (
        <div className={styles.thermostatInfo}>
          Threshold : {schedulerThreshold}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SchedulerTimetableTooltipContent;
