import NetworkGroupTable from '../../containers/NetworkGroups/NetworkGroupTable';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';

const OrgNetworkGroupPage = () => {
  const orgId = useCurrentOrganizationId();
  return (
    <>
      <NetworkGroupTable orgId={orgId} isOrgView={true} />
    </>
  );
};

export default OrgNetworkGroupPage;
