import {
  ControlBoard,
  Gateway,
  NetworkGroup,
} from '@energybox/react-ui-library/dist/types';
import SuperHubCardStructure from './SuperHubCardStructure';
import WhitelistTable from '../Gateways/WhitelistTable';
import { ApplicationState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  subscribeToDeviceList,
  unsubscribeFromDeviceList,
} from '../../actions/streamApi';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { showNewSensorModal } from '../../actions/sensors';
import NewSensorModal from '../Sensors/NewSensorModal';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { showNewThermostatModal } from '../../actions/thermostats';
import NewThermostatModal from '../Devices/NewThermostatModal';
import { showControlboardModal } from '../../actions/control_boards';
import NewControlboardModal from '../Devices/NewControlboardModal';
import {
  DeviceInstallInfo,
  getDeviceStatus,
  isGatewayConnectedStatus,
  isInstalled,
  isPaired,
  isThermostatDotInfo,
  ThermostatDotCommonInfo,
  UnifiedDeviceType,
} from '../../reducers/subscribedDeviceList';
import SensorsNearbyTable from '../../components/SensorsNearbyTable';
import RemoteAccessButton from '../../components/RemoteAccessButton/RemoteAccessButton';
import {
  DotRemoteAccess,
  EnergyproRemoteAccess,
  SiteControllerRemoteAccess,
  ThermostatRemoteAccess,
} from '@energybox/react-ui-library/dist/icons';
import { Loader } from '@energybox/react-ui-library/dist/components';
import styles from './NewNetworkGroupModal.module.css';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../../mixpanelEvents';

type Props = {
  gateways?: (Gateway | ControlBoard)[];
  uuid: string;
  networkGroup: NetworkGroup;
};

const SuperHubSensors: React.FC<Props> = ({ gateways, uuid, networkGroup }) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const siteId = networkGroup.siteId;
  const [loading, setLoading] = useState(true);
  const [sensorUuid, setSensorUuid] = useState('0');
  const [retryCount, setRetryCount] = useState(0);

  const balenaStatusBySerialNumber = useSelector(
    ({ balena }: ApplicationState) => {
      return balena.statusBySerialNumber;
    }
  );
  const serialNumber = networkGroup?.edge?.serialNumber;
  const balenaStatus = balenaStatusBySerialNumber?.[serialNumber || ''];
  const buildVersion = balenaStatus?.runningRelease?.[0]?.raw_version;

  const deviceList = useSelector(
    ({ subscribedDeviceList }: ApplicationState) => {
      return subscribedDeviceList.deviceList.devices;
    }
  );

  const whiteList: DeviceInstallInfo[] = [];
  const sensorsNearby: DeviceInstallInfo[] = [];
  const thermostatsNearby: DeviceInstallInfo[] = [];

  deviceList?.forEach(device => {
    const status = getDeviceStatus(device);
    if (status !== null) {
      if (
        (isThermostatDotInfo(status) && status.whitelisted) ||
        (isGatewayConnectedStatus(status) && status.connected)
      ) {
        whiteList.push(device);
      } else {
        if (device.device_type === 'THERMOSTAT') thermostatsNearby.push(device);
        if (device.device_type === 'DOT') sensorsNearby.push(device);
      }
    }
  });

  const getDeviceList = (type: UnifiedDeviceType) => {
    const typeSpecificDevices = whiteList.filter(
      device => device.device_type === type
    );

    // this was mostly for storing uninstalled devices info
    // but now we keep all the data coming from the admin portal in it.
    // even if its installed. this was b/c some fields
    // i.e even if a site controller is installed or not we want the port1/2 lux.
    const additionalDevices = typeSpecificDevices;

    return {
      list: typeSpecificDevices.map(d => d.uuid),
      pairedList: typeSpecificDevices.filter(d => isPaired(d)).map(d => d.uuid),
      installedList: typeSpecificDevices
        .filter(d => isInstalled(d))
        .map(d => d.uuid),
      additionalDeviceInfo:
        additionalDevices.length > 0
          ? additionalDevices.reduce((acc, device) => {
              acc[device.uuid] = device;
              return acc;
            }, {} as { [uuid: string]: DeviceInstallInfo })
          : undefined,
    };
  };

  const openNewModal = (id: string, type: string) => {
    setSensorUuid(id);
    //mixpanel button click tracking
    mixpanel.track(mixpanelEvents.INSTALL_BUTTON_CLICKS, {
      'Button ID': id,
      'Button Type': type, // Sensor, Thermostat, or Controlboard
      Page: window.location.href,
    });

    if (type === 'sensor') {
      dispatch(showNewSensorModal());
    } else if (type === 'thermostat') {
      dispatch(showNewThermostatModal());
    } else {
      dispatch(showControlboardModal());
    }
  };

  useEffect(() => {
    let unsubscribe: (() => void) | null = null; // Explicitly set the type to allow a function or null
    let retryTimeout: NodeJS.Timeout | null = null; // Set timeout type

    if (!uuid) return;

    if (isDefined(uuid)) {
      // Subscribe to device list
      dispatch(subscribeToDeviceList(uuid));

      // Unsubscribe cleanup
      unsubscribe = () => {
        unsubscribeFromDeviceList(uuid);
      };

      // Retry mechanism: If data is not received within 5 seconds, retry
      retryTimeout = setTimeout(() => {
        if (!deviceList?.length && retryCount < 3) {
          setRetryCount(retryCount + 1);
          dispatch(subscribeToDeviceList(uuid));
        }
      }, 5000);
    }

    return () => {
      // Cleanup: unsubscribe and clear any timeout on component unmount
      if (isDefined(unsubscribe)) unsubscribe();
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, [uuid, retryCount]);

  useEffect(() => {
    if (deviceList?.length) {
      setLoading(false); // Stop loading when data is received
    } else {
      setLoading(true); // Keep loading if data is still not received

      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [deviceList]);

  return loading ? (
    <div className={styles.loadbar}>
      <Loader />
    </div>
  ) : !deviceList || deviceList.length === 0 ? (
    <></>
  ) : (
    <>
      <SuperHubCardStructure
        title={'Dots'}
        whiteListContent={
          <WhitelistTable
            isLoading={false}
            onRemoveSensor={() => {}}
            onSensorAdd={() => {}}
            list={getDeviceList('DOT').list}
            isNetworkGroupPage={true}
            isDotsCard={true}
            openNewModal={openNewModal}
            pairedSensors={getDeviceList('DOT').pairedList}
            gateways={gateways}
            additionalDeviceInfo={getDeviceList('DOT').additionalDeviceInfo}
          />
        }
        unpairedListExist={true}
        unpairedListContent={
          <SensorsNearbyTable
            onSensorAdd={() => {}}
            isLoading={false}
            sensorsNearbyList={sensorsNearby}
            isNetworkGroupPage={true}
          />
        }
        remoteAccessButton={
          <RemoteAccessButton
            serialNumber={serialNumber!}
            balenaStatus={balenaStatus}
            path={'/dots/'}
            remoteAccessIcon={<DotRemoteAccess size={28} />}
            isCustomIconRequired={true}
          />
        }
      />

      <SuperHubCardStructure
        title={'Thermostat'}
        whiteListContent={
          <WhitelistTable
            isLoading={false}
            onRemoveSensor={() => {}}
            onSensorAdd={() => {}}
            list={getDeviceList('THERMOSTAT').list}
            isNetworkGroupPage={true}
            isThermostatCard={true}
            openNewModal={openNewModal}
            gateways={gateways}
            user={user}
            pairedSensors={getDeviceList('THERMOSTAT').pairedList}
            additionalDeviceInfo={
              getDeviceList('THERMOSTAT').additionalDeviceInfo
            }
          />
        }
        unpairedListExist={true}
        isThermostatCard={true}
        unpairedListContent={
          <SensorsNearbyTable
            onSensorAdd={() => {}}
            isLoading={false}
            sensorsNearbyList={thermostatsNearby}
            isNetworkGroupPage={true}
          />
        }
        remoteAccessButton={
          <RemoteAccessButton
            serialNumber={serialNumber!}
            balenaStatus={balenaStatus}
            path={'/thermostats/'}
            isCustomIconRequired={true}
            remoteAccessIcon={<ThermostatRemoteAccess size={28} />}
          />
        }
      />

      <SuperHubCardStructure
        title={'SiteController'}
        whiteListContent={
          <WhitelistTable
            isLoading={false}
            list={getDeviceList('SITE_CONTROLLER').list}
            isNetworkGroupPage={true}
            openNewModal={openNewModal}
            gateways={gateways!}
            isSiteControllerCard={true}
            onRemoveSensor={() => {}}
            onSensorAdd={() => {}}
            additionalDeviceInfo={
              getDeviceList('SITE_CONTROLLER').additionalDeviceInfo
            }
          />
        }
        remoteAccessButton={
          <RemoteAccessButton
            serialNumber={serialNumber!}
            balenaStatus={balenaStatus}
            path={'/siteController/'}
            isCustomIconRequired={true}
            remoteAccessIcon={<SiteControllerRemoteAccess size={26} />}
          />
        }
        isControlboardAndEproCard={true}
      />

      <SuperHubCardStructure
        title={'EnergyPro1'}
        whiteListContent={
          <WhitelistTable
            isLoading={false}
            list={getDeviceList('ENERGY_PRO').list}
            isNetworkGroupPage={true}
            isEnergyproCard={true}
            openNewModal={() => {}}
            gateways={gateways!}
            onRemoveSensor={() => {}}
            onSensorAdd={() => {}}
            additionalDeviceInfo={
              getDeviceList('ENERGY_PRO').additionalDeviceInfo
            }
          />
        }
        remoteAccessButton={
          <RemoteAccessButton
            serialNumber={serialNumber!}
            balenaStatus={balenaStatus}
            path={'/energypro/'}
            isCustomIconRequired={true}
            remoteAccessIcon={<EnergyproRemoteAccess size={28} />}
          />
        }
        isControlboardAndEproCard={true}
      />

      <NewSensorModal
        isSuperHubPage={true}
        sensorUuid={sensorUuid}
        lockSiteId={siteId}
      />

      <NewThermostatModal
        siteId={siteId}
        uuid={sensorUuid}
        networkGroupId={gateways?.[0]?.networkGroupId!}
        isThermostatCard={true}
      />

      <NewControlboardModal
        lockSiteId={siteId}
        networkGroupId={gateways?.[0]?.networkGroupId!}
        uuid={sensorUuid}
      />
    </>
  );
};

export default SuperHubSensors;
