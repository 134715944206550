import {
  ApiError,
  EnergyPro,
  GenericErrors,
  mapMainBreakerPoleToPhase,
} from '@energybox/react-ui-library/dist/types';
import { ApplicationState } from '../../../reducers';
import {
  displayFormErrors,
  hideEditCircuitBreakerModal,
  patch,
  updateField,
  Actions as CircuitBreakerActions,
} from '../../../actions/circuit_breakers';
import {
  displayFormErrors as displayMBFormErrors,
  patch as mbPatch,
  updateField as mbUpdateField,
} from '../../../actions/main_breakers';
import { EditableFields } from '../../../reducers/circuit_breakers';
import { EditableFields as MBEditableFields } from '../../../reducers/main_breakers';
import {
  global,
  hasKeys,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Checkbox,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { connect, useSelector } from 'react-redux';
import ModalFormContent from '../../../components/ModalFormContent';
import SelectBreakerRating from '../../Selects/SelectBreakerRating';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import CircuitBreakerReadings from '../../../components/CircuitBreaker/CircuitBreakerReadings';
import CircuitBreakerPhasorDiagram from '../../../components/CircuitBreaker/CircuitBreakerPhasorDiagram';
import PowerReadings from '../../../components/CircuitBreaker/PowerReadings';
import { getBreakerToEnergySensorsMapping } from '../../../utils/energyPro';
import styles from './EditCircuitBreakerModal.module.css';
import { ProposedReadingByUuid } from '../../../reducers/subscribedEnergyPros';
import equals from 'ramda/src/equals';

interface OwnProps {
  breakerId: number;
  panelId: number;
  energyPro: EnergyPro;
  isMainBreaker?: boolean;
  mainBreakerPos?: number;
  poles?: number;
  siteTotalChecked?: boolean;
  isSiteTotalCheckboxDisabled?: boolean;
}

interface Props extends OwnProps {
  onChange: (field: string, value: any) => void;
  onSave: (panelId: number) => void;
  onClose: () => void;
  fields: EditableFields | MBEditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  formErrorsVisible: boolean;
  displayFormErrors: (id: string) => void;
  apiError: ApiError;
}

const EditCircuitBreakerModal: React.FC<Props> = props => {
  const {
    panelId,
    isMainBreaker,
    poles,
    siteTotalChecked,
    isSiteTotalCheckboxDisabled,
    energyPro,
    fields,
    onChange,
    onClose,
    onSave,
    isLoading,
    formErrors,
    formErrorsVisible,
    displayFormErrors,
    apiError,
  } = props;

  const proposedReadingByUuid = useSelector<
    ApplicationState,
    ProposedReadingByUuid
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.proposedReadingByUuid;
  }, equals);

  const proposedReadingByIndex =
    (energyPro && proposedReadingByUuid[energyPro.uuid]) || {};

  const { title, description, rating } = fields;
  const breakerToEnergySensorsMapping = getBreakerToEnergySensorsMapping(
    energyPro
  );
  const mainBreakerSensors = energyPro.sensors;
  const energySensors = isMainBreaker
    ? mainBreakerSensors
    : breakerToEnergySensorsMapping[props.breakerId];

  const onCircuitBreakerSave = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors(String(props.breakerId));
    } else {
      onSave(panelId);
      if (isMainBreaker) {
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onCircuitBreakerSave}>
        Save
      </Button>
    </>
  );

  if (!props.isVisible) return null;

  return (
    <div
      className={styles.modal}
      style={(isMainBreaker && { top: '-2rem' }) || undefined}
    >
      <div
        className={styles.arrow}
        style={(isMainBreaker && { top: '3.25rem' }) || undefined}
      />
      <Card>
        <CardContent>
          <CardTitle className={styles.title}>Breaker</CardTitle>
          <ModalFormContent>
            <div>
              <Label htmlFor="title">Breaker Name</Label>
            </div>
            <div>
              <InputField
                id="title"
                type="text"
                name="title"
                value={title}
                onChange={e => onChange('title', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.title}
              />
            </div>

            <div>
              <Label htmlFor="description">Description</Label>
            </div>
            <div>
              <InputField
                id="description"
                type="text"
                name="description"
                placeholder=""
                value={description || ''}
                onChange={e => onChange('description', e.currentTarget.value)}
              />
            </div>

            <div>
              <Label htmlFor="rating">Breaker rating (Amperes)</Label>
            </div>
            <div>
              <SelectBreakerRating
                value={(rating && Number(rating)) || undefined}
                error={formErrorsVisible && !!formErrors.rating}
                onSelect={selectedRating => onChange('rating', selectedRating)}
              />
            </div>

            {isMainBreaker && (
              <>
                <div>
                  <Label htmlFor="siteTotal">Site total</Label>
                </div>
                <div className={styles.centeredField}>
                  <Checkbox
                    checked={
                      isSiteTotalCheckboxDisabled
                        ? siteTotalChecked
                        : fields.siteTotal || false
                    }
                    onChange={() => onChange('siteTotal', !fields.siteTotal)}
                    disabled={isSiteTotalCheckboxDisabled}
                  />
                </div>
                <div>
                  <Label htmlFor="phase">Phase</Label>
                </div>
                <div className={styles.centeredField}>
                  <div className={styles.grayText}>
                    {isDefined(poles)
                      ? mapMainBreakerPoleToPhase[poles]
                      : global.NOT_AVAILABLE}
                  </div>
                </div>
              </>
            )}
          </ModalFormContent>

          <CircuitBreakerReadings
            proposedReadingByIndex={proposedReadingByIndex}
            energySensors={energySensors}
          />

          <div className={styles.diagramContainer}>
            <CircuitBreakerPhasorDiagram
              proposedReadingByIndex={proposedReadingByIndex}
              energySensors={energySensors}
            />
            <PowerReadings energySensors={energySensors} />
          </div>

          {renderAPIerror(
            apiError,
            CircuitBreakerActions.PATCH_CIRCUIT_BREAKER_ERROR
          )}
        </CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = (
  { circuitBreakers, mainBreakers }: ApplicationState,
  { breakerId, isMainBreaker, mainBreakerPos }: OwnProps
) => {
  const fields = isMainBreaker
    ? mainBreakers.editById[breakerId]
    : circuitBreakers.editById[breakerId];
  return {
    isVisible:
      (!isDefined(mainBreakerPos) ||
        mainBreakerPos ===
          circuitBreakers.showEditCircuitBreakerModal.mainBreaker) &&
      circuitBreakers.showEditCircuitBreakerModal.value &&
      breakerId === circuitBreakers.showEditCircuitBreakerModal.id,
    ...fields,
  };
};

const mapDispatchToProps = (
  dispatch,
  { breakerId, panelId, isMainBreaker }: OwnProps
) => ({
  onClose: () => dispatch(hideEditCircuitBreakerModal()),
  onChange: isMainBreaker
    ? (field: string, value: string | number) =>
        dispatch(mbUpdateField(String(breakerId), field, value))
    : (field: string, value: any) =>
        dispatch(updateField(String(breakerId), field, value)),
  onSave: isMainBreaker
    ? () => dispatch(mbPatch(breakerId, panelId))
    : () => dispatch(patch(panelId, breakerId)),
  displayFormErrors: isMainBreaker
    ? (breakerId: string) => dispatch(displayMBFormErrors(breakerId))
    : (breakerId: string) => dispatch(displayFormErrors(breakerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCircuitBreakerModal);
