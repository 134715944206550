import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentOrganizationId } from '../../../hooks/useCurrentOrganization';
import { getNonHvacEquipments } from '../../../actions/aggregate_views';
import { ApplicationState } from '../../../reducers';
import { useEquipmentTypesById } from '../../../hooks/useEquipmentTypes';
import { getWeatherByMultiSitesIds } from '../../../actions/weather';
import { getNetworkGroupsByOrgId } from '../../../actions/network_groups';
import NonHvacPageLayout from '../NonHvacPageLayout';
import { filteredEquipmentData } from '../../../util';

const LightingControls = () => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();
  const [count, setCount] = useState(0);

  // Getting nonHvac equipment data and filtering out
  // Lighting equipment which has either a relay or a scheduler
  const controllableEquipmentsData = useSelector(
    ({ aggregate_views }: ApplicationState) => {
      return aggregate_views.nonHvacEquipments;
    }
  );
  const allEquipmentTypes = useEquipmentTypesById();
  const lightingEquipmentTypes = Object.entries(allEquipmentTypes).reduce(
    (result, [id, equipment]) => {
      if (
        equipment.group.alias === 'equipmentLighting' ||
        equipment.group.alias === 'equipmentFurnitureLighting'
      ) {
        result[id] = equipment;
      }
      return result;
    },
    {}
  );
  const typeIds = Object.keys(lightingEquipmentTypes);
  const equipmentTypeIds = typeIds.map(str => parseInt(str, 10));

  const equipmentHasRelay = (data, id) => {
    for (const item of data) {
      if (item.equipmentIds.includes(id)) {
        return true;
      }
    }
    return false;
  };

  const filteredEquipments = filteredEquipmentData(
    controllableEquipmentsData,
    typeIds
  );

  const siteIds = filteredEquipments?.map(data => {
    return Number(data.siteId);
  });

  useEffect(() => {
    dispatch(getNetworkGroupsByOrgId(orgId!));
    dispatch(getNonHvacEquipments(orgId));
  }, [orgId]);

  useEffect(() => {
    if (siteIds.length && count < 1) {
      dispatch(getWeatherByMultiSitesIds(siteIds));
      setCount(1);
    }
  }, [siteIds]);

  return (
    <>
      <NonHvacPageLayout
        data={filteredEquipments}
        isLighting={true}
        equipmentTypes={lightingEquipmentTypes}
        orgId={orgId}
        equipmentTypeIds={equipmentTypeIds}
      />
    </>
  );
};

export default LightingControls;
