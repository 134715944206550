export enum EditText {
  ACTUATOR = 'Edit Relay',
  REPORT = 'Setup Report',
}

export enum CreateNewText {
  SENSOR = 'Install Sensor',
  ACTUATOR = 'Map New Relay',
  GATEWAY = 'Install Device',
  EQUIPMENT = 'New Equipment',
  SPACE = 'New Space',
  NETWORK_GROUP = 'New Network Group',
  TIME_TABLE = 'New Timetable',
  SITE = 'New Site',
  DISTRIBUTION_PANEL = 'New Panel',
  USER = 'New User',
  INSTALLER = 'New Installer',
  SITE_GROUP = 'New Site Group',
  USER_GROUP = 'New User Group',
  INSTALLER_GROUP = 'New Installer Group',
  CIRCUIT_BREAKER = 'New Circuit Breaker',
  ENERGY_PRO = 'New Energy Pro',
  ENERGY_SPIDER = 'New Energy Spider',
  ENERGY_SPIDER_FLEX = 'New Energy Spider Flex',
  ENERGY_BAR = 'New Energy Bar',
  SOP = 'New SOP',
  SOP_TABLE = 'SOP',
  SOP_POLICY = 'New Policy SOP',
  SOP_COST = 'New Cost SOP',
  SOP_HVAC = 'New HVAC SOP',
  CONTROL = 'Install Controls',
  SCHEDULER = 'Controls: Scheduler',
  API_KEY = 'New API Key',
  REPORT = 'New Report',
  CONTROL_BOARD = 'Install SiteController',
  THERMOSTAT = 'Install Thermostat',
  CERTIFICATION = 'New Certification',
}

export enum RunTestButtonText {
  RUN_TEST = 'Run Test',
  RUN_RELAY_TEST = 'Run Relay Test',
  RUN_CONFIGURATION_TEST = 'Run Configuration Test',
  SHOW_UNMAPPED_RELAYS = 'Show Unmapped Relays',
  HIDE_UNMAPPED_RELAYS = 'Hide Unmapped Relays',
  UNMAPPED_RELAYS = 'Unmapped Relays',
}

export enum NewButtonText {
  SOP_POLICY = 'Add Policy',
  SOP_COST = 'Add Cost',
  TIME_TABLE = 'Add Timetable',
  SOP_HVAC = 'Add HVAC SOP',
  SOP_TASK = 'Add Task SOP',
}

export enum PropertyToLabel {
  // OVERLAP FIELDS
  title = 'Title',
  customerEquipmentId = 'Customer Identifier',
  description = 'Description',
  siteId = 'Site',
  spaceId = 'Space',
  networkGroupId = 'Network Group',
  equipmentId = 'Equipment',
  vendor = 'Vendor',
  model = 'Model',
  port = 'Port',
  manufactureDate = 'Manufacture Date',
  serialNumber = 'Serial Number',
  manufacturer = 'Manufacturer',

  // NOT SURE WHAT TO CALL BOTH OF THESE AS A UNIFIED TITLE
  parentId = 'Parent',
  resourceId = 'Parent',

  // USER FIELDS
  email = 'Email',
  firstName = 'First Name',
  lastName = 'Last Name',
  password = 'Password',
  position = 'Position',
  workplace = 'Workplace',
  timeZone = 'Time Zone',
  temperatureUnit = 'Temperature',
  areaUnit = 'Area',
  measurementSystem = 'Other Measurement',
  dateFormat = 'Date Format',
  timeFormat = 'Time Format',
  phone = 'Contact Number',

  // EQUIPMENT FIELDS
  groupId = 'Equipment Group',
  typeId = 'Equipment Type',

  // SENSOR FIELDS
  uuid = 'UUID',

  //SITE FIELDS
  latitude = 'Latitude',
  longitude = 'Longitude',
  postalCode = 'Postal Code',
  street = 'Address',
  street2 = 'Address 2',
  city = 'City',
  state = 'State',
  country = 'Country',
  phoneNumber = 'Contact Number',
  areaTotal = 'Total Area',
  energyCalculationMode = 'Site Total Calculation',
  newTsdb = 'Legacy DB',

  // CONTROL BOARD
  lightSensorPort = 'Light Sensor Port',
  SiteControllerModel = 'SiteController Model',
  revertTime = 'Revert Time',
  relayCount = 'Relay Count',
  hardwareVersion = ' Hardware Version',

  // DISTRIBUTION PANEL
  breakerSlots = 'Breaker slots',
  breakerColumn = 'Breaker column',
  breakerSlot = 'Breaker slot',
  rating = 'Rating',
  type = 'Type',

  // ENERGY PRO
  sampleFrequency = 'Sample frequency',
  energyProId = 'Energy pro',
  bus = 'Bus',

  // ENERGY DEVICE SENSOR
  breakerId = 'Breaker',
  breakerPole = 'Breaker pole',
  energyDevicePort = 'Device port',
  scheduler = 'Scheduler',
  phase = 'Phase',
  ct = 'CT Type',

  // TIMETABLE FIELDS
  weekdays = 'Weekdays',
  dateRanges = 'Date ranges',
  beginTime = 'Start time',
  endTime = 'End time',
  dayOfWeek = 'Day',
  timeOfDay = 'Time',

  //SCHEDULER FIELDS
  beginDelta = 'Bias start',
  endDelta = 'Bias end',
  timetableId = 'Timetable',
  'lightSensorSettings.controlBoardId' = 'Control Board',
  'lightSensorSettings.actionInterval' = 'Delay',
  'lightSensorSettings.threshold' = 'Threshold',
  'lightSensorSettings.hysteresis' = 'Hysteresis',
  'lightSensorSettings.timetableId' = 'Timetable',
  'lightSensorSettings.beginDelta' = 'Bias start',
  'lightSensorSettings.endDelta' = 'Bias end',

  // NETWORK GROUPS
  'edge.uuid' = 'Uuid',
  'edge.edgeDevice' = 'Edge control device',
  'edge.serialNumber' = 'Serial number',
  'northUuid' = 'North Bound UUID',
  'southUuid' = 'South Bound UUID',
  edmHysteresisPercent = 'EDM Hysteresis',

  // SOP
  currencyCode = 'Currency Code',
  cost = 'Cost Type',
  policy = 'Policy Type',
  tariff = 'Tariff',
  value = 'Value',
  min = 'Minimum',
  max = 'Maximum',
  duration = 'Duration (minutes)',
  powerConsumption = 'Power Consumption',
  reverse = 'Reverse',
  hysteresis = 'Hysteresis',
  equipmentTypeIds = 'Equipment Types',

  // TASK SOP
  taskType = 'Task Type',
  attachTo = 'Attach To',

  // CERTIFICATE
  validTo = 'Valid Until',
  validFrom = 'Valid From',
  certificationBody = 'Certification Body',
  certificationCompany = 'Certification Company',
  deviceVendor = 'Device Vendor',
  deviceUuid = 'Device Uuid',
  certificationId = 'Certification Id',
  certificate = 'Certificate',

  // TEMP CONTROL
  'setPoint' = 'Set Point',
  'setPointLimitDelta' = 'Setpoint Limits',

  /// ???
  organizationUnit = 'Attached To',

  // Organization or Installer Group
  region = 'EB Region',
}

export enum Placeholder {
  seachBox = 'Quick Search',
}
