import styles from './TimetableVisualizationBlock.module.css';
import { DateTime } from 'luxon';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import HvacSopTimetableTooltipContent from './HvacSopTimetableTooltipContent';
import { HvacSopTimeSlot } from '@energybox/react-ui-library/dist/types/Sop';
import SchedulerTimetableTooltipContent from './SchedulerTimetableTooltipContent';

type props = {
  canvasWidth: number;
  canvasHeight: number;
  datetimePair: DateTime[] | undefined;
  dayOfTheWeek: string;
  hvacSchedule?: HvacSopTimeSlot;
  timetableTitle: string;
  user24HrTimeFomat: boolean;
  overflowTimestamp: DateTime | null;
  isLightSensor: Boolean;
  tooltipOverride?: string;
  schedulerThreshold?: string;
};

const TimetableVisualizationBlock: React.FC<props> = ({
  canvasWidth,
  canvasHeight,
  datetimePair,
  dayOfTheWeek,
  hvacSchedule,
  timetableTitle,
  user24HrTimeFomat,
  overflowTimestamp,
  isLightSensor,
  tooltipOverride,
  schedulerThreshold,
}) => {
  if (!datetimePair) return <></>;

  const TOTAL_HOURS_IN_VISUALIZATION = 24;
  const daysOfWeekToIndex = new Map<string, number>([
    ['MON', 0],
    ['TUE', 1],
    ['WED', 2],
    ['THU', 3],
    ['FRI', 4],
    ['SAT', 5],
    ['SUN', 6],
  ]);

  let begin = datetimePair[0];
  let end = datetimePair[1];

  const formattedBeginTimestamp = user24HrTimeFomat
    ? begin.toFormat('HH:mm')
    : begin.toFormat('t').toLowerCase();
  const formattedEndTimestamp = user24HrTimeFomat
    ? end.toFormat('HH:mm')
    : end.toFormat('t').toLowerCase();
  const formattedOverflowTimestamp =
    overflowTimestamp &&
    (user24HrTimeFomat
      ? overflowTimestamp.toFormat('HH:mm')
      : overflowTimestamp.toFormat('t').toLowerCase());
  let formattedTimeRange = '';

  if (overflowTimestamp) {
    if (end.equals(begin.endOf('day'))) {
      formattedTimeRange =
        formattedBeginTimestamp + ' - ' + formattedOverflowTimestamp;
    } else if (begin.equals(end.startOf('day'))) {
      formattedTimeRange =
        formattedOverflowTimestamp + ' - ' + formattedEndTimestamp;
    }
  } else {
    formattedTimeRange =
      formattedBeginTimestamp + ' - ' + formattedEndTimestamp;
  }

  const startOfDay = begin.startOf('day');

  const blockWidth =
    (canvasWidth / TOTAL_HOURS_IN_VISUALIZATION) *
    end.diff(begin, 'hours').as('hours');
  const blockHeight = canvasHeight / daysOfWeekToIndex.size;

  const xTranslate =
    (canvasWidth / TOTAL_HOURS_IN_VISUALIZATION) *
    begin.diff(startOfDay, 'hours').as('hours');
  const yTranslate = blockHeight * daysOfWeekToIndex.get(dayOfTheWeek)!;

  let blockFillStyle = styles.filledBlock;

  const renderTooltipContent = () => {
    if (hvacSchedule) {
      blockFillStyle =
        hvacSchedule.hvacScheduleType == 'OCCUPIED'
          ? styles.filledBlock
          : styles.unfilledBlock;
      return (
        <HvacSopTimetableTooltipContent
          timetableTitle={timetableTitle}
          timeRange={formattedTimeRange}
          thermostatMode={hvacSchedule.thermostatWorkingMode}
          minTemp={hvacSchedule.minTemp}
          maxTemp={hvacSchedule.maxTemp}
        />
      );
    } else {
      blockFillStyle = isLightSensor
        ? styles.unfilledBlock
        : styles.filledBlock;
      return (
        <SchedulerTimetableTooltipContent
          timetableTitle={timetableTitle}
          timeRange={tooltipOverride ? tooltipOverride : formattedTimeRange}
          overflowTimestamp={overflowTimestamp}
          schedulerThreshold={isLightSensor ? schedulerThreshold! : ''}
        />
      );
    }
  };

  return (
    <div
      style={{
        top: yTranslate + 1,
        left: xTranslate,
        width: blockWidth,
        height: blockHeight,
      }}
      className={styles.absolute}
    >
      <Tooltip
        underline={false}
        arrowDirection={'bottom'}
        content={renderTooltipContent()}
        extraClassNames={[styles.tooltip]}
      >
        <div
          className={`${styles.timetableVisualizationBlock} ${blockFillStyle}`}
          style={{
            top: yTranslate,
            left: xTranslate,
            width: blockWidth,
            height: blockHeight,
          }}
        >
          <div className={styles.hideTextOverflow}>
            {formattedTimeRange}
            {overflowTimestamp && ' (+1)'}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default TimetableVisualizationBlock;
