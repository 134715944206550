import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardList,
  CardTitle,
  Loader,
  Modal,
  RunConfigTestWarning,
} from '@energybox/react-ui-library/dist/components';
import {
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  Actuator,
  ActuatorCircuitStatus,
  ActuatorPortType,
  ActuatorPortTypeText,
  ActuatorState,
  ControlBoard,
  DeviceTypeDisplayText,
  FirmwareGatewayModel,
  GatewayOnlineStatus,
  GatewayStates,
  GatewayStatus,
  GatewayVendorLabel,
  LightSensorPort,
  SortDirection,
  CurrentUser,
  NetworkGroup,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  genericTableSort,
  global,
  hasKeys,
  isDefined,
  shortenString,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import { formatDistance } from 'date-fns';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Actions as ControlBoardActions,
  displayFormErrors,
  getControlBoardById,
  patchControlBoard,
  updateControlBoardFields,
} from '../../../../actions/control_boards';
import { destroy, reboot, reset } from '../../../../actions/gateways';
import { getSiteByResourceId } from '../../../../actions/sites';
import {
  subscribeToDeviceReadings,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../../../actions/streamApi';
import ActuatorCircuitActivityChartContainer from '../../../../components/ActuatorCircuitActivityChartContainer';
import ActuatorControlMode from '../../../../components/ActuatorControlMode';
import EditControlBoardForm from '../../../../components/EditControlBoardForm';
import ConfigurationCard from '../../../../components/GatewayCommonCards/ConfigurationCard';
import ConnectionCard from '../../../../components/GatewayCommonCards/ConnectionCard';
import HardwareCard from '../../../../components/GatewayCommonCards/HardwareCard';
import LightSensorChartContainer from '../../../../components/GatewayCommonCards/LightSensorChartContainer';
import ShowDetailPageHeader from '../../../../components/ShowDetailPageHeader';
import UpdateFirmwareModal from '../../../../components/UpdateFirmwareModal';
import { ApplicationState } from '../../../../reducers';
import { EditControlBoard } from '../../../../reducers/control_boards';
import { SensorReading } from '../../../../reducers/sensors';
import { NormalizedActuatorStates } from '../../../../reducers/subscribedControlBoardOutputStates';
import { Routes } from '../../../../routes';
import { renderAPIerror } from '../../../../utils/apiErrorFeedback';
import { renderLocalOverrideText } from '../../../../utils/controls';
import ResourceFullPath from '../../../ResourceFullPath';
import styles from './ShowControlBoardPage.module.css';
import { UserPlatformAccess } from '../../../../types/user';
import { determineUserRoleInPlatform } from '../../../../utils/user';
import { RunTestButtonText } from '../../../../types/global';
import {
  ArrowRight,
  RunConfig,
  SiteControllerRemoteAccess,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import FeatureFlag from '../../../FeatureFlag';
import { EB_INSTALLER_WHITELIST } from '../../../../featureFlagSettings';
import { hasDaResults } from '../../../../util';
import RelayTypeTooltip from '../../../Tooltips/RelayTypeTooltip';
import RelayOutputStatusTooltip from '../../../Tooltips/RelayOutputStatusTooltip';
import RelayCircuitStatusTooltip from '../../../Tooltips/RelayCircuitStatusTooltip';
import RemoteAccessButton from '../../../../components/RemoteAccessButton/RemoteAccessButton';
import { BalenaStatusById } from '../../../../reducers/balena';

interface OwnProps {
  controlBoard: ControlBoard;
}

interface Props extends OwnProps {
  load: () => void;
  unsubscribeFromDeviceStatus: (
    vendor: string,
    uuid: string,
    id: number
  ) => void;
  subscribeToDeviceStatus: (vendor: string, uuid: string, id: number) => void;
  subscribeToDeviceReadings: (vendor: string, uuid: string, id: number) => void;
  unsubscribeFromDeviceReadings: (
    vendor: string,
    uuid: string,
    id: number
  ) => void;
  liveLuxReading: SensorReading;
  reboot: () => void;
  onChange: (field: string, value: number) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  editControlBoard?: EditControlBoard;
  siteId?: number;
  displayFormErrors: () => void;
  subscribedControlBoardOutputStates?: NormalizedActuatorStates;
  gatewayOnlineStatus?: GatewayOnlineStatus;
  timezone?: string;
  subscribedControlBoardStatus?: GatewayStatus;
  currentUser: CurrentUser | undefined;
  testResults: {};
  currentOrgId?: number;
  networkGroup: NetworkGroup;
  statusByUuid: BalenaStatusById;
}

interface State {
  showDeletePrompt: boolean;
  showUpdateFirmwareModal: boolean;
  isLatestFirmware: boolean;
}

class ShowControlBoardPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeletePrompt: false,
      showUpdateFirmwareModal: false,
      isLatestFirmware: true,
    };
  }

  handleOpenDeletePrompt = () => {
    this.setState({ showDeletePrompt: true });
  };

  handleCloseDeletePrompt = () => {
    this.setState({ showDeletePrompt: false });
  };

  handleOpenUpdateFirmwareModal = () => {
    this.setState({ showUpdateFirmwareModal: true });
  };

  handleCloseUpdateFirmwareModal = () => {
    this.setState({ showUpdateFirmwareModal: false });
  };

  updateIsLatestFirmware = (isLatestFirmware: boolean) => {
    this.setState({ isLatestFirmware });
  };

  componentDidMount() {
    const {
      load,
      controlBoard,
      subscribeToDeviceReadings,
      siteId,
    } = this.props;

    unsubscribeFromDeviceStatus(
      controlBoard.vendor,
      controlBoard.uuid,
      controlBoard.id
    );
    subscribeToDeviceReadings(
      controlBoard.vendor,
      controlBoard.uuid,
      controlBoard.id
    );
    load();
  }

  componentWillUnmount() {
    const {
      controlBoard,
      unsubscribeFromDeviceStatus,
      unsubscribeFromDeviceReadings,
    } = this.props;
    unsubscribeFromDeviceStatus(
      controlBoard.vendor,
      controlBoard.uuid,
      controlBoard.id
    );
    unsubscribeFromDeviceReadings(
      controlBoard.vendor,
      controlBoard.uuid,
      controlBoard.id
    );
  }

  deletePrompt() {
    const { controlBoard, onDelete, editControlBoard } = this.props;

    const actions = (
      <>
        <Button
          variant="text"
          onClick={this.handleCloseDeletePrompt.bind(this)}
        >
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal
        onClose={this.handleCloseDeletePrompt.bind(this)}
        actions={actions}
      >
        <p className={styles.textAlignCenter}>
          Are you sure you want to delete{' '}
          {controlBoard ? (
            <b>{controlBoard.title}</b>
          ) : (
            `this ${DeviceTypeDisplayText.ENERGYBOX_CB}?`
          )}
          ?
        </p>
        {editControlBoard &&
          renderAPIerror(
            editControlBoard.apiError,
            ControlBoardActions.DELETE_CONTROL_BOARD_ERROR
          )}
      </Modal>
    );
  }

  onSave = () => {
    const { patch, editControlBoard, displayFormErrors } = this.props;
    if (editControlBoard && hasKeys(editControlBoard.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  render() {
    const {
      controlBoard,
      editControlBoard,
      siteId,
      onChange,
      onCancel,
      subscribedControlBoardOutputStates,
      liveLuxReading,
      gatewayOnlineStatus,
      timezone,
      subscribedControlBoardStatus,
      currentUser,
      testResults,
      currentOrgId,
      networkGroup,
      statusByUuid,
    } = this.props;

    const {
      showDeletePrompt,
      showUpdateFirmwareModal,
      isLatestFirmware,
    } = this.state;
    if (!editControlBoard) return <div>Loading...</div>;
    const isSuperHub = networkGroup?.edge?.edgeDevice === 'EB_SUPER_HUB';
    const subscribedActuatorStates = subscribedControlBoardOutputStates?.state;
    const controlBoardHasLightSensor =
      controlBoard.lightSensorPort !== LightSensorPort.NONE;
    const isLocalOverrideActive = subscribedControlBoardStatus?.gatewayStates?.includes(
      GatewayStates.CONTROL_OVERRIDE
    );
    const localOverrideTimestamp = subscribedControlBoardStatus?.updatedAt
      ? formatDistance(
          new Date(subscribedControlBoardStatus.updatedAt),
          new Date(),
          { addSuffix: true }
        )
      : '';
    const daResultsExist: boolean = hasDaResults(testResults);
    const userPlatformAccess = determineUserRoleInPlatform(
      currentUser,
      siteId,
      currentOrgId
    );
    const serialNumber = networkGroup?.edge?.serialNumber;

    const listOptions = [
      {
        onSelect: this.handleOpenUpdateFirmwareModal,
        title: 'Update Firmware',
        access: [
          UserPlatformAccess.GLOBAL_ADMIN,
          UserPlatformAccess.ORG_ADMIN,
          UserPlatformAccess.INSTALLER,
        ],
        type: [GatewayVendorLabel.energybox.toString()],
        icon: isLatestFirmware ? null : <WarningIcon size={16} />,
      },
      {
        onSelect: this.props.reboot,
        title: 'Reboot',
        type: [GatewayVendorLabel.energybox.toString()],
      },
      {
        onSelect: this.handleOpenDeletePrompt,
        title: `Delete ${DeviceTypeDisplayText.ENERGYBOX_CB}`,
        isDeleteItem: true,
        type: [''],
      },
    ];

    const actuatorListHeader: CardListHeader[] = [
      {
        width: '1',
        content: 'Relay#',
        comparator: (
          a: Actuator,
          b: Actuator,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'port',
          ]);
        },
      },
      {
        width: '2',
        content: 'Name',
        comparator: (
          a: Actuator,
          b: Actuator,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '2',
        content: 'Description',
        comparator: (
          a: Actuator,
          b: Actuator,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'description',
          ]);
        },
      },
      {
        width: '2',
        content: 'Equipment',
      },
      {
        width: '1',
        customHeader: <RelayTypeTooltip />,
        comparator: (
          a: Actuator,
          b: Actuator,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'portType',
          ]);
        },
      },
      {
        width: '1',
        customHeader: <RelayOutputStatusTooltip />,
        align: 'center',
      },
      {
        width: '1',
        customHeader: <RelayCircuitStatusTooltip />,
        align: 'center',
      },
      {
        width: '2',
        content: 'Control Mode',
        cellClassName: styles.controlModePadding,
      },
    ];
    const normalize = uuidString => {
      return uuidString.replaceAll(':', '').toLowerCase();
    };
    const actuatorListData: CardListRowData[] = (
      controlBoard?.actuators || []
    ).map((actuator: Actuator) => ({
      key: actuator.id.toString(),
      dataObject: actuator,
      content: (
        <>
          <Cell width="1">{actuator.port}</Cell>
          <Cell width="2">{actuator.title || global.NOT_AVAILABLE}</Cell>
          <Cell width="2">
            {shortenString(actuator.description, 24) || global.NOT_AVAILABLE}
          </Cell>
          <Cell width="2">
            <Link to={`${Routes.EQUIPMENT}/${actuator.equipment.id}`}>
              <span>{shortenString(actuator.equipment.title, 24)}</span>
            </Link>
          </Cell>
          <Cell width="1">{ActuatorPortTypeText[actuator.portType]}</Cell>
          <Cell width="1" className={styles.controlModePadding}>
            {renderOutputStatus(
              actuator.port,
              subscribedActuatorStates,
              isLocalOverrideActive
            )}
          </Cell>
          <Cell width="1" className={styles.controlModePadding}>
            {renderCircuitStatus(
              actuator.port,
              actuator.portType,
              subscribedActuatorStates,
              isLocalOverrideActive
            )}
          </Cell>
          <Cell width="2" className={styles.controlModePadding}>
            <ActuatorControlMode
              equipmentId={actuator.equipmentId}
              isLocalOverrideActive={isLocalOverrideActive}
            />
          </Cell>
        </>
      ),
      extraContent: [
        !!siteId && timezone && (
          <Cell width="12">
            <ActuatorCircuitActivityChartContainer
              siteId={siteId}
              actuator={actuator}
              equipment={actuator.equipment}
              timezone={timezone}
              showLightSensorDetails={false}
            />
          </Cell>
        ),
      ],
    }));

    return (
      <>
        {controlBoard && (
          <UpdateFirmwareModal
            device={controlBoard}
            onClose={this.handleCloseUpdateFirmwareModal}
            isVisible={showUpdateFirmwareModal}
            firmwareGatewayModel={FirmwareGatewayModel.ENERGYBOX_CB}
          />
        )}

        {controlBoard && (
          <ShowDetailPageHeader
            name={controlBoard.title}
            description={<ResourceFullPath resourceId={controlBoard.id} />}
            resourceName="Gateway"
            siteId={siteId}
            listOptions={listOptions.filter(
              option =>
                (option.type[0] === '' ||
                  option.type.indexOf(GatewayVendorLabel[controlBoard.vendor]) >
                    -1) &&
                (option.access === undefined ||
                  option.access.includes(userPlatformAccess))
            )}
          />
        )}

        <div
          className={classNames(
            styles.paddingBetweenCards,
            daResultsExist ? '' : styles.mainCardTopPadding
          )}
        >
          {editControlBoard && (
            <>
              <div className={daResultsExist ? styles.testResultsWarning : ''}>
                {daResultsExist && (
                  <RunConfigTestWarning
                    configLink={`${Routes.RUN_CONFIGURATION_TEST}/${siteId}`}
                  />
                )}
              </div>
              <Card>
                <CardContent>
                  <div className={styles.mainCardGrid}>
                    <CardTitle className={styles.mainCardTitle}>
                      <span>General Information</span>
                      {isSuperHub ? (
                        <span>
                          <RemoteAccessButton
                            serialNumber={serialNumber!}
                            balenaStatus={
                              statusByUuid[serialNumber!] || undefined
                            }
                            path={`/siteController/${normalize(
                              controlBoard.uuid
                            )}`}
                            isCustomIconRequired={true}
                            remoteAccessIcon={
                              <SiteControllerRemoteAccess size={26} />
                            }
                          />
                        </span>
                      ) : (
                        ''
                      )}
                    </CardTitle>
                    <div className={styles.subCard}>
                      {siteId && (
                        <EditControlBoardForm
                          {...editControlBoard.fields}
                          isChanged={editControlBoard.isChanged}
                          formErrors={editControlBoard.formErrors}
                          formErrorsVisible={editControlBoard.formErrorsVisible}
                          onChange={onChange}
                          controlBoardId={controlBoard.id}
                          siteId={siteId}
                          firmwareVersion={
                            controlBoard?.gatewayInfo?.firmwareVersion
                          }
                          hardwareVersion={
                            controlBoard?.gatewayInfo?.hardwareVersion
                          }
                          testResultsExist={daResultsExist}
                          updateIsLatestFirmware={this.updateIsLatestFirmware}
                        />
                      )}
                      {renderAPIerror(
                        editControlBoard.apiError,
                        ControlBoardActions.PATCH_CONTROL_BOARD_ERROR
                      )}
                    </div>
                  </div>
                </CardContent>

                {editControlBoard.isChanged && (
                  <CardActions>
                    <Button
                      variant="text"
                      onClick={onCancel}
                      children="Cancel"
                    />

                    <Button
                      disabled={editControlBoard.isLoading}
                      onClick={this.onSave}
                    >
                      {editControlBoard.isLoading ? (
                        <Loader size={16} variant="secondary" />
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </CardActions>
                )}
              </Card>
            </>
          )}
        </div>

        <div className={styles.paddingBetweenCards}>
          <Card>
            <CardContent>
              <div
                className={classNames(
                  styles.actuatorMappingCard,
                  styles.cardTitleExtraPadding
                )}
              >
                <CardTitle className={styles.runTestContainer}>
                  Relay Mapping
                  <FeatureFlag orgWhiteList={EB_INSTALLER_WHITELIST}>
                    <Link
                      target="_blank"
                      className={styles.runConfigurationTest}
                      to={`${Routes.RUN_CONFIGURATION_TEST}/${siteId}`}
                    >
                      <RunConfig width="23" height="20" />
                      <span className={styles.buttonText}>
                        {RunTestButtonText.RUN_CONFIGURATION_TEST}
                      </span>
                      <ArrowRight className={styles.arrow} size={14} />
                    </Link>
                  </FeatureFlag>
                </CardTitle>
                <div className={styles.actuatorMappingTableContainer}>
                  <div className={styles.localOverrideTitle}>
                    <div>Local Override:</div>
                    <div>
                      {isDefined(isLocalOverrideActive)
                        ? renderLocalOverrideText(isLocalOverrideActive)
                        : global.NOT_AVAILABLE}
                    </div>
                    <div className={styles.localOverrideTimestamp}>
                      {localOverrideTimestamp}
                    </div>
                  </div>
                  <CardList
                    header={actuatorListHeader}
                    data={actuatorListData}
                    dataIsLoading={!controlBoard}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {siteId &&
          controlBoardHasLightSensor &&
          isDefined(gatewayOnlineStatus) &&
          timezone && (
            <div className={styles.paddingBetweenCards}>
              <LightSensorChartContainer
                siteId={siteId}
                controlBoardId={controlBoard.id}
                timezone={timezone}
                liveLuxReading={liveLuxReading}
                isControlBoardOnline={gatewayOnlineStatus.onlineState}
              />
            </div>
          )}

        <div className={styles.paddingBetweenCards}>
          <ConnectionCard
            ianaTimeZoneCode={timezone}
            version={'CONTROL_BOARD'}
            gateway={controlBoard}
          />
        </div>

        <div className={styles.paddingBetweenCards}>
          <HardwareCard gateway={controlBoard} ianaTimeZoneCode={timezone} />
        </div>

        <div className={styles.paddingBetweenCards}>
          <ConfigurationCard gateway={controlBoard} />
        </div>

        {showDeletePrompt && this.deletePrompt()}
      </>
    );
  }
}

const renderNotAvailable = () => (
  <div className={styles.centerAlign}>{global.NOT_AVAILABLE}</div>
);

export const renderOutputStatus = (
  port: number,
  subscribedActuatorStates?: ActuatorState[] | undefined,
  isLocalOverrideActive?: boolean
) => {
  if (!subscribedActuatorStates) return global.NOT_AVAILABLE;

  return renderOutputStatusByState(
    subscribedActuatorStates[port - 1]?.state,
    isLocalOverrideActive
  );
};

export const renderOutputStatusByState = (
  state: boolean | undefined,
  isLocalOverrideActive?: boolean
) => {
  if (state === undefined) {
    return renderNotAvailable();
  }
  return (
    <div>
      <div
        className={classNames(
          isLocalOverrideActive === true
            ? styles.localOverrideColorOutputStatus
            : '',
          state && isLocalOverrideActive !== true
            ? styles.trueOutput
            : styles.falseOutput
        )}
      />
    </div>
  );
};

export const circuitStatusVisual = (
  status: ActuatorCircuitStatus,
  isLocalOverrideActive?: boolean
) => {
  return (
    <div
      className={classNames(
        isLocalOverrideActive === true
          ? styles.localOverrideColorCircuitStatus
          : styles[`circuitStatus${status}`]
      )}
    >
      {status}
    </div>
  );
};

export const renderCircuitStatus = (
  port: number,
  portType: ActuatorPortType,
  subscribedActuatorStates: ActuatorState[] | undefined,
  isLocalOverrideActive?: boolean
) => {
  if (!subscribedActuatorStates) return renderNotAvailable();

  return renderCircuitStatusByPortTypeAndState(
    portType,
    subscribedActuatorStates[port - 1]?.state,
    isLocalOverrideActive
  );
};

export const renderCircuitStatusText = (
  port: number,
  portType: ActuatorPortType,
  subscribedActuatorStates: ActuatorState[] | undefined
) => {
  if (!subscribedActuatorStates) return global.NOT_AVAILABLE;

  const state = subscribedActuatorStates[port - 1]?.state;
  if (state === undefined) {
    return global.NOT_AVAILABLE;
  }
  switch (portType) {
    case ActuatorPortType.NORMALLY_CLOSED:
      if (state) return ActuatorCircuitStatus.OFF;
      return ActuatorCircuitStatus.ON;
    case ActuatorPortType.NORMALLY_OPEN:
      if (state) return ActuatorCircuitStatus.ON;
      return ActuatorCircuitStatus.OFF;
    default:
      return global.NOT_AVAILABLE;
  }
};

export const renderCircuitStatusByPortTypeAndState = (
  portType: ActuatorPortType,
  state: boolean | undefined,
  isLocalOverrideActive?: boolean
) => {
  if (state === undefined) {
    return renderNotAvailable();
  }
  switch (portType) {
    case ActuatorPortType.NORMALLY_CLOSED:
      if (state)
        return circuitStatusVisual(
          ActuatorCircuitStatus.OFF,
          isLocalOverrideActive
        );
      if (!state)
        return circuitStatusVisual(
          ActuatorCircuitStatus.ON,
          isLocalOverrideActive
        );
      break;
    case ActuatorPortType.NORMALLY_OPEN:
      if (state)
        return circuitStatusVisual(
          ActuatorCircuitStatus.ON,
          isLocalOverrideActive
        );
      if (!state)
        return circuitStatusVisual(
          ActuatorCircuitStatus.OFF,
          isLocalOverrideActive
        );
      break;
    default:
      return renderNotAvailable();
  }
};

const mapStateToProps = (
  {
    app,
    controlBoards,
    sites,
    subscribedControlBoards,
    subscribedControlBoardOutputStates,
    gateways,
    networkGroups,
    balena,
  }: ApplicationState,
  { controlBoard }: OwnProps
) => {
  const idNumber = controlBoard.id;
  const siteId = sites.resourceIdToSiteId[idNumber];
  return {
    editControlBoard: controlBoards.editControlBoardById[idNumber],
    siteId,
    timezone: sites.sitesById[siteId]?.timeZone,
    subscribedControlBoardStatus:
      subscribedControlBoards.byId[idNumber]?.status,
    subscribedControlBoardOutputStates:
      subscribedControlBoardOutputStates[idNumber],
    liveLuxReading: controlBoards.lightLiveReadingByControlBoardId[idNumber],
    gatewayOnlineStatus: gateways.gatewaysById[idNumber].gatewayOnlineStatus,
    currentUser: app.currentUser,
    testResults: sites.sitesById[siteId]?.installerTestResults,
    currentOrgId: app.currentOrganizationId,
    networkGroup: networkGroups.networkGroupsById[controlBoard.networkGroupId],
    statusByUuid: balena.statusByUuid,
  };
};

const mapDispatchToProps = (dispatch: any, { controlBoard }: OwnProps) => {
  const idAsString = String(controlBoard.id);
  return {
    load: () => {
      dispatch(getControlBoardById(idAsString));
      dispatch(getSiteByResourceId(idAsString));
    },
    subscribeToDeviceStatus: (vendor, uuid, id) => {
      dispatch(subscribeToDeviceStatus(vendor, uuid, id));
    },
    subscribeToDeviceReadings: (vendor, uuid, id) => {
      dispatch(subscribeToDeviceReadings(vendor, uuid, id));
    },
    unsubscribeFromDeviceReadings: (vendor, uuid, id) => {
      dispatch(unsubscribeFromDeviceReadings(vendor, uuid, id));
    },
    unsubscribeFromDeviceStatus: (vendor, uuid, id) => {
      dispatch(unsubscribeFromDeviceStatus(vendor, uuid, id));
    },
    onChange: (f: string, v: string | number) =>
      dispatch(updateControlBoardFields(idAsString, f, v)),
    patch: () => {
      dispatch(patchControlBoard(idAsString));
    },
    onDelete: () => {
      dispatch(destroy(idAsString));
    },
    onCancel: () => dispatch(reset(idAsString)),
    reboot: () => dispatch(reboot(idAsString)),
    displayFormErrors: () => dispatch(displayFormErrors(idAsString)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowControlBoardPage);
