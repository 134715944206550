const checkWhichEnvironment = () => {
  if (!process.env.REACT_APP_API_BASE_URL) {
    return null;
  }

  if (process.env.REACT_APP_API_BASE_URL.includes('staging')) {
    return 'staging';
  }

  return 'production';
};

export default checkWhichEnvironment;
