import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './RemoteAccessButton/RemoteAccessButton.module.css';
import { RemoteAccess } from '@energybox/react-ui-library/dist/icons';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import { ApplicationState } from '../reducers';
import { useGetCurrentOrganization } from '../hooks/useCurrentOrganization';
interface CiscoDeviceStatusProps {
  serialNumber?: string | false; // Optional prop
  className?: string;
  deviceStatus?: string;
}

const colorMapping: Record<string, string> = {
  Up: 'green',
  Down: 'red',
  Unheard: 'gray',
};

const CiscoDeviceStatus: React.FC<CiscoDeviceStatusProps> = ({
  serialNumber,
  className,
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<'Up' | 'Down' | 'Unheard'>('Unheard');
  const [lastHeard, setLastHeard] = useState('');
  const color = colorMapping[status || 'Unheard'];

  const currentOrganization = useGetCurrentOrganization();
  const orgId = currentOrganization?.id;
  const getDeviceStatusBySerialNumber = (
    devices: any[],
    serialNumber: string
  ): string => {
    const device = devices.find(device => device.serialNumber === serialNumber);
    if (device) {
      return device.status === 'up'
        ? 'Up'
        : device.status === 'down'
        ? 'Down'
        : 'Unheard';
    }
    return 'Unheard';
  };

  const getDeviceLastHeardBySerialNumber = (
    devices: any[],
    serialNumber: string
  ): string => {
    const device = devices.find(device => device.serialNumber === serialNumber);
    if (device) {
      return device.lastHeard;
    }
    return 'Never';
  };

  const ciscoDevicesFromState = useSelector(
    ({ ciscoDevices }: ApplicationState) => ciscoDevices.ciscoDevicesByTenantId
  );

  useEffect(() => {
    if (!serialNumber) {
      setStatus('Down');
      setLastHeard('Never');
      return;
    }

    if (ciscoDevicesFromState) {
      const status = getDeviceStatusBySerialNumber(
        ciscoDevicesFromState,
        serialNumber
      );
      setStatus(status as 'Up' | 'Down' | 'Unheard');
      const lastHeard = getDeviceLastHeardBySerialNumber(
        ciscoDevicesFromState,
        serialNumber
      );
      setLastHeard(lastHeard);
    } else {
      setStatus('Down');
      setLastHeard('Never');
    }
  }, [serialNumber, dispatch, orgId, ciscoDevicesFromState]);

  if (!serialNumber) {
    return (
      <div className={className}>
        <div className={styles.remoteAccessButton}>
          <RemoteAccess size={25} tint="gray" />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={styles.remoteAccessButton}>
        <Tooltip
          underline={false}
          arrowDirection="left"
          disableAutoAdjust={true}
          content={
            <div className={styles.tooltipContent}>
              <i>Status</i>
              <div className={styles[color]}>{status}</div>
              <div className={styles[lastHeard]}>{lastHeard}</div>
            </div>
          }
        >
          {<RemoteAccess size={25} />}

          <div
            className={classNames(
              color === 'Unknown'
                ? styles.statusDotOutterOutline
                : styles.statusDotOutter,
              styles[color]
            )}
          >
            <div className={styles.statusDotInner} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default CiscoDeviceStatus;
