import { useCallback, useMemo } from 'react';
import { getUrlStateParams } from './utils';
import history from '../history';
import { areArraysIdentical } from '../utils/areArraysIdentical';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../mixpanelEvents';

const useOnlineStatusesFilter = () => {
  const search = history?.location?.search;
  const selectedOnlineStatuses = useMemo(
    () => getUrlStateParams<string[]>(history, 'onlineStatuses', []),
    [search]
  );

  const setOnlineStatusesFilter = useCallback(
    props => {
      const statesHaveNotChanged = areArraysIdentical(
        props,
        selectedOnlineStatuses
      );

      if (statesHaveNotChanged) {
        return;
      }

      mixpanel?.track(mixpanelEvents.FILTER_APPLIED, {
        type: 'Online Statuses',
        selectedStates: props,
      });

      setOnlineStatuses(props);
    },
    [selectedOnlineStatuses]
  );

  return {
    setOnlineStatusesFilter,
    selectedOnlineStatuses,
  };
};

export default useOnlineStatusesFilter;

const setOnlineStatuses = statuses => {
  const pathname = history?.location?.pathname;
  const updatedSearchParams = new URLSearchParams(history?.location?.search);

  updatedSearchParams.delete('onlineStatuses');

  if (Array.isArray(statuses)) {
    statuses.forEach(value => {
      updatedSearchParams.append('onlineStatuses', value);
    });
  }

  history?.push({ pathname, search: updatedSearchParams.toString() });
};
