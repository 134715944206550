import { useState } from 'react';
import SelectCtPolarity from '../../../Selects/SelectCtPolarity';
import SelectPhase from '../../../Selects/SelectPhase';
import { DistributionPanelType } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

export const StatefulSelectPhase = ({
  value,
  onSelect,
  panelType,
}: {
  value: number;
  onSelect: (value: number) => void;
  panelType?: DistributionPanelType;
}) => {
  const [phase, setPhase] = useState(value);

  return (
    <SelectPhase
      noBottomLine
      value={phase}
      onSelect={(value: number) => {
        onSelect(value);
        setPhase(value);
      }}
      panelType={panelType}
      error={false}
    />
  );
};

export const StatefulSelectCtPolarity = ({
  energyDeviceId,
  port,
  configuredPhase,
  value,
  onSelect,
  disabled,
}: {
  energyDeviceId: number;
  port: number;
  configuredPhase: number | undefined;
  value: boolean;
  onSelect: (value: boolean) => void;
  disabled?: boolean;
}) => {
  const [reversePolarity, setReversePolarity] = useState(value);
  const stagingSensorConfigs = useSelector(
    ({ energyDevices }: ApplicationState) => {
      return energyDevices.stagingSensorConfigs;
    }
  );

  const existingConfig = stagingSensorConfigs.find(
    c => c.energyDeviceId === energyDeviceId && c.port === port
  );

  return (
    <SelectCtPolarity
      noBottomLine
      value={reversePolarity}
      onSelect={(value: boolean) => {
        onSelect(value);
        setReversePolarity(value);
      }}
      disabled={
        disabled ||
        (!existingConfig &&
          (!isDefined(configuredPhase) || configuredPhase === 0)) ||
        (existingConfig && existingConfig.phase === 0)
      }
      error={false}
    />
  );
};
