import {
  DistributionPanel,
  EnergyPro,
  Gateway,
  GatewayVendorLabel,
  FirmwareGatewayModel,
  EnergySensor,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Label,
  Loader,
  Modal,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  global,
  hasKeys,
  isDefined,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getDistributionPanel } from '../../../../actions/distribution_panel';
import {
  Actions as EnergyProActions,
  destroy,
  displayFormErrors,
  getEnergyPro,
  hideUpdateEnergyProConfigModal,
  patch,
  reset,
  showUpdateEnergyProConfigModal,
  updateEnergyProConfiguration,
  updateField,
} from '../../../../actions/energy_pros';
import { clearGatewayBuffer, reboot } from '../../../../actions/gateways';
import {
  subscribeToEnergyProSensorReadings,
  unsubscribeFromEnergyProSensorReadings,
} from '../../../../actions/streamApi';
import EditEnergyProForm from '../../../../components/EditEnergyProForm';
import ShowDetailPageHeader from '../../../../components/ShowDetailPageHeader';
import UpdateFirmwareModal from '../../../../components/UpdateFirmwareModal';
import { useCurrentUser } from '../../../../hooks/useAppDetails';
import useGetResourcePathById from '../../../../hooks/useGetResourcePathById';
import { useGetSite } from '../../../../hooks/useSites';
import { ApplicationState, Dispatch } from '../../../../reducers';
import { EditEnergyPro } from '../../../../reducers/energy_pros';
import { SubscribedEnergyPro } from '../../../../reducers/subscribedEnergyPros';
import { renderAPIerror } from '../../../../utils/apiErrorFeedback';
import ResourceFullPath from '../../../ResourceFullPath';
import styles from './ShowEnergyProPage.module.css';
import { UserPlatformAccess } from '../../../../types/user';
import { determineUserRoleInPlatform } from '../../../../utils/user';
import { SensorEnergyChartWithFilter } from '../../../Chart/SensorEnergyChart';
import { EquipmentById } from '../../../../reducers/equipment';
import { ProcessedEnergySensorsById } from '../../../../types/energyDevice';
import {
  getEnergyDeviceSensorsOfEnergyPro,
  getPhaseDisplayText,
} from '../../../../utils/energyPro';
import SignalStrength from '../../../../components/SignalStrength/SignalStrength';
import DeviceOnlineState, {
  DisplayType,
} from '../../../DeviceStatus/DeviceOnlineState';
import { DeviceStatusById } from '../../../../reducers/deviceStatus';
import PhaseTooltip from '../../../DistributionPanels/DPSetUpTable/EnergyPro2/PhaseTooltip/PhaseTooltip';
import { PHASE_LABEL } from '../../../Selects/SelectPhase';
type Props = {
  id: string;
  gateway: Gateway;
};
const ShowEnergyProPage: React.FC<Props> = ({ id, gateway }) => {
  //*** useState ***//
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
  const [showUpdateFirmwareModal, setShowUpdateFirmwareModal] = useState(false);
  const [isLatestFirmware, setIsLatestFirmware] = useState(true);
  //*** Redux ***//
  const isUpdateEnergyProConfigModalShowing = useIsUpdateEnergyProConfigModalShowing(
    id
  );
  const editEnergyPro: EditEnergyPro | undefined = useEditEnergyPro(id);
  const energyPro: EnergyPro | undefined = useEnergyPro(id);
  const subscribedEnergyPro:
    | SubscribedEnergyPro
    | undefined = useSubscribedEnergyProReadings(id);
  const panel = useDistributionPanel(
    energyPro ? energyPro.distributionPanelId : undefined
  );
  const equipmentsById = useSelector<ApplicationState, EquipmentById>(
    ({ equipment }) => {
      return equipment.equipmentById;
    }
  );
  const energySensorsById: ProcessedEnergySensorsById = useMemo(() => {
    const energyDeviceSensors = getEnergyDeviceSensorsOfEnergyPro(energyPro);
    return mapArrayToObject(energyDeviceSensors || []);
  }, [energyPro]);
  const isEPro2 = energyPro?.model === 'ENERGYPRO2';
  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);
  const signalStrength =
    deviceStatusById[energyPro?.uuid || -1]?.signalStrength;
  //*** Actions ***//
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const getEnergyPro = useGetEnergyPro(dispatch, id);
  const getPanel = useGetDistributionPanel(
    dispatch,
    energyPro && String(energyPro.distributionPanelId)
  );
  const patch = usePatch(dispatch, id);
  const onChange = useOnChange(dispatch, id);
  const displayFormErrors = useDisplayFormErrors(dispatch, id);
  const onCancel = useOnCancel(dispatch, id);
  const onDelete = useOnDelete(dispatch, id);
  const updateEnergyProConfiguration = useUpdateEnergyProConfiguration(
    dispatch,
    id
  );
  const showUpdateEnergyProConfigModal = useShowUpdateEnergyProConfigModal(
    dispatch,
    id
  );
  const hideUpdateEnergyProConfigModal = useHideUpdateEnergyProConfigModal(
    dispatch,
    id
  );
  const subscribeToEnergyProSensorReadings = useSubscribeToEnergyProSensorReadings(
    dispatch
  );
  const unsubscribeFromEnergyProSensorReadings = useUnsubscribeFromEnergyProSensorReadings(
    dispatch
  );
  const rebootEpro = useRebootDevice(dispatch);
  const clearEproBuffer = useClearDeviceBuffer(dispatch);
  const resourcePath = useGetResourcePathById(id);
  const siteId = useMemo(() => {
    if (resourcePath) {
      return resourcePath.find(p => p.type === 'Site')?.id;
    }
    return undefined;
  }, [resourcePath]);
  const site = useGetSite(siteId);
  //*** useEffect ***//
  useEffect(() => {
    getEnergyPro();
  }, [getEnergyPro]);
  useEffect(() => {
    getPanel();
  }, [getPanel]);
  useEffect(() => {
    if (energyPro) {
      subscribeToEnergyProSensorReadings(
        energyPro.vendor,
        energyPro.uuid,
        energyPro.id
      );
    }
    return () => {
      if (energyPro) {
        unsubscribeFromEnergyProSensorReadings(
          energyPro.vendor,
          energyPro.uuid,
          energyPro.id
        );
      }
    };
  }, [
    energyPro,
    subscribeToEnergyProSensorReadings,
    unsubscribeFromEnergyProSensorReadings,
  ]);
  const handleOpenUpdateFirmwareModal = () => {
    setShowUpdateFirmwareModal(true);
  };
  const handleCloseUpdateFirmwareModal = () => {
    setShowUpdateFirmwareModal(false);
  };
  const onEnergyProSave = () => {
    if (editEnergyPro && hasKeys(editEnergyPro.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };
  const listOptions = [
    !isEPro2 && {
      onSelect: showUpdateEnergyProConfigModal,
      title: 'Update Configuration',
      type: [''],
    },
    !isEPro2 && {
      onSelect: handleOpenUpdateFirmwareModal,
      title: 'Update Firmware',
      access: [
        UserPlatformAccess.GLOBAL_ADMIN,
        UserPlatformAccess.ORG_ADMIN,
        UserPlatformAccess.INSTALLER,
      ],
      type: [''],
      icon: isLatestFirmware ? null : <WarningIcon size={16} />,
    },
    {
      onSelect: () => rebootEpro(id),
      title: 'Reboot',
      type: [GatewayVendorLabel.energybox.toString()],
    },
    {
      onSelect: () => clearEproBuffer(id),
      title: 'Clear Buffer',
      type: [GatewayVendorLabel.energybox.toString()],
    },
    {
      onSelect: () => setIsDeletePromptOpen(true),
      title: 'Delete Energy Pro',
      isDeleteItem: true,
      type: [''],
    },
  ];
  const deletePrompt = () => {
    const actions = (
      <>
        <Button variant="text" onClick={() => setIsDeletePromptOpen(false)}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );
    return (
      <Modal onClose={() => setIsDeletePromptOpen(false)} actions={actions}>
        <p className={styles.textAlignCenter}>
          Are you sure you want to delete{' '}
          {energyPro ? <b>{energyPro.title}</b> : 'this energy pro'}?
        </p>
        {editEnergyPro &&
          renderAPIerror(
            editEnergyPro.apiError,
            EnergyProActions.DELETE_ENERGY_PRO_ERROR
          )}
      </Modal>
    );
  };
  const updateEnergyProConfigPrompt = () => {
    const actions = (
      <>
        <Button variant="text" onClick={hideUpdateEnergyProConfigModal}>
          Cancel
        </Button>
        <Button onClick={updateEnergyProConfiguration}>Confirm</Button>
      </>
    );
    return (
      <Modal actions={actions}>
        <p className={styles.updateConfigText}>
          Are you sure you want to update the configuration of{' '}
          {energyPro ? <b>{energyPro.title}</b> : 'this energy pro'}?
        </p>
        {editEnergyPro &&
          renderAPIerror(
            editEnergyPro.apiError,
            EnergyProActions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR
          )}
      </Modal>
    );
  };
  const cardListData: CardListRowData[] | undefined = useMemo(() => {
    if (!subscribedEnergyPro || !site) {
      return undefined;
    }
    return subscribedEnergyPro.sensors.map(sensor => {
      let sensorConfig: EnergySensor | undefined;
      if (sensor.index < 10) {
        sensorConfig = energyPro?.sensors.find(e => (e.id = sensor.id));
      } else if (sensor.index < 100) {
        sensorConfig = energyPro?.bus1Device?.sensors.find(
          e => (e.id = sensor.id)
        );
      } else {
        sensorConfig = energyPro?.bus2Device?.sensors.find(
          e => (e.id = sensor.id)
        );
      }
      const { phaseToShow, phaseSource } = getPhaseDisplayText(
        sensorConfig?.phase,
        sensor.phase
      );
      return {
        key: sensor.indexString,
        content: (
          <>
            <Cell width="3">{sensor.indexString}</Cell>
            <Cell width="2">{sensor.voltage}</Cell>
            <Cell width="2">{sensor.current}</Cell>
            <Cell width="2">{sensor.powerActive}</Cell>
            <Cell width="2">{sensor.powerReactive}</Cell>
            <Cell width="2">{sensor.powerApparent}</Cell>
            <Cell width="2">{sensor.powerFactor}</Cell>
            <Cell width="1">
              {isEPro2 ? (
                <>
                  <span>{phaseToShow}</span>
                  &emsp;
                  <span>{phaseSource}</span>
                </>
              ) : (
                <>{PHASE_LABEL[sensor.phase]}</>
              )}
            </Cell>
          </>
        ),
        extraContent: [
          <Cell width="16">
            <SensorEnergyChartWithFilter
              header={
                <div className={styles.title}>
                  {(sensor.equipmentId
                    ? equipmentsById[sensor.equipmentId]?.title
                    : energySensorsById[sensor.id]?.breaker?.title) ??
                    global.NOT_AVAILABLE}
                </div>
              }
              site={site}
              sensor={sensor}
            />
          </Cell>,
        ],
      };
    });
  }, [subscribedEnergyPro, site]);
  if (!editEnergyPro || !energyPro || !panel) return null;
  const userPlatformAccess = determineUserRoleInPlatform(
    currentUser,
    siteId,
    site?.organizationId
  );
  return (
    <>
      <UpdateFirmwareModal
        device={energyPro}
        onClose={handleCloseUpdateFirmwareModal}
        isVisible={showUpdateFirmwareModal}
        firmwareGatewayModel={FirmwareGatewayModel.ENERGYPRO}
      />
      {isUpdateEnergyProConfigModalShowing && updateEnergyProConfigPrompt()}
      <ShowDetailPageHeader
        name={energyPro.title}
        description={<ResourceFullPath resourceId={energyPro.id} />}
        resourceName="Energy Pro"
        siteId={siteId}
        listOptions={
          listOptions.filter(
            option =>
              !!option &&
              (option.type[0] === '' ||
                option.type.indexOf(GatewayVendorLabel[gateway.vendor]) > -1) &&
              (option.access === undefined ||
                option.access.includes(userPlatformAccess))
          ) as any[]
        }
      />
      <div className={styles.content}>
        {siteId && (
          <Card className={styles.cardContainer}>
            <CardContent className={styles.cardGrid}>
              <CardTitle className={styles.cardTitle}>
                <div>General Information</div>
              </CardTitle>
              <EditEnergyProForm
                siteId={siteId}
                fields={editEnergyPro.fields}
                onChange={onChange}
                formErrorsVisible={editEnergyPro.formErrorsVisible}
                formErrors={editEnergyPro.formErrors}
                distributionPanelEnergySource={panel.energySource}
                firmwareVersion={energyPro?.firmwareVersion}
                setIsLatestFirmware={setIsLatestFirmware}
              />
              {renderAPIerror(
                editEnergyPro.apiError,
                EnergyProActions.PATCH_ENERGY_PRO_ERROR
              )}
            </CardContent>
            {editEnergyPro.isChanged && (
              <CardActions>
                <Button variant="text" onClick={onCancel} children="Cancel" />
                <Button
                  disabled={editEnergyPro.isLoading}
                  onClick={onEnergyProSave}
                >
                  {editEnergyPro.isLoading ? (
                    <Loader size={16} variant="secondary" />
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </CardActions>
            )}
          </Card>
        )}
        {isEPro2 && (
          <Card className={styles.cardContainer}>
            <CardContent className={styles.cardGrid}>
              <CardTitle className={styles.cardTitle}>Live Status</CardTitle>
              <div className={styles.liveStatusContainer}>
                <Label>Status</Label>
                <div style={{ top: '-0.5rem' }}>
                  <DeviceOnlineState
                    displayType={DisplayType.STATUS_ONLY_WITH_TEXT_COLOR}
                    devices={[
                      {
                        vendor: energyPro.vendor,
                        uuid: energyPro.uuid,
                        id: energyPro.id,
                      },
                    ]}
                  />
                </div>
                <Label>Signal</Label>
                <div>
                  {signalStrength ? (
                    <div className={styles.signalStrengthContainer}>
                      <SignalStrength value={-signalStrength} />
                    </div>
                  ) : (
                    global.NOT_AVAILABLE
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        )}
        {site && (
          <Card className={styles.cardContainer}>
            <CardContent>
              <CardTitle className={styles.cardTitle}>
                Live Readings
                <div style={{ fontSize: '0.75rem' }}>
                  {subscribedEnergyPro ? (
                    <span title={subscribedEnergyPro.timestamp}>
                      <TimeDistance timestamp={subscribedEnergyPro.timestamp} />
                    </span>
                  ) : (
                    global.NOT_AVAILABLE
                  )}
                </div>
              </CardTitle>
              <div>
                <CardList
                  header={getHeader(isEPro2)}
                  data={cardListData}
                  numberOfGridColumns={16}
                  headerClassName={styles.cardListHeader}
                />
              </div>
            </CardContent>
          </Card>
        )}
      </div>
      {isDeletePromptOpen && deletePrompt()}
    </>
  );
};
//*** Table columns ***/
const getHeader = (isEPro2: boolean) => [
  {
    width: '3',
    content: 'Index',
  },
  {
    width: '2',
    content: (
      <>
        <div>Voltage</div>
        <div>(V)</div>
      </>
    ),
  },
  {
    width: '2',
    content: (
      <>
        <div>Current</div>
        <div>(A)</div>
      </>
    ),
  },
  {
    width: '2',
    content: (
      <>
        <div>Active Power</div>
        <div>(kW)</div>
      </>
    ),
  },
  {
    width: '2',
    content: (
      <>
        <div>Reactive Power</div>
        <div>(VAr)</div>
      </>
    ),
  },
  {
    width: '2',
    content: (
      <>
        <div>Apparent Power</div>
        <div>(VA)</div>
      </>
    ),
  },
  {
    width: '2',
    content: 'Power Factor',
  },
  {
    width: '1',
    content: (
      <div className={styles.phaseHeader}>
        <span>Phase</span>
        {isEPro2 && <PhaseTooltip arrowDirection="topRight" />}
      </div>
    ),
  },
];
//*** local hooks ***/
export const useEditEnergyPro = (energyProId: string | undefined) => {
  return useSelector(({ energyPros }: ApplicationState) => {
    if (!isDefined(energyProId)) return undefined;
    return energyPros.editById[energyProId];
  }, shallowEqual);
};
const useEnergyPro = (energyProId: string) => {
  return useSelector<ApplicationState, EnergyPro | undefined>(
    ({ energyPros }) => {
      return energyPros.energyProsById[energyProId];
    },
    shallowEqual
  );
};
const useDistributionPanel = (panelId?: number) => {
  return useSelector<ApplicationState, DistributionPanel | undefined>(
    ({ distributionPanels }) => {
      if (panelId) {
        return distributionPanels.distributionPanelsById[panelId];
      }
      return undefined;
    },
    shallowEqual
  );
};
export const useIsUpdateEnergyProConfigModalShowing = (
  energyProId: string | undefined
) => {
  return useSelector<ApplicationState, boolean>(({ energyPros }) => {
    if (!isDefined(energyProId)) return false;
    return (
      (energyPros.editById[energyProId] || {}).showUpdateEnergyProConfigModal ||
      false
    );
  });
};
const useSubscribedEnergyProReadings = (energyProId: string) => {
  return useSelector(({ subscribedEnergyPros }: ApplicationState) => {
    return subscribedEnergyPros.byId[energyProId];
  }, shallowEqual);
};
const useGetEnergyPro = (dispatch: Dispatch, energyProId: string) => {
  return useCallback(() => {
    dispatch(getEnergyPro(energyProId));
  }, [dispatch, energyProId]);
};
const useGetDistributionPanel = (dispatch: Dispatch, panelId?: string) => {
  return useCallback(() => {
    if (panelId) {
      dispatch(getDistributionPanel(panelId));
    }
  }, [dispatch, panelId]);
};
const useUpdateEnergyProConfiguration = (
  dispatch: Dispatch,
  energyProId: string
) => {
  return useCallback(() => {
    dispatch(updateEnergyProConfiguration(energyProId));
  }, [dispatch, energyProId]);
};
export const useShowUpdateEnergyProConfigModal = (
  dispatch: Dispatch,
  energyProId: string | undefined
) => {
  return useCallback(() => {
    if (isDefined(energyProId))
      dispatch(showUpdateEnergyProConfigModal(energyProId));
  }, [dispatch, energyProId]);
};
export const useHideUpdateEnergyProConfigModal = (
  dispatch: Dispatch,
  energyProId: string | undefined
) => {
  return useCallback(() => {
    if (isDefined(energyProId))
      dispatch(hideUpdateEnergyProConfigModal(energyProId));
  }, [dispatch, energyProId]);
};
const usePatch = (dispatch: Dispatch, energyProId: string) => {
  return useCallback(() => {
    dispatch(patch(energyProId));
  }, [dispatch, energyProId]);
};
const useOnChange = (dispatch: Dispatch, energyProId: string) => {
  return useCallback(
    (field: string, value: string | number) => {
      dispatch(updateField(energyProId, field, value));
    },
    [dispatch, energyProId]
  );
};
const useDisplayFormErrors = (dispatch: Dispatch, energyProId: string) => {
  return useCallback(() => {
    dispatch(displayFormErrors(energyProId));
  }, [dispatch, energyProId]);
};
const useOnCancel = (dispatch: Dispatch, energyProId: string) => {
  return useCallback(() => {
    dispatch(reset(energyProId));
  }, [dispatch, energyProId]);
};
const useOnDelete = (dispatch: Dispatch, energyProId: string) => {
  return useCallback(() => {
    dispatch(destroy(energyProId, true));
  }, [dispatch, energyProId]);
};
const useSubscribeToEnergyProSensorReadings = (dispatch: Dispatch) => {
  return useCallback(
    (vendor: string, uuid: string, energyProId: number) => {
      dispatch(subscribeToEnergyProSensorReadings(vendor, uuid, energyProId));
    },
    [dispatch]
  );
};
const useUnsubscribeFromEnergyProSensorReadings = (dispatch: Dispatch) => {
  return useCallback(
    (vendor: string, uuid: string, energyProId: number) => {
      dispatch(
        unsubscribeFromEnergyProSensorReadings(vendor, uuid, energyProId)
      );
    },
    [dispatch]
  );
};
const useRebootDevice = (dispatch: Dispatch) => {
  return useCallback(
    (energyProId: string) => {
      dispatch(reboot(energyProId));
    },
    [dispatch]
  );
};
const useClearDeviceBuffer = (dispatch: Dispatch) => {
  return useCallback(
    (energyProId: string) => {
      dispatch(clearGatewayBuffer(energyProId));
    },
    [dispatch]
  );
};
export default ShowEnergyProPage;
