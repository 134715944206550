import { useDispatch, useSelector } from 'react-redux';
import { lookupEnergyPro } from '../actions/energy_pros';
import { useEffect } from 'react';
import { ApplicationState } from '../reducers';

export const useLookupEnergyPro = (
  model: 'ENERGY_PRO1' | 'ENERGY_PRO2',
  siteId: number,
  serialNumber?: string
) => {
  const dispatch = useDispatch();

  const energyProLookup = useSelector(
    (state: ApplicationState) => state.energyPros.lookupEnergyPro
  );

  useEffect(() => {
    if (serialNumber) dispatch(lookupEnergyPro(model, serialNumber, siteId));
  }, [model, serialNumber, siteId]);

  return energyProLookup;
};
