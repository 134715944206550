const USER_VISIT = 'User Visit';
const PAGE_VISIT = 'Page Visit';
const CONTROL_MODE_CHANGE = 'Control Mode Change';
const FILTER_APPLIED = 'Filter Applied';
const INSTALL_BUTTON_CLICKS = 'Install Button Clicks';
const SIR_INITIATED = 'SIR Initiated';
const SIR_SUBMITTED = 'SIR Submitted';

const mixpanelEvents = {
  USER_VISIT,
  PAGE_VISIT,
  CONTROL_MODE_CHANGE,
  FILTER_APPLIED,
  INSTALL_BUTTON_CLICKS,
  SIR_INITIATED,
  SIR_SUBMITTED,
};

export default mixpanelEvents;
