import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import useOnlineStatusesFilter from '../../hooks/useOnlineStatusFilter';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

type Props = {
  availableOptions?: string[];
  dropdownClassName?: string;
  className?: string;
};

export const convertOnlineStateToString = (
  onlineState: boolean | undefined
) => {
  if (isDefined(onlineState)) {
    if (onlineState) return 'ONLINE';
    else return 'OFFLINE';
  }
  return 'NO_STATUS';
};

const onlineStatuses = ['ONLINE', 'OFFLINE', 'NO_STATUS'];

const transformOnlineStatus = (status: string) => {
  switch (status) {
    case 'ONLINE':
      return 'Online';
    case 'OFFLINE':
      return 'Offline';
    case 'NO_STATUS':
    default:
      return 'No Status';
  }
};

enum OnlineStatus {
  ONLINE,
  OFFLINE,
  NO_STATUS,
}

const OnlineStatusFilter: React.FC<Props> = ({
  availableOptions = onlineStatuses,
  dropdownClassName,
  className,
}) => {
  const {
    setOnlineStatusesFilter,
    selectedOnlineStatuses,
  } = useOnlineStatusesFilter();

  const sortedItems = availableOptions.sort(
    (a, b) => OnlineStatus[a] - OnlineStatus[b]
  );

  return (
    <GenericFilter
      className={className}
      dropdownClassName={dropdownClassName}
      title="Online Status"
      setFilter={setOnlineStatusesFilter}
      items={sortedItems}
      transformItemName={transformOnlineStatus}
      selectedItems={selectedOnlineStatuses}
      allowLongLabel
    />
  );
};

export default OnlineStatusFilter;
