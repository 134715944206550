import { Actions } from '../actions/cisco_devices';
import * as R from 'ramda';

export interface AuthState {
  ciscoDevicesByTenantId: any[];
}

export interface CiscoDeviceState {
  eid?: string;
  status?: string;
  SN?: string;
  deviceType?: string;
}

export type CiscoDeviceStatusByTenantId = {
  [id: string]: CiscoDeviceState;
};

export const initialState: AuthState = {
  ciscoDevicesByTenantId: [],
};

export const authReducer = (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case Actions.FETCH_DEVICE_STATUS_SUCCESS:
      return R.assocPath(['ciscoDevicesByTenantId'], action.data, {
        ...state,
        ciscoDevicesByTenantId: action.data,
      });
    default:
      return state;
  }
};

export default authReducer;
