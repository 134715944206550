const apiBase = '/api/v1/iotod';

export enum Actions {
  FETCH_DEVICE_STATUS_SUCCESS = '@devices/FETCH_DEVICES_SUCCESS',
  FETCH_DEVICE_STATUS_LOADING = '@devices/FETCH_DEVICES_LOADING',
  FETCH_DEVICES_STATUS_ERROR = '@devices/FETCH_DEVICES_ERROR',
}

const tenantMapping: Record<number, string> = {
  1257: '08abb651-3f62-4cfa-ac63-977a7a267bdf', // HOLIDAY
  275: 'd0ba371c-c602-4b22-bdb7-9b50a2d9bcf3', // EB
  502040: '23b31882-2119-4e55-93c1-d08395b26923', // MCDONALDS
  527006: '484faf5f-767c-479c-90fb-f2566989ff4e', // WHATABURGER uses Wendys tenant
  349229: '484faf5f-767c-479c-90fb-f2566989ff4e', // WENDYS Bowling Green
  214992: '484faf5f-767c-479c-90fb-f2566989ff4e', // WENDYS Mt
  1378732: '0871b6fd-2651-4005-9518-22569e0ecf66', // BURGERKING
  1337959: '20c3dd8d-fb8e-4fc3-93ee-a242a121dfe4', // Installer
  5786: 'd0ba371c-c602-4b22-bdb7-9b50a2d9bcf3', //EB Staging
  569: 'd0ba371c-c602-4b22-bdb7-9b50a2d9bcf3', // EB Dev1
  430689: '0871b6fd-2651-4005-9518-22569e0ecf67',
  38519: 'd0ba371c-c602-4b22-bdb7-9b50a2d9bcf3',
  51439: '20c3dd8d-fb8e-4fc3-93ee-a242a121dfe4',
  1360794: '23b31882-2119-4e55-93c1-d08395b26923',
  1448838: '23b31882-2119-4e55-93c1-d08395b26925',
  234840: '484faf5f-767c-479c-90fb-f2566989ff4e',
  231725: '484faf5f-767c-479c-90fb-f2566989ff4e',
  395661: '484faf5f-767c-479c-90fb-f2566989ff4e',
  233186: '484faf5f-767c-479c-90fb-f2566989ff4e',
  279953: '484faf5f-767c-479c-90fb-f2566989ff4e',
  1351329: '484faf5f-767c-479c-90fb-f2566989ff4e',
  376367: '484faf5f-767c-479c-90fb-f2566989ff4e',
  568996: '484faf5f-767c-479c-90fb-f2566989ff4e',
  375076: '484faf5f-767c-479c-90fb-f2566989ff4e',
  1369070: '484faf5f-767c-479c-90fb-f2566989ff4e',
  384525: '484faf5f-767c-479c-90fb-f2566989ff4e',
  280900: '484faf5f-767c-479c-90fb-f2566989ff4e',
  1396651: '484faf5f-767c-479c-90fb-f2566989ff4e',
  288791: '484faf5f-767c-479c-90fb-f2566989ff4e',
  385054: '484faf5f-767c-479c-90fb-f2566989ff4e',
  343025: '484faf5f-767c-479c-90fb-f2566989ff4e',
  268501: '484faf5f-767c-479c-90fb-f2566989ff4e',
  1449179: '484faf5f-767c-479c-90fb-f2566989ff4e',
  583654: '484faf5f-767c-479c-90fb-f2566989ff4e',
  214332: '484faf5f-767c-479c-90fb-f2566989ff4e',
  440920: '484faf5f-767c-479c-90fb-f2566989ff4e',
  221104: '484faf5f-767c-479c-90fb-f2566989ff4e',
  44104: '484faf5f-767c-479c-90fb-f2566989ff4e',
  568304: '484faf5f-767c-479c-90fb-f2566989ff4e',
  351228: '484faf5f-767c-479c-90fb-f2566989ff4e',
  1441302: '484faf5f-767c-479c-90fb-f2566989ff4e',
  1495480: '484faf5f-767c-479c-90fb-f2566989ff4e',
};

export const getCiscoDeviceStatusesByTenantId = (tenantId: number) => ({
  type: 'API_GET',
  path: `${apiBase}/device-status/by-tenant-id/${tenantMapping[tenantId]}`,
  success: { type: Actions.FETCH_DEVICE_STATUS_SUCCESS, tenantId },
  error: { type: Actions.FETCH_DEVICE_STATUS_LOADING, tenantId },
  loading: { type: Actions.FETCH_DEVICES_STATUS_ERROR, tenantId },
});
