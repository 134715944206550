import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdRefresh } from 'react-icons/io';
import { differenceInSeconds } from 'date-fns';
import {
  Button,
  FilterDropdown,
  FilterDropdownTextItem,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import {
  FirmwareDeviceIdVendor,
  FirmwareGatewayModel,
  InspectionDetailLevel,
} from '@energybox/react-ui-library/dist/types';
import {
  getId,
  getProcessedInspectionData,
  getRelativeTime,
} from '@energybox/react-ui-library/dist/utils/inspection';
import LoaderLayer from './LoaderLayer';
import HierarchyTree from './HierarchyTree';
import EdgeControllerTile from './EdgeControllerTile';
import SiteControllerTile from './SiteControllerTile';
import EnergyProTile from './EnergyProTile';
import ThermostatTile from './ThermostatTile';
import HubTile from './HubTile';
import SensorsTile from './SensorsTile';
import UpdateFirmwareModal from '../../../components/UpdateFirmwareModal';
import {
  getSiteInspectionReport,
  startSiteInspectionReport,
} from '../../../actions/inspection';
import { determineUserRoleInPlatform } from '../../../utils/user';
import { ApplicationState } from '../../../reducers';
import NewSensorModal from '../../Sensors/NewSensorModal';

import styles from './ShowSiteInspectionReport.module.css';
import { UserPlatformAccess } from '../../../types/user';
import {
  InspectionDataFields,
  InspectionLoading,
  InspectionReport,
  InspectionReportLoaded,
} from '../../../reducers/inspection';
import SubmitTile from './SubmitTile';
import SubmitFormModal from './SubmitFormModal';
import SubmitStatusBar from './SubmitStatusBar';
import {
  getLastSIRSubmission,
  resetInspectionSubmission,
} from '../../../actions/reportworker';
import { InspectionSubmission } from '../../../reducers/reportworker';
import {
  DAThermostatTileKey,
  InspectionReportKey,
} from './SiteInspectionEnums';
import UpdateBalenaFirmwareModal from '../../../components/UpdateBalenaFirmwareModal';
import { getBalenaStatusBySiteId } from '../../../actions/balena';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../../../mixpanelEvents';

type Props = {
  siteId: string;
  siteTitle?: string;
};

const isLoading = (
  data: InspectionDataFields | InspectionLoading | undefined
): data is InspectionLoading => !!data && 'loading' in data;

const ShowSiteInspectionReport: React.FC<Props> = ({ siteId, siteTitle }) => {
  const dispatch = useDispatch();
  const reportRootRef = useRef<HTMLDivElement>(null);
  const tilesContainerRef = useRef<HTMLDivElement>(null);
  const jumpToRef = useRef<HTMLDivElement>(null);
  const [jumpTo, setJumpTo] = useState({ component: '', id: -1 });
  const [detailLevel, setDetailLevel] = useState(InspectionDetailLevel.ALL);
  const now = new Date();
  const [currentTime, setCurrentTime] = useState(now);
  const [sensorUuid, setSensorUuid] = useState<string | undefined>(undefined);
  const [showFloatingRefreshButton, setShowFloatingRefreshButton] = useState(
    false
  );
  const [
    isHidingFloatingRefreshButton,
    setIsHidingFloatingRefreshButton,
  ] = useState(false);
  const [
    isUpdateFirmwareModalShowing,
    setIsUpdateFirmwareModalShowing,
  ] = useState(false);
  const [isUpdateBalenaModalShowing, setIsUpdateBalenaModalShowing] = useState(
    false
  );
  const [isSubmitModalShowing, setIsSubmitModalShowing] = useState(false);
  const [firmwareUpdateDevice, setFirmwareUpdateDevice] = useState<
    FirmwareDeviceIdVendor
  >();
  const [firmwareGatewayModel, setFirmwareGatewayModel] = useState<
    FirmwareGatewayModel
  >();
  const [balenaRunningVersion, setBalenaRunningVersion] = useState<number>();
  const [edgeControllerSerial, setEdgeControllerSerial] = useState<string>('');

  const [startLoadingTime, setStartLoadingTime] = useState(0);
  const updateLoadingProgress = () => {
    // fire request to ask for report using the timestamp token
    dispatch(getSiteInspectionReport(siteId, isInspectionReportLoading));
  };

  const inspectionReport: InspectionReport = useSelector(
    ({ inspection }: ApplicationState) => {
      return inspection.inspectionReportBySiteId[siteId] || {};
    }
  );

  const inspectionReportData: InspectionDataFields | undefined =
    inspectionReport.report_version > 0 && !isLoading(inspectionReport.data)
      ? inspectionReport.data
      : undefined;

  const { networkGroupInspectionData, devicesWithoutNWG } = useMemo(
    () =>
      inspectionReportData
        ? getProcessedInspectionData(inspectionReportData)
        : {
            networkGroupInspectionData: undefined,
            devicesWithoutNWG: undefined,
          },
    [inspectionReport]
  );

  const lastRefreshTime = inspectionReport.reportFetchTime || now;
  const getInspectionReport = (options = { isForceReload: false }) => {
    /*
     * The inspection report is fetched from API when:
     * 1) There's no cache of this site's report in the browser; or
     * 2) The cached report is older than 1 day; or
     * 3) Refresh button is clicked
     */
    if (
      !inspectionReport.report_date ||
      differenceInSeconds(now, new Date(inspectionReport.report_date)) >
        86400 ||
      options.isForceReload
    ) {
      dispatch(startSiteInspectionReport(siteId));

      // Mixpanel tracking
      mixpanel.track(mixpanelEvents.SIR_INITIATED, {
        siteId: siteId,
        siteTitle: siteTitle,
      });
    }
    dispatch(getBalenaStatusBySiteId(Number(siteId)));
  };
  const handleRefreshButtonClick = () => {
    getInspectionReport({ isForceReload: true });
    dispatch(resetInspectionSubmission(parseInt(siteId)));
    dispatch(getLastSIRSubmission(siteId));
  };
  const isInspectionReportLoading = useSelector(
    ({ inspection }: ApplicationState) => {
      return inspection.loadingInspectionReportBySiteId[siteId];
    }
  );
  const loadingProgress =
    !isInspectionReportLoading && inspectionReport.report_version > 0
      ? 100
      : isLoading(inspectionReport.data)
      ? inspectionReport.data.loading * 100
      : 0;
  const secondsLoaded = (new Date().getTime() - startLoadingTime) / 1000;
  const remainingSeconds = isLoading(inspectionReport.data)
    ? Math.round(secondsLoaded / inspectionReport.data.loading - secondsLoaded)
    : 0;

  const reportOffsetTop = reportRootRef.current?.offsetTop || 0;
  const reportHeightStyle = {
    height: `calc(100vh - ${reportOffsetTop}px - 3rem)`,
  };
  const canUpdateFirmware = useSelector(({ app }: ApplicationState) => {
    const role = determineUserRoleInPlatform(
      app.currentUser,
      parseInt(siteId),
      app.currentOrganizationId
    );
    return [
      UserPlatformAccess.GLOBAL_ADMIN,
      UserPlatformAccess.ORG_ADMIN,
      UserPlatformAccess.INSTALLER,
    ].includes(role);
  });
  const handleTreeNodeClick = refId => {
    if (!refId) {
      return;
    }
    const [component, id] = refId.split('-');
    setJumpTo({ component, id: Number(id) });
  };
  const onFirmwareVersionLinkClick = useCallback(
    (
      device: FirmwareDeviceIdVendor,
      firmwareGatewayModel: FirmwareGatewayModel
    ) => {
      setIsUpdateFirmwareModalShowing(true);
      setFirmwareUpdateDevice(device);
      setFirmwareGatewayModel(firmwareGatewayModel);
    },
    []
  );
  const onBuildVersionLinkClick = useCallback(
    (
      device: FirmwareDeviceIdVendor,
      buildVersion: number,
      serialNumber: string
    ) => {
      setIsUpdateBalenaModalShowing(true);
      setFirmwareUpdateDevice(device);
      setBalenaRunningVersion(buildVersion);
      setEdgeControllerSerial(serialNumber);
    },
    []
  );
  const closeUpdateFirmwareModal = useCallback(() => {
    setIsUpdateFirmwareModalShowing(false);
    setIsUpdateBalenaModalShowing(false);
  }, []);
  const onTilesContainerScroll = useCallback(() => {
    requestAnimationFrame(onTilesContainerScrollRAF);
  }, [showFloatingRefreshButton]);
  const onTilesContainerScrollRAF = () => {
    const canSeeTopRefreshButton =
      (tilesContainerRef.current?.scrollTop || 0) <= 60;
    if (!canSeeTopRefreshButton && !showFloatingRefreshButton) {
      setShowFloatingRefreshButton(true);
      setIsHidingFloatingRefreshButton(false);
    } else if (canSeeTopRefreshButton && showFloatingRefreshButton) {
      setIsHidingFloatingRefreshButton(true);
    }
  };
  const onHidingRefreshButtonTransitionEnd = () => {
    if (isHidingFloatingRefreshButton) {
      setShowFloatingRefreshButton(false);
    }
  };

  useEffect(() => {
    getInspectionReport();
    const backgroundTimeInterval = window.setInterval(() => {
      // update current time state for approximately every min
      setCurrentTime(new Date());
    }, 60000);
    return () => window.clearInterval(backgroundTimeInterval);
  }, []);

  useEffect(() => {
    let progressUpdateInterval;
    if (
      isInspectionReportLoading &&
      typeof isInspectionReportLoading === 'string'
    ) {
      setStartLoadingTime(new Date().getTime());
      updateLoadingProgress();
      progressUpdateInterval = window.setInterval(updateLoadingProgress, 1000);
    }
    return () => window.clearInterval(progressUpdateInterval);
  }, [isInspectionReportLoading]);

  useEffect(() => {
    jumpToRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [jumpTo]);

  const submissionStatus = useSelector<
    ApplicationState,
    InspectionSubmission | undefined
  >(({ reportworker }) => reportworker.sirSubmissions[siteId]);

  const canSubmit = useMemo<boolean>(
    () =>
      // when the submission is failed or not initiated
      [undefined, InspectionSubmission.error].includes(submissionStatus) &&
      // when there are some data
      !!(
        inspectionReportData?.edge_controllers?.length ||
        inspectionReportData?.super_edge_controllers?.length ||
        inspectionReportData?.superhub_sensors?.length ||
        inspectionReportData?.super_hubs?.length ||
        inspectionReportData?.site_controllers?.length ||
        inspectionReportData?.energy_pros?.length ||
        inspectionReportData?.energy_pro2s?.length ||
        inspectionReportData?.thermostats?.length ||
        inspectionReportData?.hubs?.length ||
        inspectionReportData?.eb_thermostats?.length ||
        inspectionReportData?.unpaired_sensors?.sensors?.length
      ),
    [submissionStatus, inspectionReportData]
  );

  const onClickSubmitButton = useCallback(
    () => setIsSubmitModalShowing(true),
    []
  );

  const onSubmitFormModalClose = useCallback(
    (reset?: 'reset') => {
      if (reset) dispatch(resetInspectionSubmission(parseInt(siteId)));
      setIsSubmitModalShowing(false);
    },
    [siteId]
  );

  const isHeatPump = thermostat => {
    const value = thermostat[DAThermostatTileKey.HVAC_TYPE].field;
    if (value === null) {
      return null;
    }
    return value.includes('Heatpump');
  };

  const setSensorUuidAndOpenModal = (uuid: string) => {
    setSensorUuid(uuid);
  };

  const renderTiles = (inspectionReportData: InspectionDataFields) => (
    <>
      {inspectionReportData?.edge_controllers &&
        inspectionReportData.edge_controllers.map((edgeController, index) => (
          <EdgeControllerTile
            key={index}
            data={edgeController}
            detailLevel={detailLevel}
            jumpToRef={
              jumpTo.component === InspectionReportKey.EDGE_CONTROLLERS &&
              jumpTo.id === getId(edgeController)
                ? jumpToRef
                : null
            }
          />
        ))}

      {inspectionReportData?.super_edge_controllers &&
        inspectionReportData.super_edge_controllers.map(
          (edgeController, index) => (
            <EdgeControllerTile
              key={index}
              data={edgeController}
              detailLevel={detailLevel}
              canUpdateFirmware={canUpdateFirmware}
              onBuildVersionLinkClick={onBuildVersionLinkClick}
              isSuperEdge={true}
              jumpToRef={
                jumpTo.component ===
                  InspectionReportKey.SUPER_EDGE_CONTROLLERS &&
                jumpTo.id === getId(edgeController)
                  ? jumpToRef
                  : null
              }
            />
          )
        )}

      {inspectionReportData?.hubs &&
        inspectionReportData.hubs.map((hub, index) => (
          <HubTile
            key={index}
            siteId={siteId}
            canUpdateFirmware={canUpdateFirmware}
            data={hub}
            detailLevel={detailLevel}
            onFirmwareVersionLinkClick={onFirmwareVersionLinkClick}
            jumpToRef={
              jumpTo.component === InspectionReportKey.HUBS &&
              jumpTo.id === getId(hub)
                ? jumpToRef
                : null
            }
            setSensorUuidAndOpenModal={setSensorUuidAndOpenModal}
          />
        ))}

      {inspectionReportData?.superhub_sensors &&
        inspectionReportData.superhub_sensors.map((sHub, index) => (
          <HubTile
            isSuper
            key={index}
            siteId={siteId}
            data={sHub}
            detailLevel={detailLevel}
            jumpToRef={
              jumpTo.component === 'superhub_sensors' &&
              jumpTo.id === getId(sHub)
                ? jumpToRef
                : null
            }
            setSensorUuidAndOpenModal={setSensorUuidAndOpenModal}
          />
        ))}

      {inspectionReportData?.thermostats &&
        inspectionReportData.thermostats.map((thermostat, index) => (
          <ThermostatTile
            key={index}
            siteId={siteId}
            data={thermostat}
            detailLevel={detailLevel}
            jumpToRef={
              jumpTo.component === InspectionReportKey.VENSTAR_THERMOSTATS &&
              jumpTo.id === getId(thermostat)
                ? jumpToRef
                : null
            }
          />
        ))}

      {inspectionReportData?.eb_thermostats &&
        inspectionReportData.eb_thermostats.map((thermostat, index) => (
          <ThermostatTile
            key={index}
            siteId={siteId}
            data={thermostat}
            detailLevel={detailLevel}
            isEBThermostat={true}
            isHeatPump={isHeatPump(thermostat)}
            jumpToRef={
              jumpTo.component === InspectionReportKey.EB_THERMOSTATS &&
              jumpTo.id === getId(thermostat)
                ? jumpToRef
                : null
            }
          />
        ))}

      {inspectionReportData?.site_controllers &&
        inspectionReportData.site_controllers.map((siteController, index) => (
          <SiteControllerTile
            key={index}
            siteId={siteId}
            canUpdateFirmware={canUpdateFirmware}
            data={siteController}
            detailLevel={detailLevel}
            onFirmwareVersionLinkClick={onFirmwareVersionLinkClick}
            jumpToRef={
              jumpTo.component === InspectionReportKey.SITE_CONTROLLERS &&
              jumpTo.id === getId(siteController)
                ? jumpToRef
                : null
            }
          />
        ))}
      {inspectionReportData?.energy_pros &&
        inspectionReportData.energy_pros.map((energyPro, index) => (
          <EnergyProTile
            key={index}
            siteId={siteId}
            canUpdateFirmware={canUpdateFirmware}
            data={energyPro}
            detailLevel={detailLevel}
            onFirmwareVersionLinkClick={onFirmwareVersionLinkClick}
            jumpToRef={
              jumpTo.component === InspectionReportKey.ENERGY_PROS &&
              jumpTo.id === getId(energyPro)
                ? jumpToRef
                : null
            }
          />
        ))}

      {inspectionReportData?.energy_pro2s &&
        inspectionReportData.energy_pro2s.map((energyPro2, index) => (
          <EnergyProTile
            key={`energyPro2Tile-${getId(energyPro2)}`}
            siteId={siteId}
            data={energyPro2}
            detailLevel={detailLevel}
            jumpToRef={
              jumpTo.component === InspectionReportKey.ENERGY_PRO2S &&
              jumpTo.id === getId(energyPro2)
                ? jumpToRef
                : null
            }
            isEnergyPro2
          />
        ))}

      {inspectionReportData?.unpaired_sensors?.sensors &&
        inspectionReportData.unpaired_sensors.sensors.length > 0 && (
          <SensorsTile
            siteId={siteId}
            data={inspectionReportData.unpaired_sensors.sensors}
            statisticsData={
              inspectionReportData.unpaired_sensors.result
                ? {
                    result: inspectionReportData.unpaired_sensors.result,
                    errors: inspectionReportData.unpaired_sensors.errors,
                    warnings: inspectionReportData.unpaired_sensors.warnings,
                  }
                : undefined
            }
            detailLevel={detailLevel}
            jumpToRef={
              jumpTo.component === InspectionReportKey.SENSORS
                ? jumpToRef
                : null
            }
            setSensorUuidAndOpenModal={setSensorUuidAndOpenModal}
          />
        )}
    </>
  );

  const renderHierarchyTree = (
    inspectionReportData: InspectionDataFields | undefined
  ) => (
    <HierarchyTree
      data={inspectionReportData}
      onNodeClick={handleTreeNodeClick}
    />
  );

  return (
    <div className={styles.root} ref={reportRootRef}>
      <LoaderLayer
        isVisible={isInspectionReportLoading}
        progress={loadingProgress}
        remainingSeconds={remainingSeconds}
      />
      <div className={styles.treeContainer} style={reportHeightStyle}>
        <div className={styles.innerTreeContainer}>
          {networkGroupInspectionData &&
            Object.values(networkGroupInspectionData).map(renderHierarchyTree)}
          <HierarchyTree
            data={devicesWithoutNWG}
            onNodeClick={handleTreeNodeClick}
          />
        </div>
      </div>
      <div
        ref={tilesContainerRef}
        className={styles.tilesContainer}
        style={reportHeightStyle}
        onScroll={onTilesContainerScroll}
      >
        <SubmitStatusBar siteId={siteId} currentTime={currentTime} />
        <div className={styles.actionButtons}>
          <FilterDropdown title={detailLevel} active={true}>
            <FilterDropdownTextItem
              title={InspectionDetailLevel.ISSUES}
              onClick={() => setDetailLevel(InspectionDetailLevel.ISSUES)}
              closeOnClick
            />
            <FilterDropdownTextItem
              title={InspectionDetailLevel.ALL}
              onClick={() => setDetailLevel(InspectionDetailLevel.ALL)}
              closeOnClick
            />
          </FilterDropdown>
          <div className={styles.lastRefreshContainer}>
            <Button
              className={styles.lastRefreshButton}
              onClick={handleRefreshButtonClick}
              disabled={isInspectionReportLoading}
            >
              {isInspectionReportLoading ? (
                <Loader size={10} className={styles.lastRefreshIcon} />
              ) : (
                <IoMdRefresh size="18" className={styles.lastRefreshIcon} />
              )}
              Refresh
            </Button>
            <div
              className={styles.lastRefreshTime}
            >{`Last Refreshed ${getRelativeTime(
              lastRefreshTime,
              currentTime
            )}`}</div>
          </div>
        </div>
        <div
          className={styles.floatingLastRefreshButtonContainer}
          style={{
            display: showFloatingRefreshButton ? 'block' : 'none',
            opacity: isHidingFloatingRefreshButton ? 0 : 1,
          }}
          onTransitionEnd={onHidingRefreshButtonTransitionEnd}
        >
          <Button
            className={styles.floatingLastRefreshButton}
            onClick={handleRefreshButtonClick}
            disabled={isInspectionReportLoading}
          >
            {isInspectionReportLoading ? (
              <Loader size={18} />
            ) : (
              <IoMdRefresh size="24" />
            )}
          </Button>
        </div>
        {networkGroupInspectionData &&
          Object.values(networkGroupInspectionData).map(renderTiles)}
        {devicesWithoutNWG && renderTiles(devicesWithoutNWG)}
        {inspectionReportData && (
          <>
            <SubmitTile
              canSubmit={canSubmit}
              onSubmit={onClickSubmitButton}
              siteId={siteId}
            />
            <SubmitFormModal
              data={inspectionReport as InspectionReportLoaded}
              isVisible={isSubmitModalShowing}
              onClose={onSubmitFormModalClose}
              siteTitle={siteTitle}
            />
          </>
        )}
      </div>
      {isUpdateFirmwareModalShowing &&
        firmwareUpdateDevice &&
        firmwareGatewayModel && (
          <UpdateFirmwareModal
            device={firmwareUpdateDevice}
            onClose={closeUpdateFirmwareModal}
            isVisible={isUpdateFirmwareModalShowing}
            firmwareGatewayModel={firmwareGatewayModel}
          />
        )}

      {isUpdateBalenaModalShowing && firmwareUpdateDevice && (
        <UpdateBalenaFirmwareModal
          device={firmwareUpdateDevice}
          runningReleaseId={balenaRunningVersion}
          onClose={closeUpdateFirmwareModal}
          isVisible={isUpdateBalenaModalShowing}
          firmwareGatewayModel={FirmwareGatewayModel.ENERGYBOX_HUB}
          serialNumber={edgeControllerSerial}
        />
      )}
      <NewSensorModal
        // while this is not the nwg page
        // we want installed sensors here to follow same
        // logic as unified setup
        isSuperHubPage={true}
        sensorUuid={sensorUuid}
        lockSiteId={parseInt(siteId, 10)}
      />
    </div>
  );
};

export default ShowSiteInspectionReport;
