import {
  GenericErrors,
  ResourceType,
  Site,
  SiteAstronomicalClock,
  SiteEnergyCalculationMode,
  SiteInstallationStatus,
  SiteOnlineStats,
  SiteOnlineStatus,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  mapArrayToObject,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/sites';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface EditableFields {
  title: string;
  email: string;
  timeZone: string;
  address: string;
  street: string;
  street2: string;
  state: string;
  city: string;
  postalCode: string;
  country: string;
  phone: string;
  latitude: number;
  longitude: number;
  areaTotal: number;
  energyCalculationMode: SiteEnergyCalculationMode;
  newTsdb: boolean;
  siteInstallationStatus: SiteInstallationStatus;
  customerSiteId?: string;
}

const editableFields = (site: object) =>
  R.pick(
    [
      'email',
      'country',
      'street',
      'street2',
      'state',
      'city',
      'postalCode',
      'title',
      'address',
      'phone',
      'latitude',
      'longitude',
      'timeZone',
      'areaTotal',
      'energyCalculationMode',
      'newTsdb',
      'siteInstallationStatus',
      'customerSiteId',
    ],
    site
  );

export type EditSite = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrorsVisible: boolean;
  formErrors: GenericErrors;
  apiError: ApiError;
};

export const newSiteFields = {
  title: '',
  country: '',
  street: '',
  street2: '',
  latitude: '',
  longitude: '',
  state: '',
  city: '',
  postalCode: '',
  timeZone: '',
  address: '',
  phone: '',
  areaTotal: '',
  energyCalculationMode: SiteEnergyCalculationMode.MDP_MAINS,
  newTsdb: true,
  siteInstallationStatus: SiteInstallationStatus.IN_PROGRESS,
  customerSiteId: '',
};

const newSite = {
  isLoading: false,
  isChanged: false,
  fields: newSiteFields,
  formErrors: formValidationErrors('site', newSiteFields),
  formErrorsVisible: false,
  apiError: {},
};

export type SitesById = {
  [id: string]: Site;
};

export type SitesBySentinelId = {
  [sentinelId: number]: {
    isLoading: boolean;
    data: Site[];
  };
};

export type SiteAstroClockByDateRange = {
  //`${startDate}_${endDate}` of form 'yyyy-MM-dd'
  // Could've only stored the array, with no key, but this ensures hook will
  // return the intended date range. The issue with storing each date with it's
  // own key, is you then need logic to iterate between the two dates to
  // retrieve
  [dateRangeKey: string]: SiteAstronomicalClock[];
};

export type SiteAstroClockById = {
  [siteId: number]: SiteAstroClockByDateRange;
};

export interface Sites {
  query: string;
  sitesById: SitesById;
  sitesBySentinelId: SitesBySentinelId;
  editById: EditById;
  showNewSiteModal: boolean;
  sitesOnlineStatusById: SitesOnlineStatusById;
  resourceIdToSiteId: resourceIdToSiteId;
  loadingStatusByAction: ActionsLoadingStatus;
  siteAstroClockById: SiteAstroClockById;
  siteApiError: ApiError;
  status: SiteOnlineStats;
  loadingStatus: boolean;
}

export type EditById = {
  [id: string]: EditSite;
};

export type SitesOnlineStatusById = {
  [id: string]: SiteOnlineStatus;
};

export type ActionsLoadingStatus = {
  [Actions.GET_SITES_LOADING]?: boolean;
};

export type resourceIdToSiteId = {
  [id: number]: number;
};

export const sitesFromApiResponse = (data: any) => ({
  id: data.id,
  legacyId: data.legacyId || undefined,
  externalId: data.externalId || undefined,
  organizationId: data.organizationId,
  groupIds: data.groupIds,
  title: data.title,
  email: data.email,
  description: data.description || undefined,
  areaTotal: data.areaTotal,
  country: data.country,
  postalCode: data.postalCode,
  city: data.city || '',
  state: data.state || '',
  street: data.street || '',
  street2: data.street2 || '',
  locale: data.locale || undefined,
  timeZone: data.timeZone,
  latitude: data.latitude || '',
  longitude: data.longitude || '',
  currency: data.currency || undefined,
  address: data.address,
  phone: data.phone,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  images: data.images || [],
  energyCalculationMode: data.energyCalculationMode || '',
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  newTsdb: data.newTsdb,
  siteInstallationStatus: data.siteInstallationStatus,
  siteHeroDate: data.siteHeroDate,
  siteHeroStatus: data.siteHeroStatus,
  energyTracker: data.energyTracker,
  energyTrackerDate: data.energyTrackerDate,
  siteInstallationDate: data.siteInstallationDate,
  subscriptionStartDate: data.subscriptionStartDate,
  thermostatControl: data.thermostatControl,
  thermostatControlDate: data.thermostatControlDate,
  autoScheduler: data.autoScheduler,
  autoSchedulerDate: data.autoSchedulerDate,
  installerTestResults: data.installerTestResults,
  customerSiteId: data.customerSiteId,
});

export const initialState = {
  query: '',
  sitesById: {},
  sitesBySentinelId: {},
  showNewSiteModal: false,
  editById: {},
  resourceIdToSiteId: {},
  sitesOnlineStatusById: {},
  loadingStatusByAction: {},
  siteAstroClockById: {},
  siteApiError: {},
  status: {
    total: 0,
    online: 0,
    offline: 0,
    partiallyOffline: 0,
    siteOnlineStatuses: [],
  },
  loadingStatus: false,
};

export const siteList = (state: Sites) => {
  const { sitesById, query } = state;
  let sites = values(sitesById);

  if (query && query.length >= 3) {
    sites = sites.filter(
      site =>
        hasSubstr(site.title, query) ||
        hasSubstr(site.address, query) ||
        hasSubstr(site.externalId, query)
    );
  }

  return sites;
};

export const sortedSites = ({ sitesById }: Sites) =>
  values(sitesById).sort((a, b) => a.title.localeCompare(b.title));

export const createFullAddress = (site: EditableFields) =>
  `${site.street || ''}, ${
    site.street2 ? `${site.street2},` : ''
  } ${site.city || ''}, ${site.state || ''} ${site.postalCode || ''}${
    site.country ? `, ${site.country}` : ''
  }`;

const processStatuses = (status: SiteOnlineStatus): SiteOnlineStatus => {
  return {
    ...status,
    latitude: status.latitude || 0,
    longitude: status.longitude || 0,
  } as SiteOnlineStatus;
};

const sites = (state: Sites = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SITES_SUCCESS:
      return R.pipe(
        R.assoc(
          'sitesById',
          mapArrayToObject(mapValues(action.data, sitesFromApiResponse))
        ),
        R.assocPath(['loadingStatusByAction', Actions.GET_SITES_LOADING], false)
      )(state);

    case Actions.GET_SITES_LOADING:
      return R.pipe(
        R.assocPath(['loadingStatusByAction', Actions.GET_SITES_LOADING], true),
        R.assoc('siteApiError', {})
      )(state);

    case Actions.GET_SITE_SUCCESS:
      let site = sitesFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['sitesById', action.data.id], site),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(site),
          apiError: {},
        }),
        R.assoc('siteApiError', {})
      )(state);

    case Actions.GET_SITE_ASTRO_CLOCK_SUCCESS: {
      const times: SiteAstronomicalClock[] = action.data;
      const { startDate, endDate } = action;
      const dateRangeKey = endDate ? `${startDate}_${endDate}` : `${startDate}`;
      return R.pipe(
        R.assocPath(['siteAstroClockById', action.siteId, dateRangeKey], times)
      )(state);
    }

    case Actions.GET_SITE_BY_RESOURCE_ID_SUCCESS: {
      const site = sitesFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['sitesById', site.id], site),
        R.assocPath(['resourceIdToSiteId', action.id], site.id),
        R.assoc('siteApiError', {})
      )(state);
    }

    case Actions.GET_SITE_ERROR:
    case Actions.GET_SITE_BY_RESOURCE_ID_ERROR: {
      return R.assoc('siteApiError', action.data, state);
    }

    case Actions.GET_SITES_BY_SENTINEL_TARGETS_LOADING: {
      const { sentinelId } = action;
      return R.assocPath(
        ['sitesBySentinelId', sentinelId],
        { isLoading: true, data: [] },
        state
      );
    }

    case Actions.GET_SITES_BY_SENTINEL_TARGETS_ERROR: {
      const { sentinelId, data } = action;
      return R.assocPath(
        ['sitesBySentinelId', sentinelId],
        { isLoading: false, data },
        state
      );
    }

    case Actions.GET_SITES_BY_SENTINEL_TARGETS_SUCCESS: {
      const { data, sentinelId } = action;
      const sites = data.map(sitesFromApiResponse);
      return R.assocPath(
        ['sitesBySentinelId', sentinelId],
        { isLoading: false, data: sites },
        state
      );
    }

    case Actions.UPDATED_QUERY:
      return R.assoc('query', action.query, state);

    case Actions.TOGGLE_NEW_SITE_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newSite),
        R.assoc('showNewSiteModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('site', updatedField)
        )
      )(state);

    case Actions.CREATE_SITE_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_SITE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sitesById', action.data.id],
          sitesFromApiResponse(action.data)
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewSiteModal', false)
      )(state);

    case Actions.CREATE_SITE_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_SITE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_SITE_SUCCESS:
      const patchedSite = sitesFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['sitesById', action.id], patchedSite),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_SITE_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.GET_SITE_ONLINE_STATUS_SUCCESS:
      return R.assocPath(
        ['sitesOnlineStatusById', action.id],
        action.data,
        state
      );

    case Actions.GET_SITES_ONLINE_STATUS_LOADING:
      return R.assocPath(['loadingStatus'], true, state);

    case Actions.GET_SITES_ONLINE_STATUS_SUCCESS:
      return R.pipe(
        R.assocPath(['status'], action.data),
        R.assocPath(['loadingStatus'], false)
      )(state);

    case Actions.RESET_EDIT_SITE:
      const fields = editableFields(R.path(['sitesById', action.id], state));

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('site', fields)
        )
      )(state);

    case Actions.DELETE_SITE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_SITE_SUCCESS:
      if (action.data) {
        return R.pipe(
          R.dissocPath(['sitesById', action.id]),
          R.dissocPath(['editById', action.id])
        )(state);
      }
      return state;

    case Actions.DELETE_SITE_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.UPLOAD_PICTURE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sitesById', action.id, 'images'],
          state['sitesById'][action.id].images.concat(action.data.path)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DELETE_PICTURE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sitesById', action.id, 'images'],
          state['sitesById'][action.id].images.filter(path => {
            return path !== action.path;
          })
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    default:
      return state;
  }
};

export default sites;
